import { ClaimUsername } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { TextInput } from '@/modules/shared/components/Molecule/Forms/TextInput';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { MODAL } from '../../types';
import './_ClaimUsername.scss';
export const ClaimUsernamePopup = () => {
  const { t } = useTranslation(['auth']);

  function handleSuccessClaim() {
    toast.success(t('success:loginSuccessful'));
    openModal(MODAL.USERNAME_CLAIMED, undefined, { fullScreen: true });
  }
  const placeholder = t('auth:claimusername.username');
  return (
    <ClaimUsername
      onClaimError={(reason) => toast(t(`errors:globalErrors.${reason}`))}
      onClaimSuccess={handleSuccessClaim}
    >
      {({ setUsername, username, errors, onClaim, isClaiming }) => (
        <div className="claim-username-popup">
          <h2 className="modal-title lexend-heading text-center">
            {t('claimusername.profile')}
          </h2>

          <TextInput
            id={'username-claim'}
            placeholder={placeholder}
            onChange={setUsername}
            value={username}
            error={errors.username}
          />

          <div className="flex middle actions">
            <Button
              variant={BUTTON_VARIANT.RED}
              onClick={(e) => {
                onClaim();
                e.preventDefault();
              }}
              size={BUTTON_SIZE.LARGE}
              className="logout expand"
              loading={isClaiming}
              disabled={username.length < 5}
            >
              {t('auth:claimusername.letsGo')}
            </Button>
          </div>
        </div>
      )}
    </ClaimUsername>
  );
};
