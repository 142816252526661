import { QUEST_STATUS, isAutoCompleteManual } from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  QuestRequiredAction,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { P, match } from 'ts-pattern';
import * as yup from 'yup';

import { QuestRequirementType } from '@/modules/quests/components';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { TextArea } from '@/modules/shared/components/Molecule/Forms/TextArea';

const minLimit = 3;

const textSubmissionSchema = yup.object({
  text: yup.string().required('Required').min(minLimit, 'Too short'),
});

export const TextSubmissionMission = (props: QuestRequirementType) => {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      text: quest?.metrics?.text || '',
    },
    validationSchema: textSubmissionSchema,
    onSubmit: () => {},
  });
  const { t } = useTranslation(['quests']);
  const completed =
    actionContext.reason === QuestDisabledReason.QUEST_COMPLETED;

  const errorMessage = useMemo(() => {
    return match(actionContext.reason)
      .with(QuestDisabledReason.GUEST_USER, () => t('quests:loginFirstQuest'))
      .with(QuestDisabledReason.COMMUNITY_GUEST, () =>
        t('quests:questOnlyForMembers'),
      )
      .with(QuestDisabledReason.EVENT_GUEST, () =>
        t('quests:registerEventFirst'),
      )
      .with(QuestDisabledReason.EVENT_JOIN_NOT_ALLOWED, () =>
        t('quests:eventJoinNotAllowed'),
      )
      .otherwise(() => null);
  }, [actionContext.reason, t]);

  useEffect(() => {
    props.setDisabled?.(
      !form.isValid ||
        !form.dirty ||
        actionContext.action === QuestRequiredAction.STOP,
    );
  }, [form.isValid, form.dirty, props, form.values.text, actionContext.action]);

  const autoComplete = isAutoCompleteManual(quest?.template);

  return (
    <>
      {errorMessage && <Alert variant="info">{errorMessage}</Alert>}
      <Body
        status={quest?.status}
        renderDefault={() => (
          <div
            className={`quest-requirement flex between middle
        ${completed ? ' fulfilled' : ''}
        `}
          >
            <div className="text-submission-input w-full flex">
              <TextArea
                className="w-full"
                id={'text-submission'}
                value={form.values.text}
                error={form.errors.text}
                onChange={(value) => {
                  form.handleChange('text')(value);
                  props.setPayload?.({ text: value });
                }}
                placeholder={
                  quest?.template?.args?.text ||
                  t('quests:textSubmissionPlaceholder')!
                }
                minLimit={minLimit}
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

function Body({
  status,
  renderDefault,
}: {
  status?: QUEST_STATUS;
  renderDefault: () => JSX.Element;
}) {
  const { t } = useTranslation(['quests']);

  return match(status)
    .with(QUEST_STATUS.COMPLETED, () => (
      <Alert variant="info">{t('quests:pendingClaimDescription')}</Alert>
    ))
    .with(P.union(QUEST_STATUS.RESOLVING), () => (
      <Alert variant="info">{t('quests:inReviewDescription')}</Alert>
    ))
    .with(QUEST_STATUS.FAILED, () => (
      <Alert variant="error">{t('quests:questFailedDescription')}</Alert>
    ))
    .otherwise(renderDefault);
}
