import Link from 'next/link';
import { useState } from 'react';
import SimpleBar from 'simplebar-react';

import { Icon } from '@/modules/shared/components/Atom/Icon';

type Option = {
  label: string;
  slug: string;
  active?: boolean;
};

export type TextInputType<T> = {
  onChange: (val: Option) => void;
  children: any;
  list: T[] extends Option[] ? Option[] : never;
  className?: string;
  disabled?: boolean;
  anchor?: 'anchor-top-right' | 'anchor-top-left';
};

export function DropdownSelect<T extends Option>({
  onChange,
  className,
  list,
  children,
  disabled,
  anchor,
}: TextInputType<T>) {
  const [active, setActive] = useState<boolean>(false);
  const onChangeSelect = (data: Option) => {
    if (onChange) onChange(data);
    setActive(false);
  };

  return (
    <div
      className={`dropdown-select ${className ?? ''} ${active ? 'active' : ''}${
        disabled ? 'disabled' : ''
      }`}
    >
      <div
        className="dropdown-select-btn"
        onClick={() => {
          setActive(!active);
        }}
      >
        {children}
        <Icon.chevronLeft
          className="dropdown-select-arrow stroke"
          size={16}
          color="white"
        />
      </div>
      <SimpleBar className={`dropdown-select-list flex column ${anchor}`}>
        {list.map((item) => (
          <Link
            key={item.slug}
            href=""
            className="flex middle between lexend-body-xs1"
            onClick={(e) => {
              e.preventDefault();
              onChangeSelect(item);
            }}
          >
            {item.label}
            {item.active ? <Icon.check className="stroke" size={16} /> : null}
          </Link>
        ))}
      </SimpleBar>
    </div>
  );
}
