import { QuestDetailsType, formatStat } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';
import { P, match } from 'ts-pattern';

export function Stat({
  metrics,
  defaultStatText,
  target,
  children,
}: {
  metrics: QuestDetailsType['metrics'];
  defaultStatText: string;
  target?: QuestDetailsType['template']['args'];
  children: ({
    items,
  }: {
    items: { title: string; value: string }[];
  }) => JSX.Element;
}) {
  const { t } = useTranslation(['quests']);
  const metricStats =
    metrics && Object.keys(metrics).length > 0 ? Object.entries(metrics) : null;

  const items = match(metricStats)
    .with(P.nullish, () => [
      {
        title: defaultStatText,
        value: '-',
      },
    ])
    .with(P.not(P.nullish), (stat) =>
      stat.map(([key, value]) => ({
        title: t(`quests:${key}`),
        value: value ? formatStat(value, target?.[key]) : '-',
      })),
    )
    .exhaustive();

  return children({
    items,
  });
}
