import { TFunction } from 'next-i18next';

import { LOCAL_STORAGE_VALUE } from '@/apis';
import {
  initMixpanel,
  isCookiesEnabled,
  isTrackingAllowed,
} from '@/lib/mixpanel';
import { toast } from '@/lib/toastify';
import type { CookiesConsentForm } from '@/modules/shared/components/Organism/CookiesConsent/CookiesConsent.type';
import { AppDispatch } from '@/store';

export class AppViewModel {
  constructor() {}

  async init(dispatch: AppDispatch, i18n: TFunction): Promise<boolean> {
    const allowed = isCookiesEnabled();

    if (!allowed) {
      toast(i18n('globalErrors.errors:error_cookies_disabled') as string);
      return false;
    }

    const tracking = isTrackingAllowed();
    if (tracking) initMixpanel();

    return true;
  }

  isInViewPortObserver(
    el: HTMLElement,
    callbackIn: any,
    callbackOut?: any,
    rootMargin?: string,
  ) {
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            callbackIn();
          }, 300);

          return;
        }
        callbackOut?.();
      },
      {
        root: null,
        rootMargin: rootMargin ?? '-20%',
        threshold: 0.1,
      },
    );

    observer.observe(el);
  }

  shouldDisplayCookies(): boolean {
    const allowed = isCookiesEnabled();

    if (!allowed) return false;

    const local = localStorage?.getItem(LOCAL_STORAGE_VALUE.COOKIE_CONSENT);

    if (local) {
      const parse = JSON.parse(local);

      if (parse.strict !== undefined && parse.tracking !== undefined) {
        return false;
      }

      return true;
    }

    return true;
  }

  cookiesDisagree() {
    const refuse = JSON.stringify({
      strict: false,
      tracking: false,
    });

    localStorage.setItem(LOCAL_STORAGE_VALUE.COOKIE_CONSENT, refuse);
  }

  cookiesAcceptAll() {
    const accept = JSON.stringify({
      strict: true,
      tracking: true,
    });

    initMixpanel();

    localStorage.setItem(LOCAL_STORAGE_VALUE.COOKIE_CONSENT, accept);
  }

  cookiesAcceptSelected(selected: CookiesConsentForm) {
    if (selected.tracking) {
      initMixpanel();
    }

    localStorage.setItem(
      LOCAL_STORAGE_VALUE.COOKIE_CONSENT,
      JSON.stringify(selected),
    );
  }

  copyToClipboard(string: string, message: string) {
    navigator.clipboard.writeText(string);
    toast(message);
  }
}
