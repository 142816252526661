import {
  useMutateGoogleLogin,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MIXPANEL_EVENT, trackEvent } from '@/lib/mixpanel';
import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Tag } from '@/modules/shared/components/Atom/Tag';
import { GoogleLoginButton } from '@/modules/shared/components/Organism/GoogleLoginButton';
import { PopupClose } from '@/modules/shared/components/Template/Popups/PopupClose';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import { GlobalState } from '@/reducer';
import { API_ROUTES, GLOBAL_ROUTES } from '@/routes';

import classes from './Login.module.scss';

export const LoginPopup = ({
  options,
  description,
  title,
  onSuccessToast = true,
}: {
  title?: string;
  description?: string;
  options?: string[];
  onSuccessToast?: boolean;
}) => {
  const { t } = useTranslation(['auth', 'globals']);
  const shouldLimitOptions = !!options;
  const router = useRouter();
  const [googleLoading, setGoogleLoading] = useState(false);
  const loginWithGoogleMutation = useMutateGoogleLogin();
  const websiteVersion = useSelector(
    (state: GlobalState) => state.site.version,
  );
  const { refetch } = useUserProfile();

  const urlReferrer = router.query.referrer;
  const referrerHandle: string | undefined = Array.isArray(urlReferrer)
    ? urlReferrer[0]
    : urlReferrer;

  const availableMethods = {
    google: shouldLimitOptions ? options?.includes('google') : true,
    discord: shouldLimitOptions ? options?.includes('discord') : true,
    twitter: shouldLimitOptions ? options?.includes('twitter') : true,
    zkgoogle: shouldLimitOptions ? options?.includes('zkgoogle') : true,
    zktwitch: shouldLimitOptions ? options?.includes('zktwitch') : true,
  };
  const saveCurrentPath = useCallback(() => {
    sessionStorage.setItem('redirectTo', router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    saveCurrentPath();
  }, [saveCurrentPath]);

  return (
    <div className={`${classes.login_popup}`}>
      <div className={classes.upper_part}>
        <div className="flex middle between">
          <Tag
            label={t(`globals:${websiteVersion}`)}
            slug="public-beta"
            active
          />

          <PopupClose />
        </div>
        <div className={classes.title_section}>
          <h2 className={`${classes.popup_title} lexend-heading`}>
            {title || t('auth:login.title')}
          </h2>
          <p className={classes.popup_subtitle}>
            {description || t('auth:login.desc')}
          </p>
        </div>
      </div>

      <div className={classes.login_methods}>
        {availableMethods.google && (
          <GoogleLoginButton
            loading={googleLoading}
            onClick={() => setGoogleLoading(true)}
            label={t('auth:login.google')}
            onSuccess={(data) =>
              loginWithGoogleMutation.mutate(
                { credential: data.credential, referrerHandle },
                {
                  onSuccess: () => {
                    closeModal();
                    trackEvent(MIXPANEL_EVENT.GOOGLE_SIGNUP_SUCCESS);
                    if (onSuccessToast) {
                      toast.success(t('auth:login.login_success'));
                    }

                    const redirectPath =
                      sessionStorage.getItem('redirectTo') ||
                      GLOBAL_ROUTES.LANDING;
                    router.push(redirectPath).finally(refetch);
                  },
                },
              )
            }
            onError={() => {
              toast.error('auth:login_failed');
              setGoogleLoading(false);
            }}
          />
        )}

        {availableMethods.discord && (
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            onClick={() => {
              saveCurrentPath();
              router.push(API_ROUTES.DISCORD_LOGIN);
            }}
            size={BUTTON_SIZE.LARGE}
            target="_self"
            className={classes.login_method}
          >
            <Icon.discord size={24} color="white" />
            {t('auth:login.discord')}
          </Button>
        )}

        {availableMethods.twitter && (
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            onClick={() => {
              saveCurrentPath();
              router.push(API_ROUTES.TWITTER_LOGIN);
            }}
            size={BUTTON_SIZE.LARGE}
            className={classes.login_method}
          >
            <Icon.twitter size={24} color="white" />
            {t('auth:login.twitter')}
          </Button>
        )}

        {process.env.NEXT_PUBLIC_LOCAL_BLOCKCHAIN && (
          <>
            {availableMethods.zkgoogle && (
              <Button
                variant={BUTTON_VARIANT.TRANSPARENT}
                onClick={() => {
                  saveCurrentPath();
                  router.push(API_ROUTES.GOOGLE_ZKLOGIN);
                }}
                size={BUTTON_SIZE.LARGE}
                className={classes.login_method}
              >
                <Icon.google size={24} color="white" />
                {'Google ZKLogin'}
              </Button>
            )}
            {availableMethods.zktwitch && (
              <Button
                variant={BUTTON_VARIANT.TRANSPARENT}
                onClick={() => {
                  saveCurrentPath();
                  router.push(API_ROUTES.TWITCH_ZKLOGIN);
                }}
                size={BUTTON_SIZE.LARGE}
                className={classes.login_method}
              >
                <Icon.twitch size={24} color="white" />
                {'Twitch ZKLogin'}
              </Button>
            )}
          </>
        )}
      </div>

      <div className={classes.footnote}>
        {t('auth:login.disclaimer')}{' '}
        <Link
          href={GLOBAL_ROUTES.TERMS_AND_CONDITIONS}
          onClick={() => closeModal()}
        >
          {t('auth:login.disclaimer1')}
        </Link>{' '}
        {t('auth:login.disclaimer2')}{' '}
        <Link href={GLOBAL_ROUTES.PRIVACY_POLICY} onClick={() => closeModal()}>
          {t('auth:login.disclaimer3')}
        </Link>
      </div>
    </div>
  );
};
