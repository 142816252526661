import { UserProfileSocialType } from '@xborglabs/ui-shared';
import { UnlinkSocial } from '@xborglabs/ui-shared/dist/client';
import { Trans, useTranslation } from 'next-i18next';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { API_ROUTES, EXTERNAL_ROUTES } from '@/routes';

export type LinkSteamProps = {
  steam?: UserProfileSocialType;
};

export function LinkSteam({ steam }: LinkSteamProps) {
  useIntentToast();
  const { t } = useTranslation(['quests', 'errors', 'success']);
  const redirectTo = `?redirectTo=${encodeURIComponent(
    window.location.pathname + window.location.search + '&intent=steam',
  )}`;

  return (
    <>
      <div
        className={`quest-requirement flex between middle${
          steam ? ' fulfilled' : ''
        }`}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:steamLink')}
          </h4>
        </div>

        <div className="requirement-action">
          {steam ? (
            <Tippy
              className="lexend-body-xs1"
              content={t('quests:unlinkAccount')}
            >
              <div>
                <UnlinkSocial
                  onError={() => {
                    toast.error(
                      t('errors:globalErrors.account_unlink_rejected'),
                    );
                  }}
                  onSuccess={() => {
                    toast.success(t('success:globalSuccess.unlink_success'));
                  }}
                >
                  {({ onUnlink, isLoading }) => (
                    <Button
                      variant={BUTTON_VARIANT.TRANSPARENT}
                      onClick={() => onUnlink(steam.socialId)}
                      disabled={isLoading}
                    >
                      {steam.handle
                        ? `@${steam.handle}`
                        : t('quests:accountLinked')}
                    </Button>
                  )}
                </UnlinkSocial>
              </div>
            </Tippy>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={API_ROUTES.STEAM_CONNECT + redirectTo}
              className="lexend-heading-m action btn btn-transparent flex middle center "
            >
              {t('missions.linkAccount')}
            </Button>
          )}
        </div>
      </div>
      <div className="flex alert-simple__container between gap-large lexend-body-s">
        <Alert variant="info">
          <Trans
            i18nKey="quests:steamPublicDescription"
            components={{
              button: (
                <Button
                  variant={BUTTON_VARIANT.UNDERLINE}
                  href={EXTERNAL_ROUTES.STEAM_PUBLIC_PROFILE_DESC}
                />
              ),
            }}
          />
        </Alert>
      </div>
    </>
  );
}
