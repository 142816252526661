import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import { QuestRequirementType } from '@/modules/quests/components';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export function VisitLinkMission(props: QuestRequirementType) {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const questTemplateLink = actionContext.context.quest?.template?.args?.link;

  if (!questTemplateLink) return null;

  return (
    <>
      <div className="flex middle between visit-link-mission">
        <p className="font-bold">{t('quests:visit_link_mission_desc')}</p>
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          href={questTemplateLink}
          target="_blank"
          onClick={(e) => {
            if (
              ![
                QuestDisabledReason.COMMUNITY_GUEST,
                QuestDisabledReason.EVENT_GUEST,
                QuestDisabledReason.EVENT_JOIN_NOT_ALLOWED,
                QuestDisabledReason.GUEST_USER,
                QuestDisabledReason.MAX_ATTEMPTS_REACHED,
                QuestDisabledReason.QUEST_PRECONDITION_NOT_MET,
              ].includes(actionContext.reason)
            ) {
              props.setDisabled?.(false);
            } else {
              e.preventDefault();
              toast.error(
                t(`quests:actionRequiredReason.${actionContext.reason}`),
              );
            }
          }}
        >
          {t('quests:visit_link_mission')}
        </Button>
      </div>
    </>
  );
}
