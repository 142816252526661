import { Brand } from '@/modules/shared/components/Atom/Brand';

import { BrowseLinks } from './BrowseLinks';
import { MobileMenu } from './MobileMenu';
import classes from './Sidebar.module.scss';
import { UserMenu } from './UserMenu';
export const Sidebar = () => {
  return (
    <>
      <aside className={`${classes.sidebar}`}>
        <div className={classes.upper_section}>
          <Brand size={16} />
          <BrowseLinks />
        </div>
        <UserMenu />
      </aside>
      <MobileMenu />
    </>
  );
};
