export enum MODAL {
  LOGIN = 'login',
  CONNECT_WALLET = 'connect-wallet',
  DELETE_ACCOUNT = 'delete-account',
  LOGOUT = 'logout',
  QUEST_COMPLETE = 'quest-completed',
  CLAIM_USERNAME = 'claim-username',
  USERNAME_CLAIMED = 'username-claimed',
  QUEST_DETAILS = 'quest-details',
  EQUIP_INVENTORY_ITEM = 'equip-inventory-item',
  REQUEST_DATA = 'request-data',
  LEAVE_EVENT = 'leave-event',
  LEAVE_COMMUNITY = 'leave-community',
  LEVEL_UP = 'level-up',
  QUEST_PENDING = 'quest-pending',
  UNLINK_ACCOUNT = 'unlink-account',
  COMMUNITY_REFERRAL = 'community-referral',
  ACQUISITION_RULES = 'acquisition-rules',
  NOT_WHITELISTED = 'not-whitelisted',
  GAMING_CARD_FORGED = 'gaming-card-forged',
  APEIRON_SOCIAL_BINDING = 'apeiron-social-binding',
}
