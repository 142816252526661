import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'react-i18next';

import { GlassCard } from '@/modules/shared/components/Atom/Card/Card';

import classes from './CheckLevel.module.scss';

export function CheckLevel() {
  const { context } = useQuestActionContext();
  const { t } = useTranslation(['quests']);

  return (
    <GlassCard className={classes.check_level_container}>
      <h3>
        {t('quests:reachLevel', {
          level: context.quest?.template.args.level,
        })}
      </h3>
    </GlassCard>
  );
}
