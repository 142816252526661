import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import {
  IconButton,
  IconButtonProps,
} from '@/modules/shared/components/Atom/Buttons/IconButton/IconButton';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { AppViewModel } from '@/modules/viewmodels';
import { GLOBAL_ROUTES } from '@/routes';

export const ReferralInput = ({
  variant,
}: {
  variant?: IconButtonProps['variant'];
}) => {
  const { t } = useTranslation(['success']);
  const { data: authState } = useUserProfile();
  const profile = authState?.profile;
  const handle = profile?.profile?.handle ?? '';
  const trimHandle = handle.replace(/\s/g, '');
  const referralLink = `${process.env.NEXT_PUBLIC_FRONTEND_URL}${GLOBAL_ROUTES.REFER}/${trimHandle}`;
  const appVM = new AppViewModel();

  return (
    <div className="referral-input w-full flex column">
      <div className="flex middle referral-input-wrapper">
        <input
          type="text"
          value={referralLink}
          className="lexend-body-m expand referral-input-text"
          readOnly
        />
        <IconButton
          variant={variant}
          role="button"
          className="btn-copy flex middle center"
          onClick={(e) => {
            e.preventDefault();
            appVM.copyToClipboard(
              referralLink,
              t('success:globalSuccess.copied_to_clipboard'),
            );
          }}
        >
          <Icon.copy size={21} color={'white'} />
        </IconButton>
      </div>
    </div>
  );
};
