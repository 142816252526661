import { QuestDetailsType } from '@xborglabs/ui-shared';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';

import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

export function QuestPreconditionRequirement({
  data,
}: {
  data?: QuestDetailsType;
}) {
  const router = useRouter();

  function handleOnClick() {
    router.query.qid = data!.questId;
    router.replace(router, undefined, { shallow: true });
    openModal(MODAL.QUEST_DETAILS, {
      qid: router.query.qid,
    });
  }
  if (!data) {
    return null;
  }

  return (
    <div className="precondition">
      <Alert variant="warning">
        <Trans
          i18nKey="quests:firstComplete"
          components={{
            button: (
              <Button
                variant={BUTTON_VARIANT.UNDERLINE}
                onClick={handleOnClick}
              />
            ),
          }}
          values={{ questTitle: data.title }}
        />
      </Alert>
    </div>
  );
}
