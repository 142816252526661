import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { LinkYoutube, QuestRequirementType } from '@/modules/quests/components';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export const YoutubeSubscribeMission = ({
  setDisabled,
}: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const socials = actionContext.context.authState?.socials;
  const quest = actionContext.context.quest;

  const youtube = socials?.find(
    (item: any) => item.accountType === USER_SOCIAL_TYPE.YOUTUBE,
  );

  useEffect(() => {
    if (youtube) setDisabled?.(false);
  }, []);

  const href = `https://www.youtube.com/${quest?.template.args.youtubeHandle}`;

  return (
    <>
      <LinkYoutube youtube={youtube} />

      <div
        className={`quest-requirement flex between middle${
          !youtube ? ' disabled' : ''
        }
        ${
          actionContext.reason === QuestDisabledReason.QUEST_COMPLETED
            ? ' fulfilled'
            : ''
        }
        `}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:youtubeSubscribe', {
              name: quest?.template.args.displayHandle,
            })}
          </h4>
        </div>

        <div className="requirement-action">
          {actionContext.reason === QuestDisabledReason.QUEST_COMPLETED ? (
            <Button variant={BUTTON_VARIANT.TRANSPARENT} disabled>
              {t('quests:subscribing')}
            </Button>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={href}
              target="_blank"
              disabled={!youtube}
            >
              {t('quests:subscribe')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
