import { UserProfileSocialType } from '@xborglabs/ui-shared';
import {
  UnlinkSocial,
  useMutateSocials,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { DropdownSelect } from '@/modules/shared/components/Molecule/Forms/Dropdown';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';
import { LOL_REGIONS } from '@/modules/shared/constants/LeagueOfLegendsRegions';
import { API_ROUTES } from '@/routes';

export type LinkRiotProps = {
  riot?: UserProfileSocialType;
};

export function LinkRiot({ riot }: LinkRiotProps) {
  useIntentToast();
  const { t } = useTranslation(['quests', 'errors', 'success']);
  const redirectTo = `?redirectTo=${encodeURIComponent(
    window.location.pathname + window.location.search + '&intent=riot',
  )}`;
  const mutateSocials = useMutateSocials();

  function updateRegion({
    slug,
  }: {
    slug: (typeof LOL_REGIONS)[number]['slug'];
  }) {
    mutateSocials.mutate({ server: slug, socialId: riot?.socialId });
  }

  return (
    <>
      <div
        className={`quest-requirement link-riot__requirement flex between middle${
          riot ? ' fulfilled' : ''
        }`}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:riotLink')}
          </h4>
        </div>
        <div className="requirement-action">
          {riot ? (
            <div className="flex middle">
              <Tippy
                className="lexend-body-xs1"
                content={t('quests:unlinkAccount')}
              >
                <div>
                  <UnlinkSocial
                    onError={() => {
                      toast.error(
                        t('errors:globalErrors.account_unlink_rejected'),
                      );
                    }}
                    onSuccess={() => {
                      toast.success(t('success:globalSuccess.unlink_success'));
                    }}
                  >
                    {({ onUnlink, isLoading }) => (
                      <Button
                        variant={BUTTON_VARIANT.TRANSPARENT}
                        onClick={() => onUnlink(riot.socialId)}
                        disabled={isLoading}
                      >
                        {riot.handle ? riot.handle : t('quests:accountLinked')}
                      </Button>
                    )}
                  </UnlinkSocial>
                </div>
              </Tippy>
            </div>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={API_ROUTES.RIOT_CONNECT + redirectTo}
              className="lexend-heading-m action btn btn-transparent flex middle center "
            >
              {t('missions.linkAccount')}
            </Button>
          )}
        </div>
      </div>
      {riot && !riot.server ? (
        <div className="lexend-body-xs1 flex middle between">
          <Alert variant="info">{t('quests:riotServerWarning')}</Alert>

          <DropdownSelect<(typeof LOL_REGIONS)[number]>
            anchor="anchor-top-right"
            onChange={updateRegion} // TODO: This should be fixed in DropdownSelect type
            list={LOL_REGIONS}
          >
            {mutateSocials.isLoading ? (
              <LoadingSpinner />
            ) : (
              <button className="select-region-trigger font-bold flex middle">
                {t('quests:regionSelect')}

                <Icon.chevronLeft size={16} className="stroke" />
              </button>
            )}
          </DropdownSelect>
        </div>
      ) : null}
    </>
  );
}
