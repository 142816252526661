export type TagProps = {
  label: string;
  slug: string;
  active?: boolean;
  icon?: any;
  suffix?: any;
  disabled?: boolean;
  top?: string;
  onClick?: (val: string) => void;
};

export const Tag = ({
  label,
  slug,
  active,
  onClick,
  icon,
  suffix,
  disabled,
  top,
}: TagProps) => {
  return (
    <span
      className={`flex middle center single-tag lexend-body-s ${
        active ? 'active' : 'inactive'
      }${disabled ? ' disabled' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(slug);
      }}
    >
      {icon}
      <span className="label">{label}</span>
      {suffix}

      {top && <span className="top">{top}</span>}
    </span>
  );
};
