import { CommunityDetail } from '@xborglabs/ui-shared/dist/client';
import { AnimatePresence, m } from 'framer-motion';
import Image from 'next/image';

import { Images } from '@/assets/imgs';

import { CommunityStats } from './CommunityStats/CommunityStats';
import { useGamerCard } from './mockQuery';

export function GamerCard({ communityId }: { communityId: string }) {
  const { data: gamerCardInfo, isError } = useGamerCard({
    communityKey: communityId,
  });

  if (isError) return null;
  return (
    <CommunityDetail communityKey={communityId}>
      {({ community, isLoading }) => (
        <AnimatePresence>
          {community && gamerCardInfo && !isLoading ? (
            <m.div
              initial={{
                opacity: 0,
                y: -30,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              className={`gamer-card gamer-card-${community.slug}`}
            >
              <Image
                src={gamerCardInfo.bg}
                alt={community.name}
                fill
                className="gamer-card__bg"
                id="gaming-card-background"
              />
              <Image
                src={gamerCardInfo.fg}
                alt={`${community.name} foreground`}
                fill
                id="gaming-card-foreground"
                className="gamer-card__fg"
              />
              <Image
                src={gamerCardInfo.border}
                alt={community.name}
                fill
                id="gaming-card-border"
                className="gamer-card__bg gamer-card__bg--border"
              />
              <Image
                src={
                  community.content?.images?.logoImageUrl ??
                  Images.communityview.logo
                }
                width={48}
                height={48}
                alt={community.name}
                className="gamer-card__logo"
              />
              <Image
                src="/images/logo.svg"
                width={48}
                height={48}
                alt={community.name}
                className="gamer-card__host-logo"
              />
              <CommunityStats
                community={community.slug}
                communityId={communityId}
              />
            </m.div>
          ) : null}
        </AnimatePresence>
      )}
    </CommunityDetail>
  );
}
