import { QUEST_STATUS, USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import {
  QuestRequiredAction,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { LinkDiscord, QuestRequirementType } from '@/modules/quests/components';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export const DiscordServerMission = ({ setDisabled }: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const completed = quest?.status === QUEST_STATUS.COMPLETED;
  const socials = actionContext.context.authState?.socials;
  const discord = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.DISCORD,
  );

  useEffect(() => {
    if (discord) {
      setDisabled?.(false);
    }
  }, []);

  useEffect(() => {
    setDisabled?.(discord ? false : true);
  }, [discord]);

  return (
    <>
      <LinkDiscord discord={discord} />

      <div
        className={`quest-requirement flex between middle${
          !discord ? ' disabled' : ''
        }
        ${completed ? ' fulfilled' : ''}
        `}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:discordServer', {
              name: quest?.template.args.displayHandle,
            })}
          </h4>
        </div>

        <div className="requirement-action">
          {completed ? (
            <Button variant={BUTTON_VARIANT.TRANSPARENT} disabled>
              {t('quests:joined')}
            </Button>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={quest?.template.args.guildInvite ?? ''}
              target="_blank"
              disabled={actionContext.action === QuestRequiredAction.STOP}
            >
              {t('quests:joinDiscord')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
