import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';

import { FeatureFlagGate } from '@/modules/shared/components/Organism/FeatureFlagGate';
import { Navigation } from '@/modules/shared/components/Organism/Navigation';
import { LoaderPage } from '@/modules/shared/components/Template/Loader';
import { Sidebar } from '@/modules/shared/components/Template/Sidebar';
import { handleRouting } from '@/modules/utils/routing';
import { AppViewModel } from '@/modules/viewmodels/AppViewModel';
import { GLOBAL_ROUTES } from '@/routes';
import { AppDispatch } from '@/store';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export type BorderState = 'sidebar' | 'navigation' | 'none';

export type LayoutProps = {
  children: any;
  overrideBorderState?: BorderState;
  overrideFeature?: boolean;
};

export const Layout = ({
  children,
  overrideBorderState,
  overrideFeature,
}: LayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation(['errors']);
  const router = useRouter();
  const { data: authState, isLoading } = useUserProfile();
  const appVM = new AppViewModel();
  const borderState: BorderState =
    overrideBorderState ??
    (authState?.isLoggedIn || router.pathname !== GLOBAL_ROUTES.LANDING
      ? 'sidebar'
      : 'navigation');

  useEffect(() => {
    if (authState?.state) {
      handleRouting(router, authState.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.state, router.asPath]);

  useEffect(() => {
    appVM.init(dispatch, t);
  }, []);

  useEffect(() => {
    if (borderState === 'sidebar') {
      document.body.style.setProperty('--sidebar-width', '72px');
    } else {
      document.body.style.setProperty('--sidebar-width', '0px');
    }
  }, [borderState]);

  // Keep the page on loading state while we are figuring out the state of user.
  if (isLoading || !authState) return <LoaderPage />;

  const className = borderState === 'sidebar' ? 'nav-hidden' : 'nav-visible';
  const Wrapper = overrideFeature ? Fragment : FeatureFlagGate;

  return (
    <Wrapper>
      {match(borderState)
        .with('sidebar', () => <Sidebar />)
        .with('navigation', () => <Navigation />)
        .otherwise(() => null)}
      <div className="flex column">
        <div className={`layout-content w-full ${className}`}>{children}</div>
      </div>
    </Wrapper>
  );
};
