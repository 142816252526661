import { USER_AUTH_STATE } from '@xborglabs/ui-shared';
import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { LDFlagSet, useLDClient } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';

import { APP_FEATURE_FLAGS } from '@/lib/launchdarkly/FeatureFlags';
import { LoaderPage } from '@/modules/shared/components/Template/Loader';
import MaintenancePage from '@/pages/maintenance';
import { GLOBAL_ROUTES } from '@/routes';

type FeatureFlagGateProps = { children: ReactNode };

const ALLOWED_PUBLIC_PAGES = [
  GLOBAL_ROUTES.TERMS_AND_CONDITIONS,
  GLOBAL_ROUTES.PRIVACY_POLICY,
];

const isDevMode = process.env.NODE_ENV !== 'production';
// const isDevMode = false;
export const FeatureFlagGate = ({ children }: FeatureFlagGateProps) => {
  const client = useLDClient();
  const { data: authState, isLoading } = useUserProfile();
  const [flags, setFlags] = useState<LDFlagSet | undefined>(undefined);
  const router = useRouter();
  const shouldIdentify = useMemo(
    () =>
      authState?.state &&
      [
        USER_AUTH_STATE.ONBOARDING,
        USER_AUTH_STATE.POST_CLAIM,
        USER_AUTH_STATE.NOT_REGISTERED,
      ].includes(authState?.state),
    [authState?.state],
  );

  useEffect(() => {
    if (!client || isLoading || !authState || !shouldIdentify) return;
    const payload = authState?.profile
      ? {
          key: authState.profile?.userId ?? authState.displayName,
          name: authState.displayName,
          email: authState.profile?.profile?.email,
        }
      : {
          kind: 'user',
          anonymous: true,
        };

    client.identify(payload).then((flags) => {
      setFlags(flags);
    });
  }, [client, authState, isLoading, shouldIdentify]);

  return match({
    client,
    flags,
    state: authState?.state,
    isPublic: ALLOWED_PUBLIC_PAGES.includes(router.pathname),
    isDevMode,
  })
    .with(
      P.union(
        {
          isPublic: true,
        },
        {
          isDevMode: true,
        },
      ),
      () => <>{children}</>,
    )
    .with(
      {
        flags: {
          [APP_FEATURE_FLAGS.MAINTENANCE]: true,
        },
      },
      () => <MaintenancePage />,
    )
    .with(
      {
        client: P.nullish,
        state: P.not(USER_AUTH_STATE.NOT_REGISTERED),
      },
      () => <LoaderPage />,
    )
    .with(
      {
        flags: P.nullish,
      },
      () => <LoaderPage />,
    )
    .with(
      P.union({
        flags: {
          [APP_FEATURE_FLAGS.PRERELEASE]: true,
        },
      }),
      () => <>{children}</>,
    )
    .otherwise(() => <LoaderPage />);
};
