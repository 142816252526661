import { AnimatePresence, m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import { useOutsideClick } from '@/modules/shared/hooks/useOutsideClick';
import { GlobalState } from '@/reducer';
import { MODAL_STATE, MODAL_TYPE } from '@/redux/modals/types';

import { PopupInner } from './PopupInner';
import classes from './Popups.module.scss';

export const Popups = () => {
  const router = useRouter();
  const modal = useSelector((state: GlobalState) => state.modals);
  const popupVisible =
    modal.state !== MODAL_STATE.UNINITIALIZED &&
    modal.data?.type == MODAL_TYPE.POPUP;
  const ref = useOutsideClick<HTMLDivElement>(() => {
    console.log('clicked outside');
  });

  const state = modal.state.toLowerCase();
  const options = modal.data?.options ?? {};

  useEffect(() => {
    // Enable/disable scroll
    if (popupVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [popupVisible]);

  return (
    <div
      className={`${classes.page_popups_container} page-popups-container-${modal
        .data?.content} ${
        popupVisible ? classes.container_open : classes.container_closed
      }`}
    >
      <AnimatePresence mode="sync">
        {popupVisible ? (
          <>
            <PopupInner modal={modal} state={state} />
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`${classes.popup_underlay} ${
                classes[`popup_state_${state}`]
              }`}
              onClick={() =>
                options?.closable === undefined || options?.closable === true
                  ? closeModal(router)
                  : null
              }
            />
          </>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
