import { QUEST_STATUS } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';

export type QuestStatusImageProps = {
  status?: QUEST_STATUS;
};

export const QuestStatusImage = ({ status }: QuestStatusImageProps) => {
  const { t } = useTranslation(['quests']);

  const getStatus = () => {
    switch (status) {
      case QUEST_STATUS.RESOLVING:
      case QUEST_STATUS.ACTIVE:
        return t('quests:status.inReview');
      case QUEST_STATUS.ABANDONED:
        return t('quests:status.abandoned');
      case QUEST_STATUS.COMPLETED:
        return t('quests:status.completed');
      case QUEST_STATUS.FAILED:
        return t('quests:status.failed');

      default:
        return t('quests:status.notStarted');
    }
  };

  return (
    <div className={`quest-status-gaming flex middle ${status?.toLowerCase()}`}>
      <div className="circle" />

      <span className="lexend-body-xxs">{getStatus()}</span>
    </div>
  );
};
