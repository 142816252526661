import { useTranslation } from 'react-i18next';

import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import classes from './GuestUserFooter.module.scss';
import { BUTTON_SIZE } from '../../../../shared/components/Atom/Buttons/Button';
import { DisplayParticipants } from '../DisplayParticipants/DisplayParticipants';

export function GuestUserFooter() {
  return (
    <div className={classes.guest_user_footer}>
      <p className={classes.participants}>
        <DisplayParticipants />
      </p>
      <SignUpToJoinButton />
    </div>
  );
}

function SignUpToJoinButton() {
  const { t } = useTranslation(['quests']);

  return (
    <Button
      variant={BUTTON_VARIANT.BLUE}
      className={classes.sign_up_to_join_button}
      size={BUTTON_SIZE.SMALL}
      onClick={() => {
        openModal(MODAL.LOGIN);
      }}
    >
      {t('quests:signupToJoinButton')}
    </Button>
  );
}
