import { UserProfileSocialType } from '@xborglabs/ui-shared';
import {
  UnlinkSocial,
  useMutateSocials,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { DropdownSelect } from '@/modules/shared/components/Molecule/Forms/Dropdown';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';
import { BattlenetRegions } from '@/modules/shared/constants/BattlenetRegions';
import { WowCharacterSelection } from '@/modules/user/components/AccountManagement/Battlenet/WowCharacterSelection';
import { API_ROUTES } from '@/routes';

export type LinkBattlenetProps = {
  battlenet?: UserProfileSocialType;
  showRegionSelector?: boolean;
  showWowCharacterSelector?: boolean;
};

export function LinkBattlenet({
  battlenet,
  showWowCharacterSelector,
}: LinkBattlenetProps) {
  useIntentToast();
  const { t } = useTranslation(['quests', 'errors', 'success']);
  const redirectTo = `?redirectTo=${encodeURIComponent(
    window.location.pathname + window.location.search + '&intent=battlenet',
  )}`;

  const mutateSocials = useMutateSocials();
  function updateRegion({
    slug,
  }: {
    slug: (typeof BattlenetRegions)[number]['slug'];
  }) {
    mutateSocials.mutate({ server: slug, socialId: battlenet?.socialId });
  }

  return (
    <>
      <div
        className={`quest-requirement flex between middle${
          battlenet ? ' fulfilled' : ''
        }`}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:battlenetLink')}
          </h4>
        </div>

        <div className="requirement-action">
          {battlenet ? (
            <Tippy
              className="lexend-body-xs1"
              content={t('quests:unlinkAccount')}
            >
              <div>
                <UnlinkSocial
                  onError={() => {
                    toast.error(
                      t('errors:globalErrors.account_unlink_rejected'),
                    );
                  }}
                  onSuccess={() => {
                    toast.success(t('success:globalSuccess.unlink_success'));
                  }}
                >
                  {({ onUnlink, isLoading }) => (
                    <Button
                      variant={BUTTON_VARIANT.TRANSPARENT}
                      onClick={() => onUnlink(battlenet.socialId)}
                      disabled={isLoading}
                    >
                      {battlenet.handle
                        ? `@${battlenet.handle}`
                        : t('quests:accountLinked')}
                    </Button>
                  )}
                </UnlinkSocial>
              </div>
            </Tippy>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={API_ROUTES.BATTLENET_CONNECT + redirectTo}
              className="lexend-heading-m action btn btn-transparent flex middle center "
            >
              {t('missions.linkAccount')}
            </Button>
          )}
        </div>
      </div>
      {battlenet && !battlenet.server ? (
        <div
          className="lexend-body-xs1 flex middle between"
          style={{ marginTop: '1rem' }}
        >
          <div className="flex middle gap-normal">
            <Icon.info size={24} color="white" />
            <span>{t('quests:battlenetServerWarning')}</span>
          </div>
          <DropdownSelect
            anchor="anchor-top-right"
            onChange={updateRegion}
            list={BattlenetRegions}
          >
            {mutateSocials.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Button variant={BUTTON_VARIANT.TRANSPARENT}>
                {t('quests:regionSelect')}
              </Button>
            )}
          </DropdownSelect>
        </div>
      ) : null}
      {showWowCharacterSelector ? <WowCharacterSelection /> : null}
    </>
  );
}
