import { QUEST_STATUS, getStatus } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';
import { forwardRef } from 'react';
import { P, match } from 'ts-pattern';

import { Tippy } from '@/lib/tippy';

export type QuestStatusProps = {
  status?: QUEST_STATUS;
};

export const QuestStatus = ({ status }: QuestStatusProps) => {
  const { t } = useTranslation(['quests']);

  const statusLabel = getStatus(t)(status);

  return (
    <Tippy content={statusLabel} className="lexend-body-xs1">
      <div className="quest-status">
        <StatusCircle status={status}></StatusCircle>
      </div>
    </Tippy>
  );
};

const StatusCircle = forwardRef<SVGSVGElement, QuestStatusProps>(
  ({ status }, ref) => {
    return match(status)
      .with(P.union(QUEST_STATUS.FAILED), () => (
        <svg
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.58872 8C1.58872 11.5408 4.45915 14.4113 8 14.4113C11.5408 14.4113 14.4113 11.5408 14.4113 8C14.4113 4.45915 11.5408 1.58872 8 1.58872C4.45915 1.58872 1.58872 4.45915 1.58872 8Z"
            fill="#D32F2F"
          />
        </svg>
      ))
      .with(QUEST_STATUS.INACTIVE, () => (
        <svg
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.58872 8C1.58872 11.5408 4.45915 14.4113 8 14.4113C11.5408 14.4113 14.4113 11.5408 14.4113 8C14.4113 4.45915 11.5408 1.58872 8 1.58872C4.45915 1.58872 1.58872 4.45915 1.58872 8Z"
            fill="white"
            fillOpacity="0.2"
          />
        </svg>
      ))
      .with(QUEST_STATUS.ACTIVE, () => (
        <svg
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.58872 8C1.58872 11.5408 4.45915 14.4113 8 14.4113C11.5408 14.4113 14.4113 11.5408 14.4113 8C14.4113 4.45915 11.5408 1.58872 8 1.58872C4.45915 1.58872 1.58872 4.45915 1.58872 8Z"
            fill="#4D89FF"
          />
        </svg>
      ))
      .with(QUEST_STATUS.RESOLVING, () => (
        <svg
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 6.34255e-08 8 -3.49691e-07L8 1.58872C9.70038 1.58872 11.3311 2.2642 12.5335 3.46654C13.7358 4.66889 14.4113 6.29962 14.4113 8C14.4113 9.70038 13.7358 11.3311 12.5335 12.5335C11.3311 13.7358 9.70038 14.4113 8 14.4113L8 16Z"
            fill="#FE8D25"
          />
          <path
            d="M8 -3.49691e-07C5.87827 -2.56947e-07 3.84344 0.842854 2.34315 2.34315C0.842854 3.84344 -2.82249e-07 5.87827 -3.49691e-07 8C-4.17134e-07 10.1217 0.842854 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16L8 14.4113C6.29962 14.4113 4.66889 13.7358 3.46654 12.5335C2.2642 11.3311 1.58872 9.70038 1.58872 8C1.58872 6.29962 2.2642 4.66889 3.46654 3.46654C4.66889 2.2642 6.29962 1.58872 8 1.58872L8 -3.49691e-07Z"
            fill="white"
            fillOpacity="0.2"
          />
        </svg>
      ))
      .with(QUEST_STATUS.COMPLETED, () => (
        <svg
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.58872 8C1.58872 11.5408 4.45915 14.4113 8 14.4113C11.5408 14.4113 14.4113 11.5408 14.4113 8C14.4113 4.45915 11.5408 1.58872 8 1.58872C4.45915 1.58872 1.58872 4.45915 1.58872 8Z"
            fill="#44FF57"
          />
        </svg>
      ))
      .otherwise(() => null);
  },
);

StatusCircle.displayName = 'StatusCircle';
