import {
  AUTH_COOKIES,
  ApiBase,
  isUnauthorizedCall,
} from '@xborglabs/ui-shared';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { defaultConfig } from '@/lib/axios/axiosConfig';
import { RequestQueue } from '@/modules/utils/request-queue';

import { tryRefreshToken } from './token';

export function createClientApi(tenant?: string) {
  const cookieStore = new Cookies();
  const sessionToken = cookieStore.get(AUTH_COOKIES.SESSION_TOKEN);
  const api = axios.create(defaultConfig);
  const apiContext = ApiBase.getInstance();

  const queue = new RequestQueue();
  api.interceptors.request.use((config) => {
    if (tenant) {
      config.headers['xb-tenant'] = tenant;
    }
    if (config.url && isUnauthorizedCall(config.url)) {
      delete config.headers.Authorization;
    } else {
      if (sessionToken) {
        config.headers.Authorization = `Bearer ${sessionToken}`;
      }
    }
    return config;
  });

  api.interceptors.response.use(
    (fulfilled) => fulfilled,
    (rejected) => tryRefreshToken(queue)(rejected),
  );

  apiContext.setApi(api);
  apiContext.setTokens({
    session: AUTH_COOKIES.SESSION_TOKEN,
    refresh: AUTH_COOKIES.REFRESH_TOKEN,
  });

  return api;
}
