import { createReducer } from '@reduxjs/toolkit';

import { InventoryActionTypes, InventoryInterface } from './types';

interface Action<T, P> {
  readonly type: T;
  readonly payload?: P;
}

const initialState: InventoryInterface = {
  avatar: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    InventoryActionTypes.SET_AVATAR_LOADING,
    (
      state,
      actions: Action<
        InventoryActionTypes,
        {
          communityKey: string;
          isLoading: boolean;
        }
      >,
    ) => {
      if (actions.payload) {
        state.avatar[actions.payload.communityKey] = {
          ...state.avatar[actions.payload.communityKey],
          isLoading: actions.payload.isLoading,
        };
      }
    },
  );

  builder.addCase(
    InventoryActionTypes.UPDATE_AVATAR,
    (state, actions: Action<InventoryActionTypes, any>) => {
      state.avatar[actions.payload.communityKey] = {
        ...state.avatar[actions.payload.communityKey],
        ...actions.payload,
      };
    },
  );

  builder.addCase(
    InventoryActionTypes.TRIGGER_CHANGE_AVATAR,
    (state, actions: Action<InventoryActionTypes, string>) => {
      if (actions.payload) {
        state.avatar[actions.payload] = {
          ...state.avatar[actions.payload],
          changedAt: state.avatar[actions.payload].updatedAt ?? undefined,
        };
      }
    },
  );
});

export { reducer as InventoryReducer };
