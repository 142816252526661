import { QuestDetailsType } from '@xborglabs/ui-shared';
import { useResource } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import {
  CreditResourceReward,
  DiscordReward,
  ItemReward,
} from '@/modules/shared/components/Template/Popups/content/QuestComplete';

export function QuestCompleteReward({
  reward,
  containerId,
  amount,
  communitySlug,
  onClose,
}: {
  containerId: string;
  reward: QuestDetailsType['reward'];
  amount?: number;
  communitySlug?: string;
  onClose: () => void;
}) {
  const { t } = useTranslation(['globals']);
  const {
    data: resourceType,
    isLoading,
    isError,
  } = useResource(reward.args?.resourceTypeId);

  const rewardName = resourceType?.name ?? t('globals:xp');

  if (!reward) {
    return null;
  }

  if (reward.name === 'give_assets') {
    return (
      <ItemReward
        reward={reward}
        containerId={containerId}
        communitySlug={communitySlug}
        onClose={onClose}
      />
    );
  }

  if (reward.name === 'assign_discord_role') {
    return <DiscordReward reward={reward} onClose={onClose} />;
  }

  return (
    <CreditResourceReward
      isLoading={isLoading}
      isError={isError}
      resourceType={resourceType}
      rewardName={rewardName!}
      amount={amount ?? 0}
      onClose={onClose}
    />
  );
}
