import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import {
  useMutateUnlinkSocial,
  useUserProfile,
  useWowCharacters,
} from '@xborglabs/ui-shared/dist/client';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/toastify';

export function useWorldOfWarcraftCharacters() {
  const { t } = useTranslation(['errors']);
  const mutateUnlinkSocial = useMutateUnlinkSocial();
  const { data: authState } = useUserProfile();
  const battlenet = authState?.socials?.find(
    (social) => social.accountType === USER_SOCIAL_TYPE.BATTLENET,
  );

  return useWowCharacters({
    enabled: !!battlenet,
    onError: (error) => {
      if (error instanceof AxiosError) {
        const errorCode = error.response?.data?.code;

        switch (errorCode) {
          case 10045:
            toast.error(
              'Battlenet token expired. Please re-link your battlenet account',
            );
            if (!battlenet?.socialId) return;
            mutateUnlinkSocial.mutate(battlenet.socialId);
            return;
          case 10026:
            toast.error(t('errors:globalErrors:wow_character_not_found'));
            return [];
          default:
            return null;
        }
      }
    },
  });
}
