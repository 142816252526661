import { ResourceType } from '@xborglabs/ui-shared';
import Image from 'next/image';

import { Images } from '@/assets/imgs';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';
import { loaderDataUrl } from '@/modules/shared/components/Template/Loader/LoaderDataUrl';

import styles from './ResourceImage.module.scss';

export function ResourceImage({
  isError,
  resourceType,
  isLoading,
}: {
  isError: boolean;
  isLoading: boolean;
  resourceType: ResourceType | undefined;
}) {
  const imageUrl = resourceType?.images?.resourceImageUrl;
  if (isLoading) {
    return <LoadingSpinner size="small" />;
  }
  if (isError || (!imageUrl && !isLoading)) {
    return (
      <Image
        src={Images.points.points_blue}
        alt={resourceType?.name ?? 'XP'}
        placeholder="blur"
        blurDataURL={loaderDataUrl}
        fill
        className={styles['resource-image']}
      />
    );
  }
  if (!imageUrl) return null;

  return (
    <Image
      src={imageUrl}
      alt={resourceType?.name ?? ''}
      placeholder="blur"
      blurDataURL={loaderDataUrl}
      fill
      className={styles['resource-image']}
    />
  );
}
