export const APP_FEATURE_FLAGS = {
  PRERELEASE: 'release-gamerbase',
  MAINTENANCE: 'maintenance-mode',
  AIRDROP_SECTION: 'kill-switch-airdrop-section',
  LEADERBOARD_SECTION: 'kill-switch-leaderboard-section',
} as const;

export const HOOK_FLAGS = {
  PRERELEASE: 'releaseGamerbase',
  MAINTENANCE: 'maintenanceMode',
  KILL_SWITCH: {
    APEIRON: 'killSwitchApeironSocialBinding',
    STEAM: 'killSwitchSteamSocialBinding',
    BATTLENET: 'killSwitchBattlenetSocialBinding',
    RIOT: 'killSwitchRiotSocialBinding',
    AIRDROP_SECTION: 'killSwitchAirdropSection',
    LEADERBOARD_SECTION: 'killSwitchLeaderboardSection',
  },
};
