import { QUEST_TYPE } from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { QuestStatusGaming } from '@/modules/quests/components';
import { getQuestIcon } from '@/modules/quests/utils';
import { PopupClose } from '@/modules/shared/components/Template/Popups';

import classes from './QuestDetailsHeader.module.scss';
export const QuestDetailsHeader = () => {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const { t } = useTranslation(['quests']);
  const label: string = quest?.template.tags?.[0]?.label ?? t('quests:other');
  const name = quest?.template?.name ?? '';

  return (
    <div className={classes.header}>
      <div className={classes.content}>
        <div className={classes.tag_title}>
          {name && (
            <Image
              src={getQuestIcon(name)}
              width={20}
              height={20}
              alt={label}
            />
          )}
          <span>{label}</span>
        </div>
        {quest?.questType === QUEST_TYPE.GAMING && (
          <QuestStatusGaming status={quest?.status} />
        )}
        <h3 className={`${classes.title}`}>{quest?.title ?? <Skeleton />}</h3>
      </div>

      <PopupClose />
    </div>
  );
};
