import { LeaderboardInterface } from '@xborglabs/ui-shared';

import { InventoryReducer } from './redux/inventory/reducers';
import { InventoryInterface } from './redux/inventory/types';
import { ModalsReducer } from './redux/modals/reducers';
import { ModalsInterface } from './redux/modals/types';
import { SiteReducer } from './redux/site/reducers';
import { SiteInterface } from './redux/site/types';

export interface GlobalState {
  site: SiteInterface;
  modals: ModalsInterface;
  inventory: InventoryInterface;
  leaderboard: LeaderboardInterface;
}

const reducers = {
  site: SiteReducer,
  modals: ModalsReducer,
  inventory: InventoryReducer,
};

export default reducers;
