import {
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  QUEST_STATUS,
  QuestsFiltersType,
  shouldDisplayCompleteButton,
} from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  guardQuestSubmit,
  useMutateCompleteQuest,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { match } from 'ts-pattern';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { DisplayParticipants } from '../DisplayParticipants/DisplayParticipants';
import { QuestLimit } from '../QuestLimit';
import { QuestRewards } from '../QuestRewards';

export const CompleteOnlyQuestFooter = ({
  disabled,
  payload,
}: {
  disabled?: boolean;
  filters?: QuestsFiltersType;
  payload?: any;
}) => {
  const { t } = useTranslation(['quests', 'errors']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const isResolving = quest?.status === QUEST_STATUS.RESOLVING;
  const mutateCompleteQuest = useMutateCompleteQuest();
  const displayComplete = shouldDisplayCompleteButton(
    quest?.status,
    quest?.template.name,
  );

  function unstuck() {
    tryComplete();
  }

  function tryComplete() {
    guardQuestSubmit(actionContext.reason, () => {
      const id = quest?.questId;
      if (!id) {
        return;
      }
      mutateCompleteQuest.mutate(
        { id, payload },
        {
          onSuccess: async () => {
            openModal(MODAL.QUEST_PENDING, {
              qid: { id },
            });
          },
          onError: () => {
            toast.error(t('errors:globalErrors.quest_complete_rejected'));
          },
        },
      );
    });
  }

  if (!quest) return null;

  return (
    <>
      <div className="lexend-body-xs1">
        <QuestLimit
          attemptCount={quest.attemptCount}
          attemptLimit={quest.attemptLimit}
        />
      </div>
      <div className="footer-action">
        <div className="footer-reward">
          <QuestRewards />
          <div className="total-participants lexend-body-xs1">
            <DisplayParticipants />
          </div>
        </div>
        {isResolving && (
          <Tippy
            content={
              <p className="lexend-body-xs1">{t('quests:reloadingQuest')}</p>
            }
          >
            <m.span
              onClick={unstuck}
              style={{ originX: 0.5, originY: 0.44 }}
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: 'linear',
                type: 'keyframes',
              }}
            >
              <Icon.reload size={16} className="reload-icon" color="#fff" />
            </m.span>
          </Tippy>
        )}

        {!isResolving && displayComplete && (
          <Button
            id={'complete-only-quest-button'}
            variant={BUTTON_VARIANT.RED}
            size={BUTTON_SIZE.LARGE}
            loading={mutateCompleteQuest.isLoading}
            disabled={disabled || mutateCompleteQuest.isLoading}
            onClick={tryComplete}
            className="final-action"
          >
            <ButtonLabel />
          </Button>
        )}
      </div>
    </>
  );
};

function ButtonLabel() {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const { t } = useTranslation(['quests']);
  const isCheckQuest = [
    COMPLETEONLY_QUEST_TEMPLATE_NAME.CHECK_RESOURCE,
    COMPLETEONLY_QUEST_TEMPLATE_NAME.CHECK_LEVEL,
    COMPLETEONLY_QUEST_TEMPLATE_NAME.COMMUNITY_REFERRAL,
  ].some((t) => t === quest?.template.name);
  const isClaim = quest?.template.name?.startsWith(
    COMPLETEONLY_QUEST_TEMPLATE_NAME.AUTO_COMPLETE,
  );
  const isSubmit = quest?.template.name.startsWith('basic.answer-submission');
  const isVerify = [
    COMPLETEONLY_QUEST_TEMPLATE_NAME.VISIT_LINK,
    COMPLETEONLY_QUEST_TEMPLATE_NAME.QUIZ_QUEST,
    COMPLETEONLY_QUEST_TEMPLATE_NAME.DISCORD_CHECKROLE,
  ].some((t) => t === quest?.template.name);

  const label = match({
    isAttemptDisabled:
      actionContext.reason === QuestDisabledReason.MAX_ATTEMPTS_REACHED,
    isSubmit,
    isCheckResources: isCheckQuest,
    isClaim,
    isCompleted: actionContext.reason === 'QUEST_COMPLETED',
    isVerify,
  })
    .with(
      {
        isAttemptDisabled: false,
        isSubmit: true,
      },
      () => t('buttonLabel.submit'),
    )
    .with(
      {
        isAttemptDisabled: false,
        isCheckResources: true,
      },
      () => t('buttonLabel.check'),
    )
    .with(
      {
        isAttemptDisabled: false,
        isVerify: true,
      },
      () => t('buttonLabel.verify'),
    )
    .with(
      {
        isClaim: true,
      },
      () => t('buttonLabel.claimRewards'),
    )
    .with(
      {
        isAttemptDisabled: true,
      },
      () => t('buttonLabel.maxAttemptsReached'),
    )
    .with(
      {
        isCompleted: true,
      },
      () => t('buttonLabel.questCompleted'),
    )
    .otherwise(() => t('buttonLabel.complete'));

  return <>{label}</>;
}
