import Link, { LinkProps } from 'next/link';
import { HTMLAttributes, ReactNode } from 'react';

import { Tippy } from '@/lib/tippy';

import classes from './SidebarButton.module.scss';

export function SidebarLink({
  children,
  isActive,
  kind,
  tooltip,
  href,
  ...props
}: LinkProps & {
  tooltip?: string | null;
  children: ReactNode;
  isActive?: boolean;
  kind: 'stroke' | 'fill';
}) {
  return (
    <Tippy
      touch={false}
      content={tooltip}
      disabled={!tooltip}
      className="lexend-body-s"
      placement="right"
    >
      <Link
        href={href}
        className={`${
          kind === 'fill' ? classes.button_svg_fill : classes.button_svg_stroke
        } ${isActive ? classes.active : ''}`}
        {...props}
      >
        {children}
      </Link>
    </Tippy>
  );
}

export function SidebarButton({
  children,
  isActive,
  kind,
  tooltip,
  ...props
}: HTMLAttributes<HTMLButtonElement> & {
  tooltip?: string | null;
  children: ReactNode;
  isActive?: boolean;
  kind: 'stroke' | 'fill';
}) {
  return (
    <Tippy
      touch={false}
      content={tooltip}
      disabled={!tooltip}
      className="lexend-body-s"
      placement="right"
    >
      <button
        className={`${
          kind === 'fill' ? classes.button_svg_fill : classes.button_svg_stroke
        } ${isActive ? classes.active : ''}`}
        {...props}
      >
        {children}
      </button>
    </Tippy>
  );
}
