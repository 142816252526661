import { QuestDetailsType } from '@xborglabs/ui-shared';

import { Stat } from './Stats';

export function QuestStats({
  metrics,
  defaultStatText,
  target,
}: {
  metrics: QuestDetailsType['metrics'];
  defaultStatText: string;
  target: QuestDetailsType['template']['args'];
}) {
  return (
    <div className="quest-stats__list expand">
      <Stat metrics={metrics} defaultStatText={defaultStatText} target={target}>
        {({ items }) => (
          <>
            {items.map(({ title, value }, index) => (
              <div key={index} className="quest-stat h-full">
                <div className="lexend-heading-h2">{value}</div>
                <div className="lexend-body-xs1">{title}</div>
              </div>
            ))}
          </>
        )}
      </Stat>
    </div>
  );
}
