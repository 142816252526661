import { useTranslation } from 'react-i18next';

import { GamerCard } from '@/modules/community/components/Slots/GamerCard/GamerCard';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_GamingCardForged.scss';

export function GamingCardForged({ communityId }: { communityId: string }) {
  const { t } = useTranslation(['community']);

  function handleCloseModal() {
    closeModal();
  }

  return (
    <div className="gaming-card-forged">
      <h1 className="gaming-card-forged__heading">
        {t('community:cardForged')}
      </h1>
      <GamerCard communityId={communityId} />
      <Button
        size={BUTTON_SIZE.LARGE}
        variant={BUTTON_VARIANT.RED}
        onClick={handleCloseModal}
      >
        {t('community:continue')}
      </Button>
    </div>
  );
}
