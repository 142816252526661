export function formatURL(input: string): string {
  // Check if the input starts with a "/"
  if (input.startsWith('/')) {
    // If it starts with "/", return it as is
    return input;
  } else {
    // Check if the input resembles a badly formatted URL
    const urlPattern = /^(?!(https?:\/\/)).*/; // Does not start with "http://" or "https://"
    const trimmedInput = input.trim().replace(/\/+$/, ''); // Remove trailing slashes and trim spaces

    if (urlPattern.test(trimmedInput)) {
      // If it's a badly formatted URL, add "https://" and remove duplicate slashes
      const formattedURL = `https://${trimmedInput.replace(/\/+/g, '/')}`;
      return formattedURL;
    } else {
      // If it's a properly formatted URL, return it as is
      return trimmedInput;
    }
  }
}
