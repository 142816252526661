import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { Trans, useTranslation } from 'next-i18next';

import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import classes from './UsernameClaimed.module.scss';
import { PopupContinueButton } from '../../PopupContinueButton';

export type UsernameClaimedProps = {
  redirect?: string;
};

export const UsernameClaimed = ({ redirect }: UsernameClaimedProps) => {
  const { t } = useTranslation(['auth', 'globals']);

  const { data: authState } = useUserProfile();
  const profile = authState?.profile;
  const handle = profile?.profile?.handle;

  return (
    <div className={classes.modal_wrapper}>
      <div
        className={`${classes.username_claimed_popup} flex column middle center text-center`}
      >
        <div className={`${classes.title} lexend-heading-h2`}>
          <Trans i18nKey="auth:claimusername.usernameClaimed" />
        </div>

        <div
          className={`${classes.claimed_username_container} flex middle center w-full`}
        >
          <div className={`${classes.claimed_username} expand`}>{handle}</div>
        </div>

        <div className={`${classes.footnote} lexend-heading-m`}>
          {t('auth:claimusername.ready')}
        </div>

        <div>
          <PopupContinueButton
            href={redirect ?? ''}
            onClick={(e) => {
              if (!redirect) {
                e.preventDefault();
              }
              closeModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};
