import Image from 'next/image';
import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import {
  DropzoneOptions,
  ErrorCode,
  FileRejection,
  useDropzone,
} from 'react-dropzone';

import { toast } from '@/lib/toastify';
import { Icon } from '@/modules/shared/components/Atom/Icon';

export interface FileWithPreview extends File {
  preview?: string;
}

export enum FileType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

interface FileSelectProps {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  children?: ReactNode;
  fileType: FileType;
  isDisabled?: boolean;
}

interface ThumbnailProps {
  files: FileWithPreview[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  index: number;
}

export const DropzoneOptionsMap: Record<FileType, DropzoneOptions> = {
  IMAGE: {
    maxFiles: 1,
    multiple: false,
    maxSize: 5 * 1024 * 1024,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  },
  TEXT: {},
};

const MimeType = {
  IMAGE: ['image/png', 'image/jpeg'],
};

const previewFiles = (files: File[]): FileWithPreview[] =>
  files.map((f) =>
    Object.assign(f, {
      preview: MimeType.IMAGE.includes(f.type)
        ? URL.createObjectURL(f)
        : f.name,
    }),
  );

const Thumbnail = ({ files, setFiles, index }: ThumbnailProps) => {
  const file = files[index];

  const deleteFile = (index: number) => {
    const _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
  };

  return (
    <div key={file.name} className="thumb-wrapper flex middle expand">
      {MimeType.IMAGE.includes(file.type) ? (
        <div className="flex middle center expand">
          <div className="thumb">
            <Image
              alt={file.name}
              src={file.preview || ''}
              onLoad={() => {
                URL.revokeObjectURL(file.preview || '');
              }}
              fill
            />
          </div>
          <div className="expand">
            <div className="image-name lexend-body-xs1">{file.name}</div>
          </div>
        </div>
      ) : (
        <p>{file.preview}</p>
      )}
      <div className="delete-file" onClick={() => deleteFile(index)}>
        <Icon.cross size={16} />
      </div>
    </div>
  );
};

export const FileSelect = (props: FileSelectProps) => {
  const { files, setFiles, fileType, children, isDisabled } = props;
  const dzOptions = {
    ...DropzoneOptionsMap[fileType],
    disabled: isDisabled,
  };
  const underFileLimit =
    dzOptions.maxFiles && files.length < dzOptions.maxFiles;

  const onDrop = (acceptedFiles: File[]) => {
    if (underFileLimit) {
      const pFiles = previewFiles(acceptedFiles);
      setFiles((prev) => [...prev, ...pFiles]);
    }
  };

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    rejections
      .flatMap(({ errors }) => errors)
      .forEach((error) => {
        switch (error.code) {
          case ErrorCode.FileInvalidType:
            toast.error('Invalid file type');
            break;
          case ErrorCode.FileTooLarge:
            toast.error('File too large');
            break;
          case ErrorCode.TooManyFiles:
            toast.error('Too many files');
            break;
        }
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    autoFocus: true,
    ...dzOptions,
  });

  return (
    <div className="file-select-wrapper">
      {files.length === 0 && (
        <div {...getRootProps()} className="file-select">
          <input {...getInputProps()} />
          {children}
        </div>
      )}

      <div className="file-select-files">
        {files?.length ? (
          <div className="thumb-container flex">
            {files.map((_, i) => (
              <Thumbnail
                key={`thumbnail-${i}`}
                files={files}
                setFiles={setFiles}
                index={i}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
