import React from 'react';

import classesPrix from '@/styles/animations/prix-clip-fix.module.scss';
import classes from '@/styles/animations/rotate.module.scss';
export type LoadingSpinnerProps = {
  size?: 'big' | 'small' | 'xsmall';
};

export const LoadingSpinner = ({ size = 'small' }: LoadingSpinnerProps) => {
  return (
    <div
      className={`loading-spinner ${size} ${classes['animate-rotate']} ${classesPrix['animate-prix-clip-fix']}`}
    ></div>
  );
};
