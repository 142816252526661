import { useTranslation } from 'next-i18next';

export function QuestLimit({
  attemptCount,
  attemptLimit,
}: {
  attemptCount: number;
  attemptLimit: number;
}) {
  const { t } = useTranslation(['quests']);

  if ((attemptLimit = -1)) return <div />;
  if (attemptCount + 1 > attemptLimit)
    return (
      <div className="lexend-body-xs1 font-bold quest-limit">
        {t('quests:attemptLimitReached')}
      </div>
    );

  return (
    <div className="lexend-body-xs1 font-bold quest-limit">
      {t('quests:attemptLimitDescription', {
        attemptLimit: attemptLimit,
        attemptCount: attemptCount + 1,
      })}
    </div>
  );
}
