import { Alert } from '@/modules/shared/components/Molecule/Alert';
import {
  GET_QUEST_TYPE,
  QuestDetailsType,
  getQuestType,
} from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

export function QuestResolutionNotice({ quest }: { quest?: QuestDetailsType }) {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const socials = actionContext.context.authState?.socials;
  const questTemplateName = quest?.template.name;
  const social = socials?.find((item) => {
    return questTemplateName?.startsWith(item.accountType.toLowerCase());
  });

  return (
    <>
      {match(questTemplateName)
        .when(
          (name) => getQuestType(name) === GET_QUEST_TYPE.GAMING,
          () => (
            <div className="quest-notice">
              {match(questTemplateName)
                .when(
                  (name) => name?.startsWith('dota'),
                  () => (
                    <Alert variant="info">
                      {t('quests:rankedStatsNotice')}
                    </Alert>
                  ),
                )
                .when(
                  (name) => ['tft', 'lol'].some((v) => name?.startsWith(v)),
                  () => (
                    <Alert variant="info">
                      {t('quests:rankedStatsNotice')}
                    </Alert>
                  ),
                )
                .otherwise(() => null)}
            </div>
          ),
        )
        .when(
          (name) =>
            ['youtube', 'twitter', 'twitch', 'discord'].some(
              (v) => name?.startsWith(v),
            ),
          () => {
            return (
              <div className="quest-notice">
                {new Date(social?.expiryDate ?? '') < new Date() ? (
                  <Alert variant="warning">
                    {t('quests:socialStatsOauthExpiryNotice')}
                  </Alert>
                ) : (
                  <Alert variant="info">{t('quests:socialStatsNotice')}</Alert>
                )}
              </div>
            );
          },
        )
        .otherwise(() => null)}
    </>
  );
}
