import { Logout, useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { createClientApi } from '@/modules/auth/axios';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import { handleRouting } from '@/modules/utils/routing';
import { GLOBAL_ROUTES } from '@/routes';
import './_Logout.scss';

export const LogoutPopup = () => {
  const { t } = useTranslation(['profile', 'globals']);
  const router = useRouter();
  const { data: authState, refetch } = useUserProfile();

  return (
    <Logout
      onSuccess={() => {
        if (!authState?.state) return;
        closeModal();
        handleRouting(router, authState.state);
        router.push(GLOBAL_ROUTES.LANDING).finally(refetch);
        createClientApi();
      }}
    >
      {({ onLogout, isLoading }) => (
        <div className="logout-popup ">
          <div className="modal-title">{t('profile:logout.title')}</div>

          <div className="flex middle actions">
            <Button
              variant={BUTTON_VARIANT.RED}
              onClick={() => closeModal()}
              className="cancel expand"
            >
              {t('globals:cancel')}
            </Button>
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              onClick={onLogout}
              loading={isLoading}
              className="logout expand"
            >
              {t('profile:logout.logout')}
            </Button>
          </div>
        </div>
      )}
    </Logout>
  );
};
