import { QuestDetailsType } from '@xborglabs/ui-shared';
import { useQuestResults } from '@xborglabs/ui-shared/dist/client';
import { useMemo } from 'react';

import { QuizOptions, QuizValues } from '@/modules/quests/components';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

export type PollResults = {
  [key: string]: number;
};

export const QuestPollResults = ({ data }: { data: QuestDetailsType }) => {
  const isPublicPoll = !data.template.args.isPrivate;
  const {
    data: results,
    error,
    isFetching,
  } = useQuestResults(isPublicPoll ? data.questId : undefined);
  const args: Record<string, any> = data?.template?.args ?? {};
  const options = (args.options as string[]) ?? ([] as string[]);

  const resultBreakdown = useMemo(() => {
    const res: PollResults = results?.results;
    if (!res) return null;

    const total = Object.values(res).reduce((a, b) => a + b, 0);
    let winner = null,
      winnerValue = 0;

    const values = Object.entries(res).reduce(
      (acc: QuizValues, [key, value]) => {
        const percentage = (value / total) * 100;
        if (value > winnerValue) [winner, winnerValue] = [key, value];
        acc[key] = { percentage, value };
        return acc;
      },
      {} as QuizValues,
    );

    return { values, winner } as any;
  }, [results]);

  return (
    <div>
      {isFetching && <LoadingSpinner />}
      {!!error && <Alert variant="warning">{error.toString()}</Alert>}

      {isPublicPoll && (
        <QuizOptions
          results={resultBreakdown}
          onChange={() => null}
          value={data.metrics?.text || ''}
          options={options}
        />
      )}
    </div>
  );
};
