import { InventorySlotItemType } from '@xborglabs/ui-shared';
import { EquipItem } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { P, match } from 'ts-pattern';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Tag } from '@/modules/shared/components/Atom/Tag';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import { InventoryActions } from '@/redux/inventory/actions';
import { AppDispatch } from '@/store';

import classes from './EquipInventoryItem.module.scss';

export type ShopDetailsPopupProps = {
  item: InventorySlotItemType;
  inventoryId: string;
  slotId: string;
  containerItemId: string;
  categoryTitle: string;
  isLocked: boolean;
  isActive: boolean;
};

export const EquipInventoryItemPopup = ({
  item,
  categoryTitle,
  slotId,
  isLocked,
  isActive,
}: ShopDetailsPopupProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation(['globals', 'errors', 'success']);
  const router = useRouter();
  const slug = router.query.slug;
  function onEquip(equipItem: (slotId: string, itemId: string | null) => void) {
    return function () {
      dispatch(InventoryActions.triggerChangeAvatar(slug as string));
      equipItem(slotId, isActive ? null : item.itemId);
    };
  }
  function onSuccess() {
    closeModal();
    toast.success(t('success:globalSuccess.item_allocated'));
  }
  function onError(error?: any) {
    if (error?.response?.data?.name) {
      toast.error(
        t('inventory:allocationFailed', {
          reason: t(`errors:globalErrors.${error.response?.data.name}`),
        }),
      );
      console.log('error');
      return;
    }
  }

  return (
    <EquipItem slug={slug as string} onSuccess={onSuccess} onError={onError}>
      {({ equipItem, isLoading }) => (
        <div className={classes.equip_popup}>
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            className={classes.close_button}
            onClick={() => closeModal()}
          >
            <Icon.cross size={24} />
          </Button>
          <Image
            src={item.mediaUrl}
            alt={item.name}
            width={491}
            height={491}
            className={`${classes.item_image} ${
              isLocked ? classes.image_container_locked : ''
            }`}
          />
          {isLocked && (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              className={classes.lock}
            >
              <Icon.lock size={16} />
            </Button>
          )}
          <div className={classes.equip_item_content}>
            <h3
              className={`${classes[`item_name_${item.rarity}`]} ${
                classes.item_name
              }`}
            >
              {item.name}
            </h3>
            <div className="flex between">
              <div className="tags flex flex-wrap gap-small">
                <Tag label={categoryTitle} slug={''} active disabled />
                <div className={item.rarity}>
                  <Tag label={item.rarity} slug={''} active disabled />
                </div>

                {isLocked && (
                  <Tag
                    label={''}
                    slug={''}
                    icon={<Icon.lock size={16} className="locked" />}
                    active
                    disabled
                  />
                )}
              </div>
            </div>

            {item.description && (
              <div className={classes.item_description}>{item.description}</div>
            )}
          </div>
          <Button
            variant={BUTTON_VARIANT.BLUE}
            size={BUTTON_SIZE.LARGE}
            loading={isLoading}
            disabled={isLocked}
            onClick={onEquip(equipItem)}
            className={classes.cta}
          >
            {match([isLocked, isActive])
              .with([true, P.select()], () => t('globals:locked'))
              .with([false, true], () => t('globals:unequip'))
              .with([false, false], () => t('globals:equip'))
              .exhaustive()}
          </Button>
        </div>
      )}
    </EquipItem>
  );
};
