import { Videos } from '@/assets/videos';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { GLOBAL_ROUTES } from '@/routes';

interface ErrorProps {
  statusCode: string | number;
  paragraphText: string;
  actionLabel: string;
  action?: () => void;
}

export function PageError({
  statusCode,
  paragraphText,
  actionLabel,
  action,
}: ErrorProps) {
  const actionProps = {
    onClick: action ? action : undefined,
    href: action ? undefined : GLOBAL_ROUTES.LANDING,
  };
  return (
    <div className="page-error">
      <div className="status-code-section flex column center">
        <video className="video" src={Videos.LIGHTNING} autoPlay loop muted />

        <div className="status-code">
          <h1>{statusCode}</h1>
        </div>
      </div>

      <div className="redirect-section flex column center">
        <p className="lexend-body-xl">{paragraphText}</p>

        <Button
          size={BUTTON_SIZE.LARGE}
          variant={BUTTON_VARIANT.RED}
          className="redirect-btn"
          {...actionProps}
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
}
