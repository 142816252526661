import { UnlinkSocial } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { PopupClose } from '@/modules/shared/components/Template/Popups';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_UnlinkAccount.scss';

export type UnlinkAccountPopupProps = {
  socialId: string;
};

export const UnlinkAccountPopup = ({ socialId }: UnlinkAccountPopupProps) => {
  const { t } = useTranslation(['profile', 'globals', 'success', 'errors']);

  return (
    <UnlinkSocial
      onError={() => {
        toast.error(t('errors:globalErrors.account_unlink_rejected'));
      }}
      onSuccess={() => {
        toast.success(t('success:globalSuccess.unlink_success'));
      }}
    >
      {({ onUnlink, isLoading }) => (
        <div className="unlink-account-popup ">
          <div className="flex between">
            <h3 className="popup-title lexend-heading">
              {t('profile:unlinkAccount')}
            </h3>

            <PopupClose />
          </div>

          <p className="lexend-body-s">{t('profile:unlinkAccountDesc')}</p>

          <div className="flex end middle actions">
            <Button
              variant={BUTTON_VARIANT.RED}
              size={BUTTON_SIZE.LARGE}
              className="cancel"
              onClick={() => closeModal()}
            >
              {t('globals:cancel')}
            </Button>
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              onClick={(e) => {
                e.preventDefault();
                onUnlink(socialId);
                closeModal();
              }}
              size={BUTTON_SIZE.LARGE}
              className="unlink"
              loading={isLoading}
            >
              {t('profile:unlink')}
            </Button>
          </div>
        </div>
      )}
    </UnlinkSocial>
  );
};
