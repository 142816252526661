import { NextRouter } from 'next/router';

import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { ModalActions } from '@/redux/modals/actions';
import {
  MODAL_STATE,
  MODAL_TYPE,
  ModalDataType,
  ModalOptions,
} from '@/redux/modals/types';
import store from '@/store';

export async function openModal(
  name: MODAL,
  data?: any,
  options: Partial<ModalOptions> = {
    login: false,
    fullScreen: false,
  },
) {
  function open() {
    const payload: ModalDataType = {
      content: name,
      type: MODAL_TYPE.POPUP,
      data,
      options,
    };

    setTimeout(() => {
      store.dispatch(
        ModalActions.setModal({
          data: payload,
          state: MODAL_STATE.OPEN,
        }),
      );
    }, 100);
  }

  const isClosed = await closeModal();
  if (isClosed) {
    open();
  }
}

export function closeModal(router?: NextRouter): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // if no modal, resolve early.
    if (store.getState().modals.state === MODAL_STATE.UNINITIALIZED)
      resolve(true);

    // trigger close function early for animation to took place.
    store.dispatch(
      ModalActions.close({
        data: null,
        state: MODAL_STATE.UNINITIALIZED,
      }),
    );

    // setTimeout only responsible for cleanups.
    setTimeout(() => {
      if (router) {
        // Remove qid from the url
        delete router.query.qid;
        router.replace(
          {
            query: router.query,
          },
          undefined,
          { shallow: true },
        );
      }
      resolve(true);
    }, 300);
  });
}
