import { createReducer } from '@reduxjs/toolkit';

import { MODAL_STATE, ModalsActionTypes, ModalsInterface } from './types';

interface Action<T, P> {
  readonly type: T;
  readonly payload?: P;
}

const initialState: ModalsInterface = {
  state: MODAL_STATE.UNINITIALIZED,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    ModalsActionTypes.OPEN_MODAL,
    (state, actions: Action<ModalsActionTypes, ModalsInterface>) =>
      actions.payload,
  );

  builder.addCase(
    ModalsActionTypes.CLOSE_MODAL,
    (state, actions: Action<ModalsActionTypes, ModalsInterface>) => ({
      ...state,
      ...actions.payload,
    }),
  );
});

export { reducer as ModalsReducer };
