import { humanize } from '@xborglabs/ui-shared';
import {
  useQuestActionContext,
  useResource,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Images } from '@/assets/imgs';
import { InventoryItemReward } from '@/modules/quests/components';
import { Icon } from '@/modules/shared/components/Atom/Icon';

export const QuestRewards = () => {
  const { t } = useTranslation(['quests', 'globals']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const { data: resourceType } = useResource(
    quest?.reward?.args?.resourceTypeId,
  );

  if (!quest) return null;

  let totalReward = Number(quest.reward?.args?.amount);

  if (quest.reward.name === 'quest_progress') {
    totalReward = quest.resourceEmission ?? 0;
  }

  const rewardName = resourceType ? resourceType.name : t('globals:xp');

  const isDiscord = ['assign_discord_role', 'assign_role'].includes(
    quest.reward.name,
  );

  const isResource = ['credit_resource', 'quest_progress'].includes(
    quest.reward.name,
  );

  return (
    <div className="quest-rewards-container expand">
      <div className="quest-rewards flex middle w-full">
        {isDiscord ? (
          <div className="quest-reward expand flex middle center gap-small">
            <Icon.discord size={48} />
            <div className="lexend-body-xs1">
              {t('quests:getDiscordRole')}{' '}
              {quest.reward.args.roleName ?? quest.reward.args.role}
            </div>
          </div>
        ) : null}
        {isResource ? (
          <div className="quest-reward lexend-body-m expand flex middle center gap-small">
            <Image
              src={
                resourceType?.images?.resourceImageUrl ??
                Images.points.points_blue
              }
              alt=""
              width={28}
              height={48}
              className="object-fit-contain"
            />
            <div className="reward-name text-center font-bold">
              {humanize(totalReward)} {rewardName}
            </div>
          </div>
        ) : null}
        {quest.reward.name === 'give_assets' ? (
          <InventoryItemReward quest={quest} />
        ) : null}
      </div>
    </div>
  );
};
