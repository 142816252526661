import { USER_SOCIAL_TYPE, WowCharacter } from '@xborglabs/ui-shared';
import {
  useMutateSocials,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import { TagProps } from '@xborglabs/ui-shared/lib/features/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/toastify';
import { DropdownSelect } from '@/modules/shared/components/Molecule/Forms/Dropdown';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

import { useWorldOfWarcraftCharacters } from './query';
import { WowCharacterInfo } from './WowCharacterInfo';

export function WowCharacterSelection() {
  const { t } = useTranslation(['profile']);
  const { data: characters } = useWorldOfWarcraftCharacters();
  const { data: authState } = useUserProfile();
  const mutateUpdateSocials = useMutateSocials();
  const battlenet = authState?.socials?.find(
    (social) => social.accountType === USER_SOCIAL_TYPE.BATTLENET,
  );
  const options = useMemo(() => {
    return (
      characters?.map((char) => {
        return {
          slug: char.character,
          label: char.character,
          active: char.character === battlenet?.extraArgs?.character,
        };
      }) ?? []
    );
  }, [battlenet?.extraArgs?.character, characters]);
  if (!characters) return null;

  return (
    <div className="flex between middle">
      <DropdownSelect
        onChange={(value?: TagProps) => {
          const found = characters?.find(
            (char) => char.character === value?.slug,
          );
          if (!found) {
            toast.error(t('profile:battlenet.characterNotFound'));
            return;
          }

          mutateUpdateSocials.mutate({
            socialId: battlenet?.socialId,
            extraArgs: found,
          });
        }}
        list={options}
      >
        {mutateUpdateSocials.isLoading ? (
          <LoadingSpinner size={'small'} />
        ) : battlenet?.extraArgs ? (
          <WowCharacterInfo
            character={battlenet?.extraArgs as unknown as WowCharacter}
          />
        ) : (
          t('profile:battlenet.characterSelect')
        )}
      </DropdownSelect>
    </div>
  );
}
