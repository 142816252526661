import { QuestDetailsType } from '@xborglabs/ui-shared';
import { useInventoryItem } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';

import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

export function InventoryItemReward({
  quest,
  showLabel = true,
}: {
  showLabel?: boolean;
  quest: QuestDetailsType;
}) {
  const { data: asset, isLoading } = useInventoryItem({
    communityKey: quest?.containerId,
    itemId: quest?.reward.args.itemId,
  });

  return (
    <div className="quest-reward expand flex column middle center gap-small">
      {asset && (
        <Image src={asset.mediaUrl} width="80" height="80" alt={asset.name} />
      )}

      {isLoading && (
        <div className="loading flex middle center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && !asset && <div className="no-reward"></div>}

      {showLabel && (
        <span className="reward-name font-bold inventory-item-name">
          {asset?.name ?? '-'}
        </span>
      )}
    </div>
  );
}
