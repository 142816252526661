/**
 * Some mutable state for a queue of requests. If we're already in the middle of
 * requesting a new token, we want to hold other requests in a queue, then make
 * them with the new token once we get it.
 */
export class RequestQueue {
  isFetchingToken: boolean;
  pendingRequests: any[];
  constructor() {
    this.isFetchingToken = false;
    this.pendingRequests = [];
  }

  enqueue(callback: any) {
    this.pendingRequests.push(callback);
  }

  dequeueAll(...args: any[]) {
    this.pendingRequests.forEach((cb) => cb(...args));
    this.clearQueue();
  }

  clearQueue = () => {
    this.pendingRequests = [];
    this.isFetchingToken = false;
  };
}
