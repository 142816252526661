export const LOL_REGIONS = [
  {
    label: 'Brazil',
    short: 'BR',
    slug: 'BR1',
  },
  {
    label: 'Europe Nordic & East',
    short: 'EUNE',
    slug: 'EUN1',
  },
  {
    label: 'Europe West',
    short: 'EUW',
    slug: 'EUW1',
  },
  {
    label: 'Latin America North',
    short: 'LAN',
    slug: 'LA1',
  },
  {
    label: 'Latin America South',
    short: 'LAS',
    slug: 'LA2',
  },
  {
    label: 'North America',
    short: 'NA',
    slug: 'NA1',
  },
  {
    label: 'Oceania',
    slug: 'OCE',
  },
  {
    label: 'Oceania',
    short: 'OCE',
    slug: 'OC1',
  },
  {
    label: 'Russia',
    short: 'RU',
    slug: 'RU1',
  },
  {
    label: 'Turkey',
    short: 'TR',
    slug: 'TR1',
  },
  {
    label: 'Japan',
    short: 'JP',
    slug: 'JP1',
  },
  {
    label: 'Republic of Korea',
    short: 'KR',
    slug: 'KR',
  },
  {
    label: 'The Philippines',
    short: 'PH',
    slug: 'PH2',
  },
  {
    label: 'Singapore, Malaysia, & Indonesia',
    short: 'SG',
    slug: 'SG2',
  },
  {
    label: 'Taiwan, Hong Kong, and Macao',
    short: 'TW',
    slug: 'TW2',
  },
  {
    label: 'Thailand',
    short: 'TH',
    slug: 'TH2',
  },
  {
    label: 'Vietnam',
    short: 'VN',
    slug: 'VN2',
  },
];
