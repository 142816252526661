import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

import classes from './Card.module.scss';
type GlassCardProps = HTMLAttributes<HTMLDivElement> & {
  roundness?: 'none' | 'regular';
};
export function GlassCard(props: GlassCardProps) {
  const { className, roundness = 'regular', ...rest } = props;
  return (
    <div
      className={`${classes['glass-card']} ${
        classes[`roundness_${roundness}`]
      } ${className}`}
      {...rest}
    />
  );
}

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  variant?: 'component' | 'overlay' | 'outlined';
  spacing?: 'regular' | 'narrow' | 'tight' | 'ample' | 'none';
  roundness?: 'none' | 'regular' | 'tight';
};

export const Card = forwardRef(
  (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      variant = 'component',
      spacing = 'regular',
      roundness = 'regular',
      ...rest
    } = props;
    return (
      <div
        ref={ref}
        className={`${classes[`variant_${variant}`]} ${
          classes[`spacing_${spacing}`]
        } ${classes[`roundness_${roundness}`]} ${className || ''}`}
        {...rest}
      />
    );
  },
);

Card.displayName = 'Card';
