import { MODAL } from '@/modules/shared/components/Template/Popups/types';

export enum ModalsActionTypes {
  OPEN_MODAL = 'modals/OPEN_MODAL',
  CLOSE_MODAL = 'modals/CLOSE_MODAL',
}

export interface ModalsInterface {
  data?: ModalDataType;
  state: MODAL_STATE;
}

export enum MODAL_STATE {
  UNINITIALIZED = 'UNINITIALIZED',
  OPEN = 'OPEN',
}

export type ModalOptions = {
  fullScreen: boolean;
  closable: boolean;
  login: boolean;
};
export type ModalDataType = {
  content: MODAL;
  type: MODAL_TYPE;
  data?: any;
  options: Partial<ModalOptions>;
} | null;

export enum MODAL_TYPE {
  MODAL = 'MODAL',
  POPUP = 'POPUP',
}
