import { useEffect, useState } from 'react';

import { Videos } from '@/assets/videos';
import { getServerSidei18nProps } from '@/lib/serverSideProps/getServerSidei18nProps';
import { Layout } from '@/modules/shared/components/Template/Containers';
import './_maintenance.scss';
export default function MaintenancePage() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layout overrideBorderState="none" overrideFeature={true}>
      <div className="maintainance-page">
        <main className="flex column">
          {isClient && (
            <video
              className="video-bg"
              src={Videos.RAIN}
              autoPlay
              loop
              muted
            ></video>
          )}

          <div className="epic-adventure">
            <span className="flex">An epic adventure awaits</span>
            <span className="flex">An epic adventure awaits</span>
            <span className="flex">An epic adventure awaits</span>
          </div>

          <div className="coming-soon">We will be back soon</div>

          <svg
            width="48"
            height="48"
            viewBox="0 0 21 22"
            className="page-brand"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.8708 0V6.22957L14.9679 10.6261H5.90285L0 6.22957V0L10.4354 7.68348L20.8708 0Z" />
            <path d="M20.8708 21.9991V15.7661L14.9679 11.373H5.90285L0 15.7661V21.9991L10.4354 14.3157L20.8708 21.9991Z" />
          </svg>
        </main>
      </div>
    </Layout>
  );
}

export const getServerSideProps = getServerSidei18nProps;
