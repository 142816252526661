import Link from 'next/link';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';

import { Tippy } from '@/lib/tippy';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

export type ButtonProps = {
  size?: BUTTON_SIZE;
  variant?: BUTTON_VARIANT;
  href?: string;
  target?: string;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  style?: CSSProperties;
  id?: string;
};

export enum BUTTON_VARIANT {
  RED = 'red',
  BLUE = 'blue',
  TRANSPARENT = 'transparent',
  GHOST = 'ghost',
  OUTLINE = 'outline',
  UNDERLINE = 'underline',
  SECONDARY = 'secondary',
}

export enum BUTTON_SIZE {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const Button = ({
  className = '',
  size = BUTTON_SIZE.MEDIUM,
  variant = BUTTON_VARIANT.BLUE,
  href,
  disabled,
  loading,
  children,
  tooltip,
  ...props
}: ButtonProps) => {
  if (!href) {
    const buttonRender = (
      <button
        type="button"
        className={`btn btn-${variant} flex middle center gap-small ${size} ${className}${
          disabled ? ' disabled' : ''
        }
        ${loading ? ' loading' : ''}`}
        {...props}
      >
        {loading ? <LoadingSpinner /> : children}
      </button>
    );
    if (tooltip) {
      <Tippy content={tooltip}>{buttonRender}</Tippy>;
    }
    return buttonRender;
  }

  const linkRender = (
    <Link
      href={href}
      className={`btn btn-${variant} flex middle center gap-small ${size} ${className}${
        disabled ? ' disabled' : ''
      }
        ${loading ? ' loading' : ''}`}
      {...props}
    >
      {loading && <LoadingSpinner />}
      {children}
    </Link>
  );

  if (tooltip) {
    <Tippy content={tooltip}>{linkRender}</Tippy>;
  }
  return linkRender;
};

Button.variant = BUTTON_VARIANT;
Button.size = BUTTON_SIZE;
