import Image from 'next/image';
import Link from 'next/link';

import { Images } from '@/assets/imgs';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons/Button';
import { GLOBAL_ROUTES } from '@/routes';

import classes from './Brand.module.scss';

export const Brand = ({ size = 32 }: { size?: number }) => {
  return (
    <div className={classes['site-brand-container']}>
      <Link href={GLOBAL_ROUTES.LANDING} className={classes['site-brand']}>
        <Image
          src={Images.logo}
          priority
          alt="logo"
          width={size}
          height={size}
        />
      </Link>
      <Button
        variant={BUTTON_VARIANT.TRANSPARENT}
        size={BUTTON_SIZE.XSMALL}
        disabled
        className={classes['site-brand__beta']}
      >
        Beta
      </Button>
    </div>
  );
};
