export enum SiteActionTypes {
  LOAD_SITE = 'site/LOAD_SITE',
  TOGGLE_NAVIGATION = 'site/TOGGLE_NAVIGATION',
  TOGGLE_SOUND = 'site/TOGGLE_SOUND',
  TOGGLE_SOUND_SOURCE = 'site/TOGGLE_SOUND_SOURCE',
  SET_BACK_URL = 'site/SET_BACK_URL',
  TOGGLE_AVATAR_VISIBILITY = 'site/TOGGLE_AVATAR_VISIBILITY',
  TOGGLE_SIDEBAR = 'site/TOGGLE_SIDEBAR',
  SET_SITE_TITLE = 'site/SET_SITE_TITLE',
  SET_NAVBAR_CTA = 'site/SET_NAVBAR_CTA',
}

export interface SiteInterface {
  navigation: boolean;
  backUrl?: string;
  version: 'beta' | 'stable';
  sidebar: boolean;
  appName: string;
  navbar_cta: 'None' | 'Login' | 'JoinCommunity';
}
