import { QuestDetailsType } from '@xborglabs/ui-shared';
import { useTranslation } from 'react-i18next';

import { Videos } from '@/assets/videos';
import { Icon } from '@/modules/shared/components/Atom/Icon';

import { PopupContinueButton } from '../../../PopupContinueButton';

export const DiscordReward = ({
  reward,
  onClose,
}: {
  reward: QuestDetailsType['reward'];
  onClose: () => void;
}) => {
  const { t } = useTranslation(['globals', 'quests']);

  return (
    <>
      <div className="reward-container flex column middle center">
        <div className="reward-image flex column middle center">
          <Icon.discord color={'#4E32FF'} size={120} />

          <video
            src={Videos.PARTICLES}
            loop
            muted
            autoPlay
            className="particles"
          />

          <div className="lexend-heading-h2 font-bold reward">
            {reward.args.roleName ?? reward.args.role} Role
          </div>
        </div>
      </div>

      <PopupContinueButton onClick={onClose} />
    </>
  );
};
