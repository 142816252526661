import { shortenId } from '@xborglabs/ui-shared';

export enum VISITOR_ROUTES {
  SIGNUP = '/signup',
}

export enum EXTERNAL_ROUTES {
  STEAM_PUBLIC_PROFILE_DESC = 'https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276',
  STEAM_PUBLIC_WISHLIST_DESC = 'https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276',
  GAMERBASE_TWITTER = 'https://twitter.com/GamerBaseHQ',
  XBORG_WEBSITE = 'https://www.xborg.com/',
  GAMERBASE_WEBSITE = 'http://gamerbase.gg/',
}

export const GLOBAL_ROUTES = {
  LANDING: '/',
  ONBOARDING: '/?intent=claim_username',
  AIRDROP: '/airdrop',
  REFER: '/r',
  REFER_PATH: '/r/[referrer]',
  FAQ: '/faq',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  NOT_FOUND_ERROR: '/404',
  SERVER_ERROR: '/500',

  EXPLORE: '/explore',
  CHANGELOG: '/changelog',
  PROFILE: '/profile',

  // GAMING
  GAMEVIEW: '/g/',
  GAMEVIEW_PATH: '/g/[gameid]',
  GAMEDETAILS: '/gamedetails/',
  GAMEDETAILS_PATH: '/gamedetails/[gameid]',

  // COMMUNITY
  COMMUNITY: '/c/',
  COMMUNITY_PATH: '/c/[slug]',
  COMMUNITY_SLUG: (slug: string) => `/c/${slug}/`,
  COMMUNITY_QUESTS: (slug: string) => `/c/${slug}/quests`,
  COMMUNITY_EVENTS: (slug: string) => `/c/${slug}/events`,
  COMMUNITY_EVENT_DETAILS: (slug: string, id: string) =>
    `/c/${slug}/events/${shortenId(id)}`,
  COMMUNITY_INVENTORY: (slug: string) => `/c/${slug}/inventory`,
  COMMUNITY_INVENTORY_ALL_ITEMS: (slug: string, slot?: string) =>
    `/c/${slug}/inventory/all-items${slot ? `?slot=${slot}` : ''}`,
  COMMUNITY_INVENTORY_SLOTS: (slug: string) => `/c/${slug}/inventory/slots`,

  COMMUNITY_GAMER_CARDS: (slug: string) => `/c/${slug}/cards`,
  COMMUNITY_LEADERBOARD: (slug: string) => `/c/${slug}/leaderboard`,
};

export const PRIVATE_ROUTES = {
  PROFILE: (
    tab?:
      | 'manage-account'
      | 'quests-history'
      | 'referrals'
      | 'account-settings',
  ) => (!!tab ? `/profile?t=${tab}` : '/profile'),
  CLAIM: '/claim',
  REFERRAL: '/referral',
  LEADERBOARD: '/leaderboard',
} as const;

export enum API_ROUTES {
  DISCORD_CONNECT = '/api/user/socials/link/discord/start',
  DISCORD_LOGIN = '/api/user/login/link/discord/start',
  GOOGLE_ZKLOGIN = '/api/user/zklogin/link/google/start',
  TWITCH_ZKLOGIN = '/api/user/zklogin/link/twitch/start',

  DISCORD_AUTOMATION_CONNECT = '/api/community/automation/link/discord/start',

  TWITTER_CONNECT = '/api/user/socials/link/twitter/start',
  TWITTER_LOGIN = '/api/user/login/link/twitter/start',

  RIOT_CONNECT = '/api/user/games/link/riot/start',
  STEAM_CONNECT = '/api/user/games/link/steam/start',
  BATTLENET_CONNECT = '/api/user/games/link/battlenet/start',
  TWITCH_CONNECT = '/api/user/socials/link/twitch/start',
  YOUTUBE_CONNECT = '/api/user/socials/link/youtube/start',
  EPIC_CONNECT = '/api/user/games/link/epic/start',
  APEIRON_CONNECT = '/api/user/games/link/apeiron/start',
}
