import { Images } from '@/assets/imgs';

export const getQuestIcon = (questTemplateName: string) => {
  const [groupName, templateName] = questTemplateName.split('.');

  if (Images.questIcon.hasOwnProperty(groupName)) {
    return Images.questIcon[groupName as keyof typeof Images.questIcon];
  }
  if (Images.questIcon.hasOwnProperty(templateName)) {
    return Images.questIcon[templateName as keyof typeof Images.questIcon];
  }
  return '';
};
