import { useQuery } from '@tanstack/react-query';
import { ApiBase, getCommunity } from '@xborglabs/ui-shared';
import find from 'lodash/find';

export const SUPPORTED_GAMING_CARDS = [
  'bushi',
  'gaming-card',
  'shrapnel',
] as const;
export function useGamerCard({ communityKey }: { communityKey?: string }) {
  const api = ApiBase.getInstance().getApi();
  return useQuery({
    queryKey: ['COMMUNITY_GAMER_CARD', communityKey],
    enabled: !!communityKey,
    queryFn: async () => {
      const community = await getCommunity(api, communityKey!);
      const found = find(SUPPORTED_GAMING_CARDS, (c) => c === community.slug);
      if (!found) return Promise.reject('Community not supported');

      return {
        bg: `/images/gaming-cards/${found}/bg.png`,
        fg: `/images/gaming-cards/${found}/fg.png`,
        border: `/images/gaming-cards/${found}/border.png`,
      };
    },
  });
}
