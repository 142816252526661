import { createReducer } from '@reduxjs/toolkit';

import { SiteActionTypes, SiteInterface } from './types';
import { GlobalState } from '../../reducer';

interface Action<T, P> {
  readonly type: T;
  readonly payload?: P;
}

const initialState: SiteInterface = {
  navigation: true,
  version: 'beta',
  sidebar: false,
  appName: 'Gamerbase',
  navbar_cta: 'None',
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    SiteActionTypes.TOGGLE_NAVIGATION,
    (state, actions: Action<SiteActionTypes, any>) => {
      state.navigation = actions.payload;
    },
  );

  builder.addCase(
    SiteActionTypes.SET_BACK_URL,
    (state, actions: Action<SiteActionTypes, any>) => {
      state.backUrl = actions.payload;
    },
  );

  builder.addCase(
    SiteActionTypes.TOGGLE_SIDEBAR,
    (state, actions: Action<SiteActionTypes, any>) => {
      state.sidebar = actions.payload;
    },
  );

  builder.addCase(
    SiteActionTypes.SET_SITE_TITLE,
    (state, actions: Action<SiteActionTypes, any>) => {
      if (!actions.payload) {
        state.appName = 'Gamerbase';
      }
      state.appName = actions.payload;
    },
  );

  builder.addCase(
    SiteActionTypes.SET_NAVBAR_CTA,
    (state, actions: Action<SiteActionTypes, SiteInterface['navbar_cta']>) => {
      state.navbar_cta =
        typeof actions.payload === 'undefined' ? 'None' : actions.payload;
    },
  );
});

export const selectSite = (state: GlobalState) => state.site;

export { reducer as SiteReducer };
