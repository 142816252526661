import { SingleCommunityType, USER_AUTH_STATE } from '@xborglabs/ui-shared';
import {
  CommunityDetail,
  JoinCommunity,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { match } from 'ts-pattern';

import { Images } from '@/assets/imgs';
import { toast } from '@/lib/toastify';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import {
  closeModal,
  openModal,
} from '@/modules/shared/components/Template/Popups/utils/modal';
import './_CommunityReferral.scss';
export function CommunityReferral({
  slug,
  referrer,
}: {
  referrer: string;
  slug: string;
}) {
  const { data: authState } = useUserProfile();

  if (authState?.isLoggedIn && authState?.state !== USER_AUTH_STATE.POST_CLAIM)
    return null;

  return (
    <div className="community-referral flex column gap-normal">
      <CommunityDetail communityKey={slug}>
        {({ community, isLoading }) => (
          <CommunityReferralContainer
            isLoggedIn={Boolean(authState?.isLoggedIn)}
            referrer={referrer}
            community={community}
            isLoading={isLoading}
          />
        )}
      </CommunityDetail>
    </div>
  );
}

function LoginButton() {
  const { t } = useTranslation(['globals']);
  return (
    <Button
      variant={BUTTON_VARIANT.TRANSPARENT}
      onClick={() => openModal(MODAL.LOGIN, undefined, { login: true })}
    >
      {t('globals:login')}
    </Button>
  );
}

function CommunityReferralContainer({
  referrer,
  community,
  isLoggedIn,
  isLoading,
}: {
  referrer: string;
  community?: SingleCommunityType;
  isLoggedIn: boolean;
  isLoading: boolean;
}) {
  const { t } = useTranslation(['community']);
  const router = useRouter();
  function removeURL() {
    router.replace({
      pathname: `${router.basePath}/c/${community?.slug}`,
    });
  }

  function handleSuccess() {
    closeModal();
    toast.success(
      t('community:joinedCommunity.title', {
        community: community?.name,
      }),
    );
    removeURL();
  }
  // TODO: Update with loading skeleton
  if (isLoading || !community) return <>Loading...</>;

  return (
    <>
      <Button
        variant={BUTTON_VARIANT.TRANSPARENT}
        className="community-referral__close"
        onClick={(e) => {
          e.preventDefault();
          closeModal(router);
          removeURL();
        }}
      >
        <Icon.cross size={16} />
      </Button>
      <div className="community-referral__logo-container">
        <Image
          src={community.content?.images?.logoImageUrl ?? Images.logo}
          width={120}
          height={120}
          alt={community.name}
        />
      </div>
      <h2 className="community-referral__title">
        {t('community:youAreInvitedTo', { community: community.name })}
      </h2>
      {match(isLoggedIn)
        .with(false, () => <LoginButton />)
        .otherwise(() => (
          <JoinCommunity onSuccess={handleSuccess}>
            {({ onJoin, isLoading }) => (
              <Button
                variant={BUTTON_VARIANT.TRANSPARENT}
                className="w-full"
                disabled={community.isMember}
                loading={isLoading}
                onClick={() => {
                  onJoin(community.communityId, {
                    referrerHandle: referrer,
                  });
                }}
              >
                {community.isMember
                  ? t('community:youAlreadyJoined')
                  : t('community:acceptInvitation')}
              </Button>
            )}
          </JoinCommunity>
        ))}
    </>
  );
}
