import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { toast } from '@/lib/toastify';

export const RouteErrorHandler: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (router.query.error_name) {
      toast(t(`errors:globalErrors.${router.query.error_name}`));
      delete router.query.error;
      delete router.query.error_code;
      delete router.query.error_name;
      delete router.query.intent;
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
          },
        },
        undefined,
        { shallow: true },
      );
    }
  }, [router.query]);

  return <></>;
};
