import { UserProfileSocialType } from '@xborglabs/ui-shared';
import { UnlinkSocial } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { API_ROUTES } from '@/routes';

export type LinkTwitchProps = {
  twitch?: UserProfileSocialType;
};

export function LinkTwitch({ twitch }: LinkTwitchProps) {
  useIntentToast();
  const { t } = useTranslation(['quests', 'errors', 'success']);
  const redirectTo = `?redirectTo=${encodeURIComponent(
    window.location.pathname + window.location.search + '&intent=twitch',
  )}`;

  return (
    <div
      className={`quest-requirement flex between middle${
        twitch ? ' fulfilled' : ''
      }`}
    >
      <div className="requirement-details expand">
        <h4 className="lexend-heading-l requirement-title">
          {t('quests:twitchLink')}
        </h4>
      </div>

      <div className="requirement-action">
        {twitch ? (
          <Tippy
            className="lexend-body-xs1"
            content={t('quests:unlinkAccount')}
          >
            <div>
              <UnlinkSocial
                onError={() => {
                  toast.error(t('errors:globalErrors.account_unlink_rejected'));
                }}
                onSuccess={() => {
                  toast.success(t('success:globalSuccess.unlink_success'));
                }}
              >
                {({ onUnlink, isLoading }) => (
                  <Button
                    variant={BUTTON_VARIANT.TRANSPARENT}
                    onClick={() => onUnlink(twitch.socialId)}
                    disabled={isLoading}
                  >
                    {twitch.handle
                      ? `@${twitch.handle}`
                      : t('quests:accountLinked')}
                  </Button>
                )}
              </UnlinkSocial>
            </div>
          </Tippy>
        ) : (
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            href={API_ROUTES.TWITCH_CONNECT + redirectTo}
            className="lexend-heading-m action btn btn-transparent flex middle center "
          >
            {t('missions.linkAccount')}
          </Button>
        )}
      </div>
    </div>
  );
}
