'use client';
import dynamic from 'next/dynamic';

export function toast(message: string) {
  showNotify(message, 'info');
}

toast.error = function (message: string) {
  showNotify(message, 'error');
};
toast.success = function (message: string) {
  showNotify(message, 'success');
};

export const ToastContainer = dynamic(
  () => import('react-toastify').then((m) => m.ToastContainer),
  { ssr: false },
);

function showNotify(message: string, type: 'info' | 'error' | 'success') {
  import('react-toastify').then((m) => m.toast[type](message));
}
