import { Logout, useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { AnimatePresence, m } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Dispatch, forwardRef, useReducer } from 'react';

import { toast } from '@/lib/toastify';
import { useOutsideClick } from '@/modules/shared/hooks/useOutsideClick';
import { SiteActions } from '@/redux/site/actions';
import { GLOBAL_ROUTES, PRIVATE_ROUTES } from '@/routes';
import { useAppDispatch } from '@/store';

import classes from './UserMenu.module.scss';
import { EXTERNAL_ROUTES } from '../../../../../routes';
import { Icon } from '../../Atom/Icon';
import {
  SidebarButton,
  SidebarLink,
} from '../../Molecule/SidebarButton/SidebarButton';

type State = {
  menu: boolean;
  settings: boolean;
};

type Action = { type: 'toggleMenu' } | { type: 'toggleSettings' };

const initialState: State = { menu: false, settings: false };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'toggleMenu':
      return {
        menu: !state.menu,
        settings: false,
      };
    case 'toggleSettings':
      return {
        menu: false,
        settings: !state.settings,
      };

    default:
      return state;
  }
};

const handleOutsideClick =
  (dispatch: Dispatch<Action>, action: Action) => (e: MouseEvent) => {
    const elButton = e
      .composedPath()
      .find((el) =>
        ['SidebarSettingsButton', 'SidebarButtonMore'].includes(
          (el as HTMLElement)?.id,
        ),
      );
    if (typeof elButton === 'undefined') {
      dispatch(action);
    }
  };

export function UserMenu() {
  const router = useRouter();
  const { t } = useTranslation(['sidebar']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: authState } = useUserProfile();

  const menuMoreRef = useOutsideClick<HTMLDivElement>(
    handleOutsideClick(dispatch, { type: 'toggleMenu' }),
  );
  const settingsRef = useOutsideClick<HTMLDivElement>(
    handleOutsideClick(dispatch, { type: 'toggleSettings' }),
  );

  const profileActive = router.route === PRIVATE_ROUTES.PROFILE();
  // if (!authState?.isLoggedIn) return null;

  return (
    <>
      <div className={classes.user_area} id="UserMenu">
        <SidebarButton
          kind="fill"
          tooltip={t('sidebar:more')}
          onClick={() => dispatch({ type: 'toggleMenu' })}
          id="SidebarButtonMore"
        >
          <Icon.dots size={20} />
        </SidebarButton>
        <MoreMenuItems state={state} dispatch={dispatch} ref={menuMoreRef} />
        {authState?.isLoggedIn && (
          <>
            <SidebarButton
              kind="stroke"
              isActive={profileActive}
              onClick={() => dispatch({ type: 'toggleSettings' })}
              tooltip={t('sidebar:settings')}
              id="SidebarSettingsButton"
            >
              <Icon.settings size={24} />
            </SidebarButton>
            <SettingsMenuItems
              state={state}
              dispatch={dispatch}
              ref={settingsRef}
            />
          </>
        )}
      </div>
    </>
  );
}

function RenderMoreMenuItems(
  {
    state,
    dispatch,
  }: {
    state: State;
    dispatch: (action: Action) => void;
  },
  ref: any,
) {
  const { t } = useTranslation(['sidebar']);
  const router = useRouter();
  const { data: authState } = useUserProfile();
  return (
    <AnimatePresence>
      {state.menu ? (
        <m.div
          ref={ref}
          initial={{ opacity: 0, translateX: -20, pointerEvents: 'none' }}
          animate={{ opacity: 1, translateX: 0, pointerEvents: 'initial' }}
          exit={{ opacity: 0, translateX: -20, pointerEvents: 'none' }}
          className={classes.menu_container}
        >
          <div className={`${classes['sidebar-menu-links']}`}>
            <div>
              {/* <Link
                href={PRIVATE_ROUTES.REFERRAL}
                onClick={() => dispatch({ type: 'toggleMenu' })}
              >
                {t('sidebar:menu.refer')}
              </Link> */}
              <Link
                href={GLOBAL_ROUTES.FAQ}
                onClick={() => dispatch({ type: 'toggleMenu' })}
              >
                {t('sidebar:menu.faq')}
              </Link>
              <Link
                href={'https://gamerbase.featurebase.app/'}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {t('sidebar:menu.report')}
              </Link>
              <Link
                href={'https://gamerbase.featurebase.app/changelog'}
                target="_blank"
                rel="noreferrer nofollow"
              >
                {t('sidebar:menu.changelog')}
              </Link>
              <Link href="mailto:contact@gamerbase.gg">
                {t('sidebar:menu.contact')}
              </Link>
              <Link
                href={GLOBAL_ROUTES.TERMS_AND_CONDITIONS}
                onClick={() => dispatch({ type: 'toggleMenu' })}
              >
                {t('sidebar:menu.terms')}
              </Link>
              <Link
                href={GLOBAL_ROUTES.PRIVACY_POLICY}
                onClick={() => dispatch({ type: 'toggleMenu' })}
              >
                {t('sidebar:menu.privacy')}
              </Link>
            </div>

            <div className={classes.social_links_and_logout}>
              <div className={classes.social_links}>
                <SidebarLink
                  kind="fill"
                  href={EXTERNAL_ROUTES.GAMERBASE_TWITTER}
                >
                  <Icon.twitter size={24} />
                </SidebarLink>
                <SidebarLink
                  kind="stroke"
                  href={EXTERNAL_ROUTES.GAMERBASE_WEBSITE}
                >
                  <Icon.web size={24} />
                </SidebarLink>
              </div>
              {authState?.isLoggedIn && (
                <div>
                  <Logout
                    onSuccess={() => {
                      dispatch({ type: 'toggleSettings' });
                      router.push(GLOBAL_ROUTES.LANDING);
                    }}
                    onError={() => {
                      dispatch({ type: 'toggleSettings' });
                      toast.error(t('sidebar:menu.logoutError'));
                      router.push(GLOBAL_ROUTES.LANDING);
                    }}
                  >
                    {({ onLogout }) => (
                      <Link
                        href=""
                        onClick={onLogout}
                        className={classes.danger}
                      >
                        {t('sidebar:menu.logout')}
                      </Link>
                    )}
                  </Logout>
                </div>
              )}
            </div>
          </div>
        </m.div>
      ) : null}
    </AnimatePresence>
  );
}

const MoreMenuItems = forwardRef(RenderMoreMenuItems);
function RenderSettingsMenuItems(
  {
    state,
    dispatch,
  }: {
    state: State;
    dispatch: (action: Action) => void;
  },
  ref: any,
) {
  const { t } = useTranslation(['sidebar']);
  const router = useRouter();
  const appDispatch = useAppDispatch();
  return (
    <AnimatePresence>
      {state.settings ? (
        <m.div
          ref={ref}
          initial={{ opacity: 0, translateX: -20, pointerEvents: 'none' }}
          animate={{ opacity: 1, translateX: 0, pointerEvents: 'initial' }}
          exit={{ opacity: 0, translateX: -20, pointerEvents: 'none' }}
          className={classes.menu_container}
        >
          <div className={`${classes['sidebar-menu-links']} `}>
            <Link
              href={PRIVATE_ROUTES.PROFILE('manage-account')}
              onClick={() => {
                appDispatch(SiteActions.toggleSidebar(false));
                dispatch({ type: 'toggleSettings' });
              }}
            >
              {t('sidebar:settings')}
            </Link>
            <Logout
              onSuccess={() => {
                dispatch({ type: 'toggleSettings' });
                router.push(GLOBAL_ROUTES.LANDING);
              }}
              onError={() => {
                dispatch({ type: 'toggleSettings' });
                toast.error(t('sidebar:menu.logoutError'));
                router.push(GLOBAL_ROUTES.LANDING);
              }}
            >
              {({ onLogout }) => (
                <Link href="" onClick={onLogout} className={classes.danger}>
                  {t('sidebar:menu.logout')}
                </Link>
              )}
            </Logout>
          </div>
        </m.div>
      ) : null}
    </AnimatePresence>
  );
}

const SettingsMenuItems = forwardRef(RenderSettingsMenuItems);
