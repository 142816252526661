import { m } from 'framer-motion';

import { AcquisitionRules } from '@/modules/shared/components/Template/Popups/content/AcquisitionRules';
import { ApeironSocialBindingModal } from '@/modules/shared/components/Template/Popups/content/ApeironSocialBinding/ApeironSocialBindingModal';
import { ClaimUsernamePopup } from '@/modules/shared/components/Template/Popups/content/ClaimUsername';
import { CommunityReferral } from '@/modules/shared/components/Template/Popups/content/CommunityReferral/CommunityReferral';
import { DeleteAccountPopup } from '@/modules/shared/components/Template/Popups/content/DeleteAccount';
import { EquipInventoryItemPopup } from '@/modules/shared/components/Template/Popups/content/EquipInventoryItem';
import { GamingCardForged } from '@/modules/shared/components/Template/Popups/content/GamingCardForged';
import { LeaveCommunityPopup } from '@/modules/shared/components/Template/Popups/content/LeaveCommunity';
import { LeaveEventPopup } from '@/modules/shared/components/Template/Popups/content/LeaveEvent';
import { LevelUp } from '@/modules/shared/components/Template/Popups/content/LevelUp';
import { LoginPopup } from '@/modules/shared/components/Template/Popups/content/Login';
import { LogoutPopup } from '@/modules/shared/components/Template/Popups/content/Logout';
import { QuestComplete } from '@/modules/shared/components/Template/Popups/content/QuestComplete';
import { QuestDetailsPopup } from '@/modules/shared/components/Template/Popups/content/QuestDetails';
import { QuestPending } from '@/modules/shared/components/Template/Popups/content/QuestPending/QuestPending';
import { RequestDataPopup } from '@/modules/shared/components/Template/Popups/content/RequestData';
import { UnlinkAccountPopup } from '@/modules/shared/components/Template/Popups/content/UnlinkAccount';
import { UsernameClaimed } from '@/modules/shared/components/Template/Popups/content/UsernameClaimed';
import { WhitelistPopup } from '@/modules/shared/components/Template/Popups/content/Whitelist/WhitelistPopup';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { ModalsInterface } from '@/redux/modals/types';

import classes from './Popups.module.scss';

export const PopupInner = ({
  modal,
  state,
}: {
  modal: ModalsInterface;
  state: string;
}) => {
  const options = modal.data?.options;

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${classes.popup_inner} ${
        options?.fullScreen ? classes.popup_fullscreen : ''
      } ${options?.login ? classes.popup_login : ''} ${state}`}
    >
      {modal.data?.content == MODAL.NOT_WHITELISTED && (
        <WhitelistPopup {...modal.data?.data} />
      )}
      {modal.data?.content == MODAL.LOGIN && (
        <LoginPopup {...modal.data?.data} />
      )}
      {modal.data?.content == MODAL.DELETE_ACCOUNT && <DeleteAccountPopup />}
      {modal.data?.content == MODAL.LOGOUT && (
        <LogoutPopup {...modal.data?.data} />
      )}
      {modal.data?.content == MODAL.QUEST_DETAILS && (
        <QuestDetailsPopup {...modal.data?.data} />
      )}
      {modal.data?.content == MODAL.QUEST_COMPLETE && (
        <QuestComplete {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.QUEST_PENDING && (
        <QuestPending {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.CLAIM_USERNAME && (
        <ClaimUsernamePopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.EQUIP_INVENTORY_ITEM && (
        <EquipInventoryItemPopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.REQUEST_DATA && (
        <RequestDataPopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.LEAVE_EVENT && (
        <LeaveEventPopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.LEAVE_COMMUNITY && (
        <LeaveCommunityPopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.LEVEL_UP && (
        <LevelUp {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.USERNAME_CLAIMED && (
        <UsernameClaimed {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.UNLINK_ACCOUNT && (
        <UnlinkAccountPopup {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.COMMUNITY_REFERRAL && (
        <CommunityReferral {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.ACQUISITION_RULES && (
        <AcquisitionRules {...modal.data.data} />
      )}
      {modal.data?.content == MODAL.GAMING_CARD_FORGED && (
        <GamingCardForged {...modal.data.data} />
      )}
      {modal.data?.content === MODAL.APEIRON_SOCIAL_BINDING && (
        <ApeironSocialBindingModal {...modal.data.data} />
      )}
    </m.div>
  );
};
