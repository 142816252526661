import {
  useJoinedCommunities,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { Images } from '@/assets/imgs';
import { useKillSwitch } from '@/lib/launchdarkly/useKillSwitch';
import { SiteActions } from '@/redux/site/actions';
import { GLOBAL_ROUTES } from '@/routes';
import { useAppDispatch } from '@/store';

import classes from './BrowseLinks.module.scss';
import { Icon } from '../../Atom/Icon';
import { SidebarLink } from '../../Molecule/SidebarButton/SidebarButton';

export function BrowseLinks() {
  const { t } = useTranslation(['sidebar']);
  const { data: communities } = useJoinedCommunities();
  const { data: authState } = useUserProfile();
  const appDispatch = useAppDispatch();
  const slug = router.query.slug;
  const explorerActive = router.route === GLOBAL_ROUTES.EXPLORE;
  const homeActive = router.route === GLOBAL_ROUTES.LANDING;
  const homeEnabled = authState?.isLoggedIn;
  const airdropActive = router.route === GLOBAL_ROUTES.AIRDROP;
  const isAirdropEnabled = useKillSwitch('AIRDROP_SECTION');

  const links = useMemo(() => {
    return [
      {
        link: GLOBAL_ROUTES.LANDING,
        name: t('sidebar:homepage'),
        icon: Icon.home,
        size: 16,
        isActive: homeActive,
        tooltip: t('sidebar:homepage'),
        enabled: homeEnabled,
      },
      {
        link: GLOBAL_ROUTES.EXPLORE,
        name: t('sidebar:explore'),
        icon: Icon.explore,
        size: 18,
        isActive: explorerActive,
        tooltip: t('sidebar:explore'),
        enabled: true,
      },
      {
        link: GLOBAL_ROUTES.AIRDROP,
        name: t('sidebar:airdrop'),
        icon: Icon.airdrop,
        size: 18,
        isActive: airdropActive,
        tooltip: t('sidebar:airdrop'),
        enabled: isAirdropEnabled,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes['list-container']}>
      {links.map((item) =>
        item.enabled ? (
          <SidebarLink
            key={item.name}
            kind="stroke"
            href={item.link}
            isActive={item.isActive}
            tooltip={item.tooltip}
            onClick={() => appDispatch(SiteActions.toggleSidebar(false))}
          >
            <item.icon size={item.size} className={classes.icon_container} />
          </SidebarLink>
        ) : null,
      )}

      {communities?.results.map((item) => (
        <SidebarLink
          key={item.name}
          href={`${GLOBAL_ROUTES.COMMUNITY}${item.slug}`}
          kind={'fill'}
          isActive={slug === item.slug}
          tooltip={item.name}
          onClick={() => appDispatch(SiteActions.toggleSidebar(false))}
        >
          <Image
            src={item.content?.images.logoImageUrl ?? Images.logo}
            alt={`${item.name} logo`}
            priority
            width={24}
            height={24}
          />
        </SidebarLink>
      ))}
    </div>
  );
}
