import { ResourceType } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Images } from '@/assets/imgs';
import { Videos } from '@/assets/videos';
import { CountUpAnimation } from '@/modules/shared/components/Atom/CountUpAnimation';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';
import { PopupContinueButton } from '../../../PopupContinueButton';

export const CreditResourceReward = ({
  resourceType,
  rewardName,
  isLoading,
  isError,
  amount,
  onClose,
}: {
  resourceType?: ResourceType;
  amount: number;
  rewardName?: string;
  isLoading: boolean;
  isError: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['globals', 'quests']);

  return (
    <>
      <div className="reward-container flex column middle center">
        <div className="reward-image flex column middle center">
          {!isLoading && !isError && resourceType?.images?.resourceImageUrl && (
            <>
              <Image
                src={resourceType.images.resourceImageUrl}
                alt={t('globals:points')}
                width={256}
                height={423}
                className="image"
              />
              <Image
                src={resourceType.images.resourceImageUrl}
                alt={t('globals:points')}
                width={256}
                height={423}
                className="shadow"
              />
            </>
          )}

          {isLoading ? (
            <LoadingSpinner size="big" />
          ) : (
            <video
              src={Videos.PARTICLES}
              loop
              muted
              autoPlay
              className="particles"
            />
          )}
          {!isLoading && !resourceType?.images?.resourceImageUrl && (
            <>
              <Image
                src={Images.points.points_blue}
                alt={t('globals:points')}
                width={256}
                height={423}
                className="image"
              />
              <Image
                src={Images.points.points_blue}
                alt={t('globals:points')}
                width={256}
                height={423}
                className="shadow"
              />
            </>
          )}
        </div>
        <CountUpAnimation
          className="reward lexend-heading-h2 font-bold"
          number={amount}
          prefix="+"
          sufix={rewardName}
        />
      </div>

      <PopupContinueButton onClick={onClose} />
    </>
  );
};
