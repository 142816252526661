import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

/**
 * Generates a dynamic icon component based on the given icon name.
 * Given iconName must be equal to exported module name from its respective file.
 *
 * @param {string} iconName - The name of the icon.
 * @return {ComponentType<IconProps>} The generated dynamic icon component.
 */
function dynamicIcon(iconName: string): ComponentType<IconProps> {
  return dynamic<IconProps>(() =>
    import(`./${iconName}`).then((module) => ({ default: module[iconName] })),
  );
}

export type IconProps = {
  size: number;
  color?: string;
  className?: string;
  ref?: React.Ref<SVGSVGElement>;
};

export const Icon = {
  airdrop: dynamicIcon('Airdrop'),
  arrowRight: dynamicIcon('ArrowRight'),
  battlenet: dynamicIcon('Battlenet'),
  bin: dynamicIcon('Bin'),
  check: dynamicIcon('Check'),
  checkLight: dynamicIcon('CheckLight'),
  chevronLeft: dynamicIcon('ChevronLeft'),
  chevronRight: dynamicIcon('ChevronRight'),
  close: dynamicIcon('Close'),
  clock: dynamicIcon('Clock'),
  compass: dynamicIcon('Compass'),
  copy: dynamicIcon('Copy'),
  cross: dynamicIcon('Cross'),
  discord: dynamicIcon('Discord'),
  dots: dynamicIcon('Dots'),
  download: dynamicIcon('Download'),
  epic: dynamicIcon('Epic'),
  exit: dynamicIcon('Exit'),
  explore: dynamicIcon('Explore'),
  externalLink: dynamicIcon('ExternalLink'),
  filter: dynamicIcon('Filter'),
  google: dynamicIcon('Google'),
  home: dynamicIcon('Home'),
  info: dynamicIcon('Info'),
  itemGrid: dynamicIcon('ItemGrid'),
  itemSlot: dynamicIcon('ItemSlot'),
  letter: dynamicIcon('Letter'),
  link: dynamicIcon('Link'),
  lock: dynamicIcon('Lock'),
  menu2: dynamicIcon('Menu2'),
  plus: dynamicIcon('Plus'),
  reload: dynamicIcon('Reload'),
  riot: dynamicIcon('Riot'),
  rows: dynamicIcon('Rows'),
  scroll: dynamicIcon('Scroll'),
  search: dynamicIcon('Search'),
  settings: dynamicIcon('Settings'),
  steam: dynamicIcon('Steam'),
  target: dynamicIcon('Target'),
  twitch: dynamicIcon('Twitch'),
  twitter: dynamicIcon('Twitter'),
  upload: dynamicIcon('Upload'),
  userprofile: dynamicIcon('UserProfile'),
  web: dynamicIcon('Web'),
  xplorer: dynamicIcon('Xplorer'),
  youtube: dynamicIcon('Youtube'),
};
