import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { LinkTwitch, QuestRequirementType } from '@/modules/quests/components';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export const TwitchFollowMission = ({ setDisabled }: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const socials = actionContext.context.authState?.socials;

  const twitch = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.TWITCH,
  );

  useEffect(() => {
    if (twitch) setDisabled?.(false);
  }, []);

  const href = `https://www.twitch.tv/${quest?.template.args.handle}`;

  return (
    <>
      <LinkTwitch twitch={twitch} />

      <div
        className={`quest-requirement flex between middle${
          !twitch ? ' disabled' : ''
        }
        ${
          actionContext.reason === QuestDisabledReason.QUEST_COMPLETED
            ? ' fulfilled'
            : ''
        }
        `}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:twitchFollow', {
              name: quest?.template.args.displayHandle,
            })}
          </h4>
        </div>
        <div className="requirement-action">
          {actionContext.reason === QuestDisabledReason.QUEST_COMPLETED ? (
            <Button variant={BUTTON_VARIANT.TRANSPARENT} disabled>
              {t('quests:following')}
            </Button>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              href={href}
              target="_blank"
              disabled={!twitch}
            >
              {t('quests:follow')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
