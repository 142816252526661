import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'react-i18next';

export function DisplayParticipants() {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();

  const participants = actionContext.context.quest?.completionCount || 0;

  return <>{t('participants', { count: participants })}</>;
}
