import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { LinkSteam } from '@/modules/quests/components';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export function SteamWishlistMission() {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const socials = actionContext.context.authState?.socials;

  const steam = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.STEAM,
  );

  return (
    <div className="quest-requirements">
      <LinkSteam steam={steam} />

      <div className="quest-objective flex middle between expand">
        <div className="font-bold lexend-body-l">
          {t('quests:wishListGame', {
            gameName: actionContext.context.quest?.template.args.gameName,
          })}
        </div>
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          href={`https://store.steampowered.com/app/${actionContext.context.quest?.template.args.appId}/`}
        >
          {t('quests:wishListGameNow', {
            gameName: actionContext.context.quest?.template.args.gameName,
          })}
        </Button>
      </div>
    </div>
  );
}
