import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { BUTTON_SIZE, BUTTON_VARIANT, Button } from '../../Atom/Buttons';
import classes from './PopupContinueButton.module.scss';

export const PopupContinueButton = ({
  onClick,
  href,
}: {
  onClick?: MouseEventHandler<HTMLElement> | undefined;
  href?: string;
}) => {
  const { t } = useTranslation(['globals']);
  return (
    <Button
      className={classes.continue_btn}
      variant={BUTTON_VARIANT.RED}
      size={BUTTON_SIZE.LARGE}
      onClick={onClick}
      href={href}
    >
      {t('globals:continue')}
    </Button>
  );
};
