import { useTranslation } from 'react-i18next';

import { Tag, TagProps } from '@/modules/shared/components/Atom/Tag';

export type QuestTagsProps = {
  tags?: TagProps[];
};

export const QuestTags = ({ tags }: QuestTagsProps) => {
  const { t } = useTranslation(['quests']);

  return (
    <div className="flex middle tags-list">
      {tags?.map((item) => (
        <Tag
          label={item.label}
          slug={item.slug}
          disabled
          key={item.slug}
          active
        />
      )) ?? (
        <Tag
          label={t('quests:other')}
          slug={'other'}
          key={'other'}
          disabled
          active
        />
      )}
    </div>
  );
};
