import { ModalsActionTypes, ModalsInterface } from './types';

const ActionCreators = {
  setModal: (data: ModalsInterface) => ({
    type: ModalsActionTypes.OPEN_MODAL,
    payload: data,
  }),
  close: (data: ModalsInterface) => ({
    type: ModalsActionTypes.CLOSE_MODAL,
    payload: data,
  }),
};

export { ActionCreators as ModalActions };
