import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { HOOK_FLAGS } from '@/lib/launchdarkly/FeatureFlags';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import classes from './LoginSignupButtons.module.scss';

export function LoginSignUpButtons() {
  const flags = useFlags();
  const { t } = useTranslation(['globals']);
  const { data: authState } = useUserProfile();
  const login: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      MODAL.LOGIN,
      process.env.NODE_ENV !== 'development' &&
        flags?.[HOOK_FLAGS.PRERELEASE] === false
        ? {
            options: ['google'],
            description: t('auth:login.whitelistedLogin'),
            onSuccessToast: false,
          }
        : null,
      { login: true },
    );
  };

  if (authState?.isLoggedIn) return null;

  return (
    <div className={classes.login_signup}>
      <Button
        variant={BUTTON_VARIANT.TRANSPARENT}
        onClick={login}
        className="login-button"
      >
        {t('globals:login')}
      </Button>
      <Button onClick={login} variant={BUTTON_VARIANT.RED}>
        {t('globals:signup')}
      </Button>
    </div>
  );
}
