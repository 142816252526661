const VERSION = `/v1`;

export const Endpoint = {
  // USER
  LOGIN: `${VERSION}/user/auth/password/login`,
  LOGIN_GOOGLE: `${VERSION}/user/auth/google/login`,
  LOGIN_WEB3: `${VERSION}/user/auth/siwe/login`,
  LOGIN_OAUTH: `${VERSION}/user/auth`,
  REFRESH_TOKEN: `${VERSION}/user/auth/refresh-session`,
  PICK_GAMES: `${VERSION}/user/pick-games`,

  GET_PROFILE: `${VERSION}/user/profile`,
  EDIT_PROFILE: `${VERSION}/user/profile`,
  DELETE_ACCOUNT: `${VERSION}/user/delete`,
  SOCIALS: `${VERSION}/user/socials`,
  UPDATE_SOCIALS: `${VERSION}/user/socials/update`,
  GET_USER_GAMES: `/user/games`,
  GET_USER_COMMUNITIES: `/user/communities`,
  REFERRALS: `v1/user/referrals`,

  // SUBSCRIPTION
  SUBSCRIBE: `${VERSION}/subscribe`,

  // QUESTS
  GET_QUEST: `${VERSION}/quests/{id}`,
  GET_QUESTS: `${VERSION}/quests`,
  GET_QUESTS_FILTERS: `${VERSION}/quests/tags`,
  GET_QUESTS_HISTORY: `${VERSION}/quests/history`,
  GET_QUESTS_HISTORY_FILTERS: `${VERSION}/quests/historyTags`,
  ACCEPT_QUEST: `${VERSION}/quests/{id}/accept`,
  COMPLETE_QUEST: `${VERSION}/quests/{id}/complete`,
  REFRESH_QUEST: `${VERSION}/quests/{id}/refresh`,
  LOAD_QUEST_CONTEXT: `${VERSION}/quests/{id}/load-context`,

  GET_QUEST_EVENT: `${VERSION}/quest-event/{id}`,
  GET_QUEST_EVENT_LEADERBOARD: `${VERSION}/quest-event/{id}/leaderboard`,

  // LEADERBOARD
  GET_LEADERBOARD: `${VERSION}/leaderboards/{resourceTypeId}/rank`,
  GET_USER_RANK: `${VERSION}/leaderboards/{resourceTypeId}/rank/user`,

  // GAMING
  GET_ALL_GAMES: `${VERSION}/games`,
  GET_GAME_DETAILS: `${VERSION}/games/{id}`,
  GET_GAME_QUESTS: `${VERSION}/games/{gid}/quests`,

  // COMMUNITIES
  GET_ALL_COMMUNITIES: `${VERSION}/community`,
  GET_COMMUNITY: `${VERSION}/community/{slug}`,
  GET_COMMUNITY_QUESTS: `${VERSION}/community/{id}/quests`,
  GET_COMMUNITY_LEADERBOARD: `${VERSION}/community/{communityId}/leaderboard/{resourceTypeId}`,
  GET_COMMUNITY_XP: `${VERSION}/community/{communityId}/leaderboard/xp`,
  GET_COMMUNITY_RANKING_XP: `${VERSION}/community/{id}/ranking/xp`,
  GET_COMMUNITY_QUEST_TAGS: `${VERSION}/community/{id}/quests/tags`,
  JOIN_COMMUNITY: `${VERSION}/community/{id}/join`,
  LEAVE_COMMUNITY: `${VERSION}/community/{id}/leave`,

  // INVENTORY
  GET_INVENTORY: `${VERSION}/community/assets/inventory`,
  GET_INVENTORY_SLOTS: `${VERSION}/community/assets/inventory/{inventoryId}/slots`,
  GET_INVENTORY_ITEMS: `${VERSION}/community/assets/inventory/slot/{slotId}/items`,
  GET_INVENTORY_CATEGORY_ITEMS: `${VERSION}/community/assets/inventory/categories/{categoryId}/items`,
  GET_INVENTORY_AVATAR: `${VERSION}/community/assets/inventory/{inventoryId}/avatar`,
  ALLOCATE_INVENTORY_SLOT: `${VERSION}/community/assets/inventory/{inventoryId}/allocate/slots`,

  // EVENTS
  GET_EVENTS: `${VERSION}/quest-event`,
  GET_EVENT_DETAILS: `${VERSION}/quest-event/{id}`,
  GET_EVENT_PARTICIPANT: `${VERSION}/quest-event/{id}/participant`,
  GET_EVENT_USER_RANK: `${VERSION}/quest-event/{id}/participant/rank`,
  PARTICIPATE_EVENT: `${VERSION}/quest-event/{eventId}/participant/register`,
  LEAVE_EVENT: `${VERSION}/quest-event/{eventId}/participant/unregister`,
  GET_EVENT_LEADERBOARD: `${VERSION}/quest-event/{eventId}/leaderboard`,

  LEADERBOARD: `${VERSION}/leaderboards/{resourceTypeId}/rank`,
  USER_RANK: `${VERSION}/leaderboards/{resourceTypeId}/rank/user`,

  NEWSLETTER: 'https://gaming.xborg.com/api/newsletter/post',
};

export const WhitelistSubscription = {
  baseURL: 'https://gaming.xborg.com',
  endpoints: {
    subscribe: '/api/newsletter/post',
  },
};

export enum LOCAL_STORAGE_VALUE {
  COOKIE_CONSENT = 'c',
  REFRESH_TOKEN = 'rt',
  SESSION_TOKEN = 'st',
  QUESTS_LIST_MINIMIZED = 'qlm',
  QUESTS_REFRESH_TIMER = 'qrt',
  QUEST_EVENT_SYNC_TIMER = 'qert',
}
