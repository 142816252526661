import { useQueryClient } from '@tanstack/react-query';
import {
  ACCEPTONLY_QUEST_TEMPLATE_NAME,
  CLIENT_QUERY_KEYS,
  QUEST_STATUS,
  QuestDetailsType,
  getQuestType,
  isAutoCompleteManual,
  shouldDisplayGamingCompleteButton,
} from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  guardQuestSubmit,
  useMutateAcceptQuest,
  useMutateCompleteQuest,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { DisplayParticipants } from '../DisplayParticipants/DisplayParticipants';
import { QuestLimit } from '../QuestLimit';
import { QuestRewards } from '../QuestRewards';

export const AcceptOnlyQuestFooter = ({
  disabled,
  payload,
}: {
  payload: any;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(['quests', 'errors']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const mutateAcceptQuest = useMutateAcceptQuest();
  const mutateCompleteQuest = useMutateCompleteQuest();
  const queryClient = useQueryClient();

  async function acceptQuest() {
    const id = quest?.questId;
    const shouldResolve = isAutoCompleteManual(quest?.template);
    if (!id) {
      return;
    }
    mutateAcceptQuest.mutate(
      { id, payload },
      {
        onSuccess: () => {
          if (!shouldResolve) {
            toast.success(t(getStartedMessage(actionContext?.context.quest)));
          } else {
            toast.success(t('quests:questCompletedDescription'));
          }
          queryClient.invalidateQueries([CLIENT_QUERY_KEYS.QUEST_DETAILS]);
        },
        onError: (err: unknown) => {
          match(err)
            .with(P.instanceOf(AxiosError), (err: AxiosError) => {
              match(err?.response?.data)
                .with({ code: 10013 }, () =>
                  toast.error(t('errors:globalErrors:not_public_data')),
                )
                .with({ code: 10014 }, () =>
                  toast.error(t('errors:globalErrors:new_or_not_public_data')),
                )
                .with({ code: 10026 }, () => {
                  toast.error(t('errors:globalErrors:handler_data_not_found'));
                })
                .otherwise(() => {
                  toast.error(t('errors:globalErrors:quest_complete_rejected'));
                });
            })
            .otherwise(() =>
              toast.error(t('errors:globalErrors:quest_complete_rejected')),
            );
        },
      },
    );

    if (shouldResolve) {
      openModal(MODAL.QUEST_PENDING, {
        qid: {
          id,
        },
      });
    }
  }

  async function completeQuest() {
    const id = quest?.questId;
    if (!id) return;
    mutateCompleteQuest.mutate(
      { id },
      {
        onSuccess: async () => {
          openModal(MODAL.QUEST_PENDING, {
            qid: {
              id,
            },
          });
        },
        onError: () => {
          toast.error(t('errors:globalErrors.quest_complete_rejected'));
        },
      },
    );
  }

  const isCompleteRequest =
    getQuestType(quest?.template.name) &&
    shouldDisplayGamingCompleteButton(quest?.status);
  const isButtonLoading = isCompleteRequest
    ? mutateCompleteQuest.isLoading
    : mutateAcceptQuest.isLoading;

  function handleButtonClick() {
    guardQuestSubmit(
      actionContext.reason,
      isCompleteRequest ? completeQuest : acceptQuest,
    );
  }

  if (!quest) return null;

  return (
    <>
      <div className="lexend-body-xs1">
        <QuestLimit
          attemptCount={quest.attemptCount}
          attemptLimit={quest.attemptLimit}
        />
      </div>
      <div className="footer-action">
        <div className="footer-reward">
          <QuestRewards />
          <div className="total-participants lexend-body-xs1">
            <DisplayParticipants />
          </div>
        </div>

        <Button
          id={'accept-only-quest-button'}
          variant={BUTTON_VARIANT.RED}
          className="final-action"
          size={BUTTON_SIZE.LARGE}
          loading={isButtonLoading}
          disabled={disabled || isButtonLoading}
          onClick={handleButtonClick}
        >
          <ButtonLabel />
        </Button>
      </div>
    </>
  );
};

function ButtonLabel() {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const { t } = useTranslation(['quests']);
  const isCompleteRequest =
    isAutoCompleteManual(quest?.template) ||
    shouldDisplayGamingCompleteButton(quest?.status);
  const isTextSubmission = [
    ACCEPTONLY_QUEST_TEMPLATE_NAME.TEXT_SUBMISSION,
  ].some((templateName) => templateName === quest?.template?.name);

  const buttonLabel = useMemo(() => {
    if (actionContext.reason === 'MAX_ATTEMPTS_REACHED') {
      t('buttonLabel.maxAttemptsReached');
    }
    if (isTextSubmission) {
      if (actionContext.reason === QuestDisabledReason.UNDER_ADMIN_REVIEW) {
        return t('buttonLabel.submitted');
      }
      return t('buttonLabel.submit');
    }
    if (!isCompleteRequest && quest?.status === QUEST_STATUS.INACTIVE) {
      return t('buttonLabel.start');
    }
    if (isCompleteRequest) {
      return t('buttonLabel.complete');
    } else if (actionContext.reason === 'QUEST_COMPLETED') {
      return t('buttonLabel.questCompleted');
    } else if (actionContext.reason === 'QUEST_FAILED') {
      return t('buttonLabel.questFailed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionContext, quest]);

  return <>{buttonLabel}</>;
}

function getStartedMessage(quest?: QuestDetailsType) {
  if (quest?.template.name === ACCEPTONLY_QUEST_TEMPLATE_NAME.TEXT_SUBMISSION) {
    return 'quests:manualReview';
  }

  return 'quests:quest_started';
}
