import { QuestDetailsType } from '@xborglabs/ui-shared';
import { useResource } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export const useQuestTitles = (data?: QuestDetailsType) => {
  const { t } = useTranslation(['quests']);

  const { data: resourceType } = useResource(
    data?.reward?.args?.resourceTypeId,
  );

  return useMemo(() => {
    let title = '';
    let subtitle = '';
    if (data?.reward.name === 'quest_progress') {
      const { args: rewardArgs } = data.reward;
      const { args: templateArgs } = data.template;

      const fieldName = t(`quests:fields:${rewardArgs.field}`);
      title = t(`quests:quest-progress`, {
        ...rewardArgs,
        resourceTypeName: resourceType?.name ?? '',
        field: fieldName,
      });

      if (templateArgs[rewardArgs.field] && rewardArgs['noLimit'])
        subtitle = `${templateArgs[rewardArgs.field]} ${fieldName} limit`;
    } else {
      title = t(`quests:${data?.template.name}`, {
        ...data?.template.args,
      });
    }

    const defaultStatText = t(`quests:${data?.template.name}-description`);
    return { title, subtitle, defaultStatText } as const;
  }, [data, resourceType]);
};
