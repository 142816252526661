import { USER_SOCIAL_TYPE, useQuestObjective } from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { QuestObjective } from '../../../QuestDetails';
import { QuestRequirementType } from '../../QuestRequirement';
import { LinkBattlenet } from '../LinkBattlenet';

export const BattlenetQuestRequirement = ({
  setDisabled,
}: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const socials = actionContext.context.authState?.socials;
  const quest = actionContext.context.quest;
  const battlenet = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.BATTLENET,
  );

  useEffect(() => {
    setDisabled?.(battlenet ? false : true);
  }, [battlenet]);

  const objective = useQuestObjective(quest);

  const title = t(`quests:${quest?.template.name}`, {
    ...quest?.template.args,
  });

  return (
    <>
      <LinkBattlenet battlenet={battlenet} />

      <QuestObjective title={title} data={quest} objective={objective} />
    </>
  );
};
