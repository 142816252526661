import dynamic from 'next/dynamic';
import { Fragment, ReactNode } from 'react';

import { formatURL } from './utils';

const RichTextViewer = dynamic(
  () =>
    import(
      '@/modules/shared/components/Organism/RichText/RichTextComposer/RichTextViewer'
    ),
  {
    ssr: false,
  },
);

export type SupportedTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'li'
  | 'a'
  | 'ul'
  | 'ol'
  | 'div'
  | 'blockquote';

type TypeMapOptions = {
  newTab?: boolean;
  url?: string;
  italic?: boolean;
  bold?: boolean;
  code?: boolean;
  strikethrough?: boolean;
};

function isValidHtmlTag(tagName?: string) {
  if (!tagName) return false;
  const parser = new DOMParser();
  const document = parser.parseFromString(
    `<${tagName}></${tagName}>`,
    'text/html',
  );
  const parsedTag = document.body.firstChild;

  return (
    parsedTag !== null &&
    parsedTag instanceof Element &&
    parsedTag.tagName.toLowerCase() === tagName.toLowerCase()
  );
}

function typeMap(
  text: ReactNode,
  type: SupportedTags | 'link' | 'indent',
  options?: TypeMapOptions,
) {
  switch (type) {
    case 'h1':
      return <h1 className="tag-h1 lexend-heading">{text}</h1>;
    case 'h2':
      return <h2 className="tag-h2 lexend-heading-xxs2">{text}</h2>;
    case 'h3':
      return <h3 className="tag-h3 lexend-heading-s3">{text}</h3>;
    case 'link':
      return (
        <a
          className="tag-link"
          href={formatURL(options?.url ?? '')}
          target={options?.newTab ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {text}
        </a>
      );
    case 'h4':
    case 'h5':
    case 'h6':
      return <h4 className="tag-h4 lexend-heading-xxs4">{text}</h4>;
    case 'indent':
      return <div className="tag-indent">{text}</div>;
    default:
      const TagName = isValidHtmlTag(type) ? type : Fragment;
      return (
        <TagName
          {...{
            ...(type
              ? {
                  className: `tag-${type}`,
                }
              : {}),
          }}
        >
          <StylizeText text={text} options={options} />
        </TagName>
      );
  }
}

function StylizeText({
  text,
  options,
}: {
  text: ReactNode;
  options?: TypeMapOptions;
}) {
  function wrap({
    children,
    WrapperComponent,
  }: {
    WrapperComponent: any;
    children?: ReactNode;
  }) {
    return <WrapperComponent>{children}</WrapperComponent>;
  }

  let Output = wrap({ children: text, WrapperComponent: Fragment });
  if (!options) return Output;
  if (options.italic) {
    Output = wrap({
      children: Output,
      WrapperComponent: () => <i className="tag-i">{text}</i>,
    });
  }
  if (options.bold) {
    Output = wrap({
      children: Output,
      WrapperComponent: () => <b className="tag-b">{text}</b>,
    });
  }
  if (options.code) {
    Output = wrap({
      children: Output,
      WrapperComponent: () => <code className="tag-code">{text}</code>,
    });
  }

  return Output;
}

export type ParsableContent =
  | {
      type?: SupportedTags;
      text?: string;
      children: ParsableContent[];
      italic?: boolean;
      bold?: boolean;
      code?: boolean;
      strikethrough?: boolean;
    }
  | {
      type: 'link';
      url: string;
      newTab: boolean;
      children: ParsableContent[];
      italic?: boolean;
      bold?: boolean;
      code?: boolean;
      strikethrough?: boolean;
    };

export function Parser({
  content,
  isPlain,
}: {
  isPlain?: boolean;
  content?: ParsableContent[] | string;
}) {
  if (!content) return null;

  if (typeof content === 'string') {
    if (content.startsWith('urn:xb')) {
      return null;
    }
    return typeMap(content, 'p');
  }

  return <RichTextViewer isPlain={Boolean(isPlain)} value={content} />;
}
