import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Images } from '@/assets/imgs';
import { Videos } from '@/assets/videos';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_LevelUp.scss';

export const LevelUp = () => {
  const { t } = useTranslation(['globals']);

  return (
    <div className="levelup-popup flex column middle center text-center">
      <Image
        src={Images.rain}
        alt=""
        width={1920}
        height={1080}
        className="background"
      />

      <div className="level">
        <Image
          src={Images.leaderboard.default}
          alt=""
          height={204}
          width={180}
        />
        <div className="level-number font-bold">10</div>
        <video src={Videos.LEVEL_UP} autoPlay muted loop className="video" />
      </div>

      <div className="lexend-xl font-bold">Level UP!</div>
      <div className="actions">
        <button
          className="btn btn-primary flex middle center large"
          type="button"
          onClick={() => {
            closeModal();
          }}
        >
          {t('globals:continue')}
        </button>
      </div>
    </div>
  );
};
