import { LeaveEvent } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_LeaveEvent.scss';

export type LeaveEventPopupProps = {
  id: string;
};

export const LeaveEventPopup = ({ id }: LeaveEventPopupProps) => {
  const { t } = useTranslation(['events', 'globals']);

  return (
    <LeaveEvent
      onSuccess={() => {
        toast.success(t('events:leave_event_success'));
      }}
      onError={() => toast.error(t('events:leave_event_failed'))}
    >
      {({ onLeave, isLoading }) => (
        <div className="leave-event-popup ">
          <div className="modal-title">{t('events:leaveWarning')}</div>

          <div className="flex middle actions">
            <Button
              variant={BUTTON_VARIANT.RED}
              onClick={() => closeModal()}
              className="cancel expand"
            >
              {t('globals:cancel')}
            </Button>
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              onClick={() => {
                closeModal();
                onLeave(id);
              }}
              loading={isLoading}
              className="leave expand"
            >
              {t('events:leave')}
            </Button>
          </div>
        </div>
      )}
    </LeaveEvent>
  );
};
