import {
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  USER_SOCIAL_TYPE,
  useQuestObjective,
} from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useEffect } from 'react';

import {
  QuestObjective,
  QuestRequirementType,
} from '@/modules/quests/components';
import { useQuestTitles } from '@/modules/quests/utils';

import { LinkApeiron } from './LinkApeiron';

export const ApeironQuestRequirement = ({
  setDisabled,
}: QuestRequirementType) => {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const socials = actionContext.context.authState?.socials;
  const { title, subtitle } = useQuestTitles(quest);

  // play-10-min exception
  const isPlay10Min =
    quest?.template?.name ===
    COMPLETEONLY_QUEST_TEMPLATE_NAME.APEIRON_PLAY_10_MINUTES;

  const apeiron = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.APEIRON,
  );

  useEffect(() => {
    setDisabled?.(!apeiron);
  }, [apeiron]);

  const objective = useQuestObjective(quest);

  if (!quest) return null;

  return (
    <>
      <LinkApeiron apeiron={apeiron} questId={quest.questId} />

      <QuestObjective
        title={title}
        subtitle={subtitle}
        data={quest}
        objective={objective}
        hasRefresh={isPlay10Min ? false : true}
      />
    </>
  );
};
