import axios from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { WhitelistSubscription } from '@/apis';
import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { TextInput } from '@/modules/shared/components/Molecule/Forms/TextInput';
import './_WhitelistForm.scss';

const emailValidationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Required'),
});

export function WhitelistForm() {
  const { t } = useTranslation(['auth']);

  const form = useFormik({
    validationSchema: emailValidationSchema,
    initialValues: {
      email: '',
    },

    onSubmit: joinWaitlist,
  });

  async function joinWaitlist(
    values: { email: string },
    helpers: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) {
    helpers.setSubmitting(true);
    const api = axios.create({
      baseURL: WhitelistSubscription.baseURL,
    });

    await api.post(WhitelistSubscription.endpoints.subscribe, {
      email: form.values.email,
    });
    toast.success(t('auth:login.whitelistSuccess'));
    form.resetForm();
    helpers.setSubmitting(false);
  }

  return (
    <form onSubmit={form.handleSubmit} className="whitelist-form">
      <TextInput
        id={'email'}
        onChange={form.handleChange('email')}
        value={form.values.email}
        error={form.errors.email}
        placeholder="Enter email here"
      />
      <Button
        variant={BUTTON_VARIANT.TRANSPARENT}
        onClick={form.submitForm}
        disabled={!form.isValid || form.isSubmitting}
        size={BUTTON_SIZE.LARGE}
        className="red"
      >
        {t('auth:login.joinWaitlistBtnLabel')}
      </Button>
    </form>
  );
}
