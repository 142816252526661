import {
  QuestDisabledReason,
  QuestRequiredAction,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { QuestRequirementType } from '@/modules/quests/components';

export const CheckResourceMission = ({ setDisabled }: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const amount = quest?.template?.args?.amount ?? 0;

  useEffect(() => {
    setDisabled?.(actionContext.action === QuestRequiredAction.STOP);
  }, [actionContext.action, setDisabled]);

  return (
    <div
      className={`quest-requirement flex between middle
        ${
          actionContext.reason === QuestDisabledReason.QUEST_COMPLETED
            ? ' fulfilled'
            : ''
        }
        `}
    >
      <div className="requirement-details expand">
        <h4 className="lexend-heading-l requirement-title">
          {t('quests:checkResourceDesc', { amount })}
        </h4>
      </div>
    </div>
  );
};
