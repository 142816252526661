import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

export function CommunityStats({
  community,
  communityId,
}: {
  communityId: string;
  community: string;
}) {
  const Component = useMemo(
    () =>
      match(community)
        .with('bushi', () => dynamic(() => import('./Bushi/Bushi')))
        .with('shrapnel', () => dynamic(() => import('./Shrapnel/Shrapnel')))
        .with('gaming-card', () => dynamic(() => import('./GamingCard')))
        .otherwise(() => null),
    [community],
  );
  if (!Component) return null;
  return <Component communityId={communityId} />;
}
