import { getQueryClient } from '@xborglabs/ui-shared';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';

import { createClientApi } from './axios';

export type QuerySetupOptions = {
  tenant?: string;
};

export function useQuerySetup(options?: QuerySetupOptions) {
  const [queryClient] = useState(() =>
    getQueryClient({
      defaultOptions: {
        queries: {
          staleTime: 30000, // 30 seconds
          retry: (failureCount, error) => {
            if (error instanceof AxiosError) {
              // Don't retry for certain error responses
              if ([400, 401, 404].includes(error.response?.status ?? 0)) {
                return false;
              }
            }
            // Retry others just once
            return failureCount <= 1;
          },
        },
      },
    }),
  );
  const api = useMemo(
    () => createClientApi(options?.tenant),
    [options?.tenant],
  );

  return { api, queryClient } as const;
}
