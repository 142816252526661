import { QUEST_STATUS } from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { P, match } from 'ts-pattern';
import * as yup from 'yup';

import { QuestRequirementType } from '@/modules/quests/components';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { TextArea } from '@/modules/shared/components/Molecule/Forms/TextArea';

const minLimit = 3;

const textSubmissionSchema = yup.object({
  text: yup.string().required('Required').min(minLimit, 'Too short'),
});
export const AnswerSubmissionMission = (props: QuestRequirementType) => {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const form = useFormik({
    validationSchema: textSubmissionSchema,
    onSubmit: () => {},
    initialValues: {
      text: quest?.metrics?.answer || '',
    },
  });

  const { t } = useTranslation(['quests']);

  const errorMessage = useMemo(() => {
    return match(actionContext.reason)
      .with(QuestDisabledReason.GUEST_USER, () => t('quests:loginFirstQuest'))
      .with(QuestDisabledReason.COMMUNITY_GUEST, () =>
        t('quests:questOnlyForMembers'),
      )
      .with(QuestDisabledReason.EVENT_GUEST, () =>
        t('quests:registerEventFirst'),
      )
      .with(QuestDisabledReason.EVENT_JOIN_NOT_ALLOWED, () =>
        t('quests:eventJoinNotAllowed'),
      )
      .otherwise(() => null);
  }, [actionContext.reason, t]);

  useEffect(() => {
    props.setPayload?.({
      text: form.values.text,
    });
  }, [form]);

  return (
    <>
      {errorMessage && <Alert variant="info">{errorMessage}</Alert>}
      <Body
        status={quest?.status}
        renderDefault={() => (
          <div
            className={`quest-requirement flex between middle
        ${
          actionContext.reason === QuestDisabledReason.QUEST_COMPLETED
            ? ' fulfilled'
            : ''
        }
        `}
          >
            <div className="text-submission-input w-full flex">
              <TextArea
                className="w-full"
                id={'answer-submission'}
                disabled={props.disabled}
                value={form.values.text}
                onChange={form.handleChange('text')}
                error={form.errors?.text}
                minLimit={minLimit}
                placeholder={
                  quest?.template?.args?.text ||
                  t('quests:textSubmissionPlaceholder')!
                }
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

function Body({
  status,
  renderDefault,
}: {
  status?: QUEST_STATUS;
  renderDefault: () => JSX.Element;
}) {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  return match([actionContext.reason, status])
    .with([QuestDisabledReason.QUEST_COMPLETED, P.any], () => (
      <Alert variant="info">{t('quests:pendingClaimDescription')}</Alert>
    ))
    .with([QuestDisabledReason.QUEST_FAILED, P.any], () => (
      <Alert variant="error">{t('quests:questFailedDescription')}</Alert>
    ))
    .with(P.union([P._, QUEST_STATUS.RESOLVING]), () => (
      <Alert variant="info">{t('quests:inReviewDescription')}</Alert>
    ))
    .otherwise(renderDefault);
}
