import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

export type GoogleLoginButtonProps = {
  label: string;
  onSuccess: (credentialResponse: CredentialResponse) => void;
  onError: () => void;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export const GoogleLoginButton = ({
  onClick,
  loading,
  label,
  disabled,
  onSuccess,
  onError,
}: GoogleLoginButtonProps) => {
  const googleBtnRef = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const btn = googleBtnRef.current as unknown as HTMLElement;

    if (btn) {
      setWidth(btn.clientWidth);
      window.addEventListener(
        'resize',
        debounce(() => {
          setWidth(btn.clientWidth);
        }, 200),
      );

      return () => {
        window.removeEventListener(
          'resize',
          debounce(() => {
            setWidth(btn.clientWidth);
          }, 200),
        );
      };
    }
  }, []);

  return (
    <>
      <div
        className={`google-btn flex middle center${
          disabled ? ' disabled' : ''
        }`}
        ref={googleBtnRef}
      >
        <GoogleLogin
          // useOneTap
          onSuccess={onSuccess}
          width={width + 'px'}
          size={'large'}
          onError={onError}
          click_listener={onClick}
          theme="filled_black"
        />
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          className="lexend-body-s"
          size={BUTTON_SIZE.LARGE}
          disabled={disabled}
        >
          <p className="flex middle center expand gap-small">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Icon.google color="white" size={24} className="google" />
            )}
            <p className="lexend-body-s">{label}</p>
          </p>
        </Button>
      </div>
    </>
  );
};
