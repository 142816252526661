import { QUEST_TYPE, QuestsFiltersType } from '@xborglabs/ui-shared';
import {
  QuestActionContext,
  QuestRequiredAction,
  questActionMachine,
  useCommunityDetail,
  useQuestDetail,
  useQuestEvent,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  QuestDetailsFooter,
  QuestDetailsHeader,
  QuestRequirement,
  QuestRewards,
  QuestStats,
} from '@/modules/quests/components';
import { HIDE_LIST } from '@/modules/quests/components/QuestStats/constants';
import { useQuestTitles } from '@/modules/quests/utils';
import { Alert } from '@/modules/shared/components/Molecule/Alert';
import { Parser } from '@/modules/shared/components/Organism/RichText';

import classes from './QuestDetails.module.scss';

export type QuestDetailsPopupProps = {
  qid: string;
  filters?: QuestsFiltersType;
};

export const QuestDetailsPopup = ({ qid }: QuestDetailsPopupProps) => {
  const router = useRouter();
  const { data, status } = useQuestDetail(qid);
  const { data: authState } = useUserProfile();
  const { data: event } = useQuestEvent(router.query.id as string, 'ongoing');
  const { data: community } = useCommunityDetail(data?.containerId);
  const { defaultStatText } = useQuestTitles(data);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [isFooter, setFooter] = useState<boolean>(true);
  const [payload, setPayload] = useState<any>({});

  const actionContext = useMemo(
    () =>
      questActionMachine({
        quest: data,
        authState,
        event: event?.questEvent,
        community,
      }),
    [authState, data, event?.questEvent, community],
  );

  // The following effect is responsible to check for on/off submit button
  // This should not be placed in any other component as actionContext is responsible for this.
  useEffect(() => {
    setDisabled(actionContext.action === QuestRequiredAction.STOP);
  }, [actionContext.action]);

  if (!data) return null;

  const shouldShowStatBox = !HIDE_LIST.includes(data?.template.name ?? '');
  const hasStats = [QUEST_TYPE.GAMING, QUEST_TYPE.OBJECTIVE]
    .map((o) => o.toLowerCase())
    .includes(data.questType.toLowerCase());
  return (
    <QuestActionContext.Provider value={actionContext}>
      <div className={`${classes.quest_details} `}>
        <div className={classes.popup_border} />

        <div className={classes.quest_details_container}>
          <QuestDetailsHeader />

          <div className={classes.quest_details_scrollbar}>
            <div className={classes.content}>
              <div
                className={`${classes.quests_subtitle} lexend-heading-s quest-subtitle parser-content`}
              >
                {data ? (
                  <Parser content={data.description} />
                ) : (
                  <Skeleton count={2} />
                )}
              </div>

              {Boolean(data?.feedback) && (
                <Alert
                  variant="warning"
                  style={{ marginTop: 12, marginBottom: 12 }}
                  title={'Feedback'}
                >
                  {data?.feedback}
                </Alert>
              )}

              {status === 'success' && (
                <QuestRequirement
                  setPayload={(p) => setPayload(p)}
                  setDisabled={setDisabled}
                  setFooter={setFooter}
                  disabled={isDisabled}
                />
              )}
              {isFooter && (
                <div className={classes.quest_data}>
                  <QuestRewards />

                  {hasStats && shouldShowStatBox ? (
                    <div className={classes.quest_stats}>
                      <QuestStats
                        metrics={data?.metrics as Record<string, string>}
                        defaultStatText={defaultStatText}
                        target={data?.template?.args as Record<string, string>}
                      />
                    </div>
                  ) : (
                    <div className="expand" />
                  )}
                </div>
              )}
            </div>
          </div>

          {isFooter && (
            <QuestDetailsFooter disabled={isDisabled} payload={payload} />
          )}
        </div>
      </div>
    </QuestActionContext.Provider>
  );
};
