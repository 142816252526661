import { USER_SOCIAL_TYPE, useQuestObjective } from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { useEffect } from 'react';

import {
  LinkSteam,
  QuestObjective,
  QuestRequirementType,
} from '@/modules/quests/components';
import { useQuestTitles } from '@/modules/quests/utils';

export const SteamQuestRequirement = ({
  setDisabled,
}: QuestRequirementType) => {
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const socials = actionContext.context.authState?.socials;
  const { title, subtitle } = useQuestTitles(quest);

  const steam = socials?.find(
    (item) => item.accountType === USER_SOCIAL_TYPE.STEAM,
  );

  useEffect(() => {
    setDisabled?.(!steam);
  }, [steam]);

  const objective = useQuestObjective(quest);

  return (
    <>
      <LinkSteam steam={steam} />

      <QuestObjective
        title={title}
        subtitle={subtitle}
        data={quest}
        objective={objective}
      />
    </>
  );
};
