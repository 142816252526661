import { GET_QUEST_TYPE, getQuestType } from '@xborglabs/ui-shared';
import { useQuestActionContext } from '@xborglabs/ui-shared/dist/client';
import { P, match } from 'ts-pattern';

import {
  AcceptOnlyQuestFooter,
  CompleteOnlyQuestFooter,
  ReferralsQuestFooter,
} from '@/modules/quests/components';

import { GuestUserFooter } from '../GuestUesrFooter/GuestUserFooter';

export const QuestDetailsFooter = (props: {
  payload: any;
  disabled?: boolean;
}) => {
  const actionContext = useQuestActionContext();
  if (!actionContext.context.authState?.isLoggedIn) {
    return <GuestUserFooter />;
  }

  const FooterComponent = getQuestFooter(
    actionContext.context.quest?.template.name,
  );

  return (
    <div className="flex quest-details__footer between middle">
      <FooterComponent {...props} />
    </div>
  );
};

function getQuestFooter(name?: string) {
  return match(getQuestType(name))
    .with(GET_QUEST_TYPE.REFERRALS, () => ReferralsQuestFooter)
    .with(P.union(GET_QUEST_TYPE.ACCEPT_ONLY, GET_QUEST_TYPE.GAMING), () => {
      return AcceptOnlyQuestFooter;
    })
    .with(P.union(GET_QUEST_TYPE.COMPLETE_ONLY, GET_QUEST_TYPE.UNKNOWN), () => {
      return CompleteOnlyQuestFooter;
    })
    .exhaustive();
}
