import Skeleton from 'react-loading-skeleton';

export const LoadingRequirement = () => {
  return (
    <>
      <div className="quest-requirement flex between middle">
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            <Skeleton />
          </h4>
          <div className="requirement-desc lexend-body-s">
            <Skeleton count={2} />
          </div>
        </div>
      </div>
      <div className="quest-requirement flex between middle">
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            <Skeleton />
          </h4>
          <div className="requirement-desc lexend-body-s">
            <Skeleton count={2} />
          </div>
        </div>
      </div>
    </>
  );
};
