/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr'],
    localeDetection: false,
  },
  serializeConfig: false,
  use: [
    {
      init: (i18next) => {
        i18next.services.formatter.add(
          'tftTier',
          (v) =>
            ({
              0: 'Unranked',
              1: 'Iron',
              2: 'Bronze',
              3: 'Silver',
              4: 'Gold',
              5: 'Platinum',
              6: 'Diamond',
              7: 'Master',
              8: 'Grandmaster',
              9: 'Challenger',
            }[Number(v)] ?? v),
        );

        i18next.services.formatter.add('toFixed', (v) => {
          const percentage = parseFloat(v);

          return isNaN(percentage) ? v : percentage.toFixed(2);
        });

        i18next.services.formatter.add(
          'lolTier',
          (v) =>
            ({
              0: 'Unranked',
              1: 'Iron',
              2: 'Bronze',
              3: 'Silver',
              4: 'Gold',
              5: 'Platinum',
              6: 'Emerald',
              7: 'Diamond',
              8: 'Master',
              9: 'Grandmaster',
              10: 'Challenger',
            }[Number(v)] ?? v),
        );

        i18next.services.formatter.add(
          'riotRank',
          (v) =>
            ({
              5: 'V',
              4: 'IV',
              3: 'III',
              2: 'II',
              1: 'I',
              0: 'UNRANKED',
            }[Number(v)] ?? v),
        );

        return i18next;
      },
      type: '3rdParty',
    },
  ],
};
