import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { useQuestTitles } from '@/modules/quests/utils';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { AppViewModel } from '@/modules/viewmodels';
import { useQuestObjective } from '@xborglabs/ui-shared';
import { QuestObjective } from '../../../QuestDetails';
import classes from './CommunityReferralMission.module.scss';

export function CommunityReferralMission() {
  const { t } = useTranslation(['community']);
  const router = useRouter();
  const actionContext = useQuestActionContext();
  const referralURL =
    window.location.origin +
    router.basePath +
    `/c/${actionContext.context.community?.slug}/?intent=community_referral&r=${actionContext.context.authState?.displayName}&community=${actionContext.context.community?.slug}`;
  const appVm = new AppViewModel();

  const quest = actionContext.context.quest;
  const { title, subtitle } = useQuestTitles(quest);

  const objective = useQuestObjective(quest);

  if (actionContext.reason === QuestDisabledReason.GUEST_USER) return null;

  return (
    <>
      <QuestObjective
        title={title}
        subtitle={subtitle}
        data={quest}
        objective={objective}
      />
      <div className="referral-input w-full flex column">
        <div className="flex middle referral-input-wrapper">
          <input
            type="text"
            value={referralURL}
            className="lexend-body-m expand referral-input-text"
            readOnly
          />
          <div
            role="button"
            className={`${classes.copy_button} btn-copy flex middle center`}
            onClick={(e) => {
              e.preventDefault();
              appVm.copyToClipboard(
                referralURL,
                t('success:globalSuccess.copied_to_clipboard'),
              );
            }}
          >
            <Icon.copy size={21} color={'white'} />
          </div>
        </div>
      </div>
    </>
  );
}
