import { CSSProperties, ReactNode } from 'react';
import { match } from 'ts-pattern';

import { Icon } from '@/modules/shared/components/Atom/Icon';

export type AlertType = 'background' | 'simple';
export type AlertVariant = 'info' | 'success' | 'warning' | 'error';

export function Alert({
  children,
  variant = 'info',
  type = 'simple',
  title,
  style = {},
}: {
  children: ReactNode;
  variant: AlertVariant;
  type?: AlertType;
  title?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      style={style}
      className={`flex w-full alert-${type} lexend-body-xs1 alert-${type}-${variant}`}
    >
      <div style={{ position: 'relative', top: 2 }}>
        {match(variant)
          .with('info', () => <Icon.info size={16} />)
          .otherwise(() => (
            <Icon.info size={16} />
          ))}
      </div>
      <div className={'flex column'}>
        {!!title && <div style={{ fontWeight: 600 }}>{title}</div>}
        <div>{children}</div>
      </div>
    </div>
  );
}
