import { SiteActionTypes } from './types';

const ActionCreators = {
  toggleNavigation: (payload: boolean) => ({
    type: SiteActionTypes.TOGGLE_NAVIGATION,
    payload,
  }),

  setBackUrl: (url: string) => ({
    type: SiteActionTypes.SET_BACK_URL,
    payload: url,
  }),

  toggleSidebar: (payload: boolean) => ({
    type: SiteActionTypes.TOGGLE_SIDEBAR,
    payload,
  }),
  setSiteTitle: (payload?: string) => ({
    type: SiteActionTypes.SET_SITE_TITLE,
    payload,
  }),

  setNavbarCta: (payload: 'None' | 'Login' | 'JoinCommunity') => ({
    type: SiteActionTypes.SET_NAVBAR_CTA,
    payload,
  }),
};

export { ActionCreators as SiteActions };
