import { UserProfileSocialType } from '@xborglabs/ui-shared';
import { UnlinkSocial } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { Tippy } from '@/lib/tippy';
import { toast } from '@/lib/toastify';
import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

export type LinkApeironProps = {
  apeiron?: UserProfileSocialType;
  questId: string;
};

export function LinkApeiron({ apeiron, questId }: LinkApeironProps) {
  useIntentToast();
  const { t } = useTranslation(['quests', 'errors', 'success']);

  function handleBindAccount() {
    openModal(MODAL.APEIRON_SOCIAL_BINDING, {
      onClose: () => {
        openModal(MODAL.QUEST_DETAILS, {
          qid: questId,
        });
      },
    });
  }

  return (
    <>
      <div
        className={`quest-requirement flex between middle${
          apeiron ? ' fulfilled' : ''
        }`}
      >
        <div className="requirement-details expand">
          <h4 className="lexend-heading-l requirement-title">
            {t('quests:apeironLink')}
          </h4>
        </div>

        <div className="requirement-action">
          {apeiron ? (
            <Tippy
              className="lexend-body-xs1"
              content={t('quests:unlinkAccount')}
            >
              <div>
                <UnlinkSocial
                  onError={() => {
                    toast.error(
                      t('errors:globalErrors.account_unlink_rejected'),
                    );
                  }}
                  onSuccess={() => {
                    toast.success(t('success:globalSuccess.unlink_success'));
                  }}
                >
                  {({ onUnlink, isLoading }) => (
                    <Button
                      variant={BUTTON_VARIANT.TRANSPARENT}
                      onClick={() => onUnlink(apeiron.socialId)}
                      disabled={isLoading}
                    >
                      {apeiron.handle
                        ? `@${apeiron.handle}`
                        : t('quests:accountLinked')}
                    </Button>
                  )}
                </UnlinkSocial>
              </div>
            </Tippy>
          ) : (
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              className="lexend-heading-m action btn btn-transparent flex middle center "
              onClick={handleBindAccount}
            >
              {t('missions.linkAccount')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
