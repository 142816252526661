import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useEffect } from 'react';

import { QuestRequirementType } from '@/modules/quests/components';
import { ReferralInput } from '@/modules/referrals';

export const ReferralCountMission = ({ setDisabled }: QuestRequirementType) => {
  useEffect(() => {
    setDisabled?.(false);
  }, []);
  const actionContext = useQuestActionContext();

  return (
    <div
      className={`flex between middle
        ${
          actionContext.reason === QuestDisabledReason.QUEST_COMPLETED
            ? ' fulfilled'
            : ''
        }
        `}
    >
      <ReferralInput />
    </div>
  );
};
