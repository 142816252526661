import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { selectSite } from '@/redux/site/reducers';
import classes from './BackButton.module.scss';
export function BackButton() {
  const { t } = useTranslation(['globals']);
  const { backUrl } = useSelector(selectSite);
  if (!backUrl) return null;
  return (
    <Button
      variant={BUTTON_VARIANT.TRANSPARENT}
      size={BUTTON_SIZE.SMALL}
      className={classes.button}
      href={backUrl}
    >
      <Icon.chevronLeft size={16} color="white" />
      {t('globals:goback')}
    </Button>
  );
}
