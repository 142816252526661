import {
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  QUEST_STATUS,
  QuestDetailsType,
} from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  QuestRequiredAction,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';

import { QuestRequirementType, QuizOptions } from '@/modules/quests/components';
import { Alert } from '@/modules/shared/components/Molecule/Alert';

export const QuizQuest = (props: QuestRequirementType) => {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const [selected, setSelected] = useState<string>('');
  const args: Record<string, any> = quest?.template?.args ?? {};
  const options = (args.options as string[]) ?? ([] as string[]);

  const errorMessage = useMemo(() => {
    return match(actionContext.reason)
      .with(QuestDisabledReason.GUEST_USER, () => t('quests:loginFirstQuest'))
      .with(QuestDisabledReason.COMMUNITY_GUEST, () =>
        t('quests:questOnlyForMembers'),
      )
      .with(QuestDisabledReason.EVENT_GUEST, () =>
        t('quests:registerEventFirst'),
      )
      .with(QuestDisabledReason.EVENT_JOIN_NOT_ALLOWED, () =>
        t('quests:eventJoinNotAllowed'),
      )
      .otherwise(() => null);
  }, [actionContext.reason, t]);

  useEffect(() => {
    props.setDisabled?.(true);
  }, []);

  useEffect(() => {
    if (selected.length && actionContext.action !== QuestRequiredAction.STOP) {
      props.setDisabled?.(false);
      props.setPayload?.({
        text: selected,
      });
    }
  }, [actionContext.action, props, selected]);

  return (
    <>
      {errorMessage && <Alert variant="info">{errorMessage}</Alert>}
      <Body
        status={quest?.status}
        renderDefault={() => (
          <>
            <QuizOptions
              value={selected}
              options={options}
              onChange={(v) => setSelected(v)}
            />
            <Disclaimer quest={quest} />
          </>
        )}
      />
    </>
  );
};

function Body({
  status,
  renderDefault,
}: {
  status?: QUEST_STATUS;
  renderDefault: () => JSX.Element;
}) {
  const { t } = useTranslation(['quests']);

  return match(status)
    .with(QUEST_STATUS.COMPLETED, () => (
      <Alert variant="info">{t('quests:pendingClaimDescription')}</Alert>
    ))
    .with(QUEST_STATUS.FAILED, () => (
      <Alert variant="error">{t('quests:questFailedDescription')}</Alert>
    ))
    .with(P.union(QUEST_STATUS.RESOLVING), () => (
      <Alert variant="info">{t('quests:inReviewDescription')}</Alert>
    ))
    .otherwise(renderDefault);
}

function Disclaimer({ quest }: { quest?: QuestDetailsType }) {
  const { t } = useTranslation(['quests']);
  if (!quest) return null;
  if (quest?.template?.name !== COMPLETEONLY_QUEST_TEMPLATE_NAME.POLL_QUEST)
    return null;

  const text =
    quest?.template?.name === COMPLETEONLY_QUEST_TEMPLATE_NAME.POLL_QUEST &&
    quest?.template?.args?.isPrivate === 'true'
      ? t('quests:disclaimer:pollHidden')
      : t('quests:disclaimer:pollVisible');

  return <Alert variant="info">{text}</Alert>;
}
