export const BattlenetRegions = [
  {
    label: 'United States',
    short: 'us',
    slug: 'us',
  },
  {
    label: 'Europe',
    short: 'eu',
    slug: 'eu',
  },
  {
    label: 'Korea',
    short: 'kr',
    slug: 'kr',
  },
  {
    label: 'Taiwan',
    short: 'tw',
    slug: 'tw',
  },
  {
    label: 'China',
    short: 'cn',
    slug: 'cn',
  },
];
