export const Videos = {
  LIGHTNING: '/videos/lightning.webm',
  RAIN: '/videos/rain.webm',
  GAMERBASE: '/videos/gamerbase.webm',
  AVATARS: '/videos/avatars.webm',
  HERO: '/videos/hero.webm',
  ABSTRACT: '/videos/abstract.webm',
  PARTICLES: '/videos/particles.webm',
  LEVEL_UP: '/videos/levelup.webm',
};
