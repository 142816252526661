import { PageError } from '../Errors/PageError/PageError';
import './_ErrorPage.scss';

const errorMessages: Record<
  number,
  {
    paragraphText: string;
    actionLabel: string;
    action?: () => void;
  }
> = {
  404: {
    paragraphText: 'The page you are looking for no longer exists.',
    actionLabel: 'Go back to home',
  },
  500: {
    paragraphText: 'Something went wrong. Please try again later.',
    actionLabel: 'Refresh the page',
    action: () => {
      window.location.reload();
    },
  },
};

export function ErrorPage({ code }: { code: 404 | 500 }) {
  return (
    <main className="error-page">
      <PageError
        statusCode={code}
        paragraphText={errorMessages[code].paragraphText}
        action={errorMessages[code]?.action}
        actionLabel={errorMessages[code].actionLabel}
      />
    </main>
  );
}
