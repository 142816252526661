import { QUEST_STATUS, getGamingStatus } from '@xborglabs/ui-shared';
import { useTranslation } from 'next-i18next';

export type QuestStatusGamingProps = {
  status?: QUEST_STATUS;
};

export const QuestStatusGaming = ({ status }: QuestStatusGamingProps) => {
  const { t } = useTranslation(['quests']);

  return (
    <div className={`quest-status-gaming flex middle ${status?.toLowerCase()}`}>
      <div className="circle" />
      <span className="lexend-body-xxs">{getGamingStatus(t)(status)}</span>
    </div>
  );
};
