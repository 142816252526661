import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export type TextAreaType = {
  id: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  limit?: number;
  optional?: boolean;
  disabled?: boolean;
  minLimit?: number;
};

export const TextArea = ({
  id,
  label,
  placeholder,
  value,
  error,
  onChange,
  onFocus,
  onBlur,
  className,
  limit,
  optional,
  disabled,
  minLimit,
}: TextAreaType) => {
  const { t } = useTranslation(['globals']);
  const [errorIn, setErrorIn] = useState<boolean>(false);
  const count = value.length;
  const limitReached = count > (limit ?? 0);

  const onTextChange = (el: any) => {
    if (onChange) onChange(el.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorIn(!!error);
    }, 300);
  }, [error]);

  function renderLimit() {
    if (limit) {
      return (
        <div
          className={`lexend-body-xs2 count-characters${
            limitReached ? ' reached' : ''
          }`}
        >
          {count} / {limit} {t('globals:characters')}
        </div>
      );
    }
    if (minLimit) {
      return (
        <div className="lexend-body-xs2 count-characters">
          {t('minCharacters', { minLimit })}
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className={`input-wrapper${error ? ' has-error' : ''} ${className ?? ''}`}
    >
      <div className="input-container flex column">
        {label && (
          <label className="input-label" htmlFor={`input-${id}`}>
            {label} {optional && <span>{t('globals:optional')}</span>}
          </label>
        )}
        <textarea
          onChange={onTextChange}
          onFocus={onFocus}
          onBlur={onBlur}
          id={`textarea-${id}`}
          placeholder={placeholder ?? ''}
          value={value}
          autoComplete="off"
          disabled={disabled}
        />
        <div className="border" />
      </div>

      <div className="validation-container">
        <div>
          {error && (
            <div
              className={`lexend-body-xs2 input-error ${
                errorIn ? 'in' : 'out'
              }`}
            >
              {error}
            </div>
          )}
        </div>
        <div>{renderLimit()}</div>
      </div>
    </div>
  );
};
