import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { Images } from '@/assets/imgs';
import { Brand } from '@/modules/shared/components/Atom/Brand';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { GlobalState } from '@/reducer';
import { GLOBAL_ROUTES } from '@/routes';

import { LoginSignUpButtons } from '../LoginSignupButtons/LoginSignupButtons';

export const Navigation = () => {
  const { data: authState } = useUserProfile();
  const site = useSelector((state: GlobalState) => state.site);

  const router = useRouter();

  return (
    <nav
      className={`navigation flex between middle ${
        site.navigation ? 'visible' : 'hidden'
      } ${authState?.isLoggedIn ? 'user' : 'guest'}`}
    >
      <div
        className={`brand flex middle ${
          router.pathname.startsWith('/c/') ? 'brand--hidden' : ''
        }`}
      >
        {authState?.isLoggedIn ? (
          <Brand />
        ) : (
          <Link href={GLOBAL_ROUTES.LANDING}>
            <Image src={Images.logo_full} alt="" width={127} height={24} />
          </Link>
        )}

        {!authState?.isLoggedIn && (
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            size={BUTTON_SIZE.XSMALL}
            className="beta"
          >
            Beta
          </Button>
        )}
      </div>

      <LoginSignUpButtons />
    </nav>
  );
};
