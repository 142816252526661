export enum InventoryActionTypes {
  UPDATE_AVATAR = 'inventory/UPDATE_AVATAR',
  SET_AVATAR_LOADING = 'inventory/SET_AVATAR_LOADING',
  TRIGGER_CHANGE_AVATAR = 'inventory/TRIGGER_CHANGE_AVATAR',
}

export interface InventoryInterface {
  avatar: Record<string, InventoryUpdatedAt>;
}

export type InventoryUpdatedAt = {
  updatedAt: string | null;
  changedAt?: string;
  isLoading?: boolean;
  communityKey: string;
};
