import { WowCharacter } from '@xborglabs/ui-shared';

import styles from './BattlenetExtendedSocials.module.scss';

export function WowCharacterInfo({ character }: { character?: WowCharacter }) {
  if (!character) return null;

  return (
    <article className={styles.battlenet_character_info}>
      <h2 className={styles.battlenet_character_name}>
        {character.character}
        <span className={styles.battlenet_character_realm}>
          #{character.realm}
        </span>
      </h2>
    </article>
  );
}
