import { InventoryActionTypes, InventoryUpdatedAt } from './types';

const ActionCreators = {
  updateAvatar: (payload: InventoryUpdatedAt) => ({
    type: InventoryActionTypes.UPDATE_AVATAR,
    payload,
  }),
  setAvatarLoading: (payload: {
    communityKey: string;
    isLoading: boolean;
  }) => ({
    type: InventoryActionTypes.SET_AVATAR_LOADING,
    payload,
  }),
  triggerChangeAvatar: (payload?: string) => ({
    type: InventoryActionTypes.TRIGGER_CHANGE_AVATAR,
    payload,
  }),
};

export { ActionCreators as InventoryActions };
