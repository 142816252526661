import { AUTH_COOKIES } from '@xborglabs/ui-shared';
import Cookies from 'universal-cookie';

export class AuthCookies {
  static readonly SESSION_TOKEN = AUTH_COOKIES.SESSION_TOKEN;
  static readonly REFRESH_TOKEN = AUTH_COOKIES.REFRESH_TOKEN;

  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  get sessionToken() {
    return this.cookies.get(AuthCookies.SESSION_TOKEN);
  }

  get refreshToken() {
    return this.cookies.get(AuthCookies.REFRESH_TOKEN);
  }

  login(sessionToken: string, refreshToken: string) {
    this.cookies.set(AuthCookies.SESSION_TOKEN, sessionToken, {
      sameSite: 'lax',
      secure: true,
      path: '/',
      maxAge: 604800,
    });

    this.cookies.set(AuthCookies.REFRESH_TOKEN, refreshToken, {
      sameSite: 'lax',
      secure: true,
      path: '/',
      maxAge: 604800,
    });
  }

  refresh(sessionToken: string) {
    this.cookies.set(AuthCookies.SESSION_TOKEN, sessionToken, {
      sameSite: 'lax',
      secure: true,
      path: '/',
      maxAge: 604800,
    });
  }

  logout() {
    this.cookies.remove(AuthCookies.SESSION_TOKEN);
    this.cookies.remove(AuthCookies.REFRESH_TOKEN);
  }
}
