import { RequestData } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { TextInput } from '@/modules/shared/components/Molecule/Forms/TextInput';
import { PopupClose } from '@/modules/shared/components/Template/Popups';
import './_RequestData.scss';

export type RequestDataFormProps = {
  email: string;
};

export const RequestDataPopup = () => {
  const { t } = useTranslation(['profile', 'globals']);

  return (
    <RequestData>
      {({ onRequest, isLoading, email, setEmail }) => (
        <div className="request-data-popup ">
          <div className="flex between middle">
            <h3 className="popup-title lexend-heading">
              {t('profile:exportYourData')}
            </h3>

            <PopupClose />
          </div>

          <p className="lexend-body-s">{t('profile:exportDataDisclaimer')}</p>

          <div className="email-input lexend-body-s">
            <span>{t('profile:email')}</span>
            <TextInput
              id="request-data-email"
              placeholder={t('profile:exportDataPlaceholder')!}
              value={email}
              onChange={(email) => setEmail(email)}
            />
          </div>
          <div className="flex end">
            <Button
              variant={BUTTON_VARIANT.RED}
              onClick={onRequest}
              size={BUTTON_SIZE.LARGE}
              className="send"
              loading={isLoading}
            >
              {t('globals:request')}
            </Button>
          </div>
        </div>
      )}
    </RequestData>
  );
};
