import { useEffect, useState } from 'react';

import { Videos } from '@/assets/videos';

export const LoaderPage = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className="loader-page flex middle center">
      {isClient && <video src={Videos.GAMERBASE} autoPlay loop muted />}
    </div>
  );
};
