import { AnimatePresence, m } from 'framer-motion';
import React, { ReactNode } from 'react';

import classes from './TextInput.module.scss';

export type TextInputType = {
  id: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string | number;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  renderIcon?: () => ReactNode;
  variant?: 'outlined' | 'filled';
};

export const TextInput = ({
  id,
  label,
  placeholder,
  value,
  error,
  onChange,
  onFocus,
  onBlur,
  className,
  renderIcon,
  variant = 'filled',
}: TextInputType) => {
  const onTextChange = (el: any) => {
    if (onChange) onChange(el.target.value);
  };

  return (
    <div
      className={`input-wrapper ${error ? 'has-error' : ''} ${className ?? ''}`}
    >
      <div
        className={`${classes.input_container} ${
          classes[`variant_${variant}`]
        }`}
      >
        {renderIcon ? <div className="icon">{renderIcon()}</div> : null}
        {label && (
          <label className="input-label" htmlFor={`input-${id}`}>
            {label}
          </label>
        )}
        <input
          onChange={onTextChange}
          onFocus={onFocus}
          onBlur={onBlur}
          id={`input-${id}`}
          placeholder={placeholder ?? ''}
          value={value}
          autoComplete="off"
          type="text"
        />
      </div>
      <AnimatePresence>
        {error && (
          <m.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className={classes.error}
          >
            {error}
          </m.p>
        )}
      </AnimatePresence>
    </div>
  );
};
