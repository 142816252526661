import {
  CommunityDetail,
  JoinCommunity,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import { AnimatePresence, m } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';

import { Images } from '@/assets/imgs';
import { toast } from '@/lib/toastify';
import { IconButton } from '@/modules/shared/components/Atom/Buttons/IconButton/IconButton';
import { GlobalState } from '@/reducer';
import { SiteActions } from '@/redux/site/actions';
import { useAppDispatch, useAppSelector } from '@/store';

import { BrowseLinks } from './BrowseLinks';
import classes from './MobileMenu.module.scss';
import { UserMenu } from './UserMenu';
import { BUTTON_SIZE, BUTTON_VARIANT, Button } from '../../Atom/Buttons';
import { Icon } from '../../Atom/Icon';
import { MODAL } from '../Popups/types';
import { openModal } from '../Popups/utils/modal';

function Backdrop() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classes.backdrop}
    />
  );
}

function outsideClickHandler({
  isSidebarVisible,
  toggleSidebar,
}: {
  isSidebarVisible: boolean;
  toggleSidebar: () => void;
}) {
  return function (e: MouseEvent) {
    const communityMenuButtonClicked = e
      .composedPath()
      .find((el) =>
        [
          'CommunityMoreButton',
          'NavbarMobile',
          'UserMenu',
          'VerticalNavbar',
        ].includes((el as HTMLElement)?.id),
      );

    if (typeof communityMenuButtonClicked === 'undefined' && isSidebarVisible) {
      toggleSidebar();
    }
  };
}

export function MobileMenu() {
  const isSidebarVisible = useAppSelector(
    (state: GlobalState) => state.site.sidebar,
  );
  const menuTitle = useAppSelector((state) => state.site.appName);
  const dispatch = useAppDispatch();

  const toggleSidebar = useCallback(() => {
    dispatch(SiteActions.toggleSidebar(!isSidebarVisible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarVisible]);

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      outsideClickHandler({ isSidebarVisible, toggleSidebar }),
    );

    return () => {
      document.removeEventListener(
        'mousedown',
        outsideClickHandler({ isSidebarVisible, toggleSidebar }),
      );
    };
  }, [isSidebarVisible, toggleSidebar]);

  return (
    <>
      <AnimatePresence>{isSidebarVisible && <Backdrop />}</AnimatePresence>
      <nav className={classes.navbar} id="NavbarMobile">
        <div className={classes.primary_section}>
          <IconButton variant="transparent" onClick={toggleSidebar}>
            <AnimatePresence mode="popLayout">
              {isSidebarVisible && (
                <m.div
                  initial={{ opacity: 0, rotate: 180 }}
                  animate={{ opacity: 1, rotate: 0 }}
                  exit={{ opacity: 0, rotate: -180 }}
                >
                  <Icon.close
                    className={classes.close_icon}
                    size={16}
                    color="white"
                  />
                </m.div>
              )}
            </AnimatePresence>
            <AnimatePresence mode="popLayout">
              {!isSidebarVisible && (
                <m.div
                  initial={{ opacity: 0, rotate: 180 }}
                  animate={{ opacity: 1, rotate: 0 }}
                  exit={{ opacity: 0, rotate: -180 }}
                >
                  <Icon.menu2 size={16} color="white" />
                </m.div>
              )}
            </AnimatePresence>
          </IconButton>
          <div className={classes.appName}>
            <Logo />
            {menuTitle}
          </div>
        </div>
        <ExtraButton />
      </nav>
      <AnimatePresence>
        {isSidebarVisible && <MobileMenuItems />}
      </AnimatePresence>
    </>
  );
}

function Logo() {
  const router = useRouter();
  const slug = router.query.slug;
  if (typeof slug === 'string' && !Array.isArray(slug)) {
    return (
      <CommunityDetail communityKey={slug}>
        {({ community }) => {
          return (
            <Image
              src={community?.content?.images.logoImageUrl || Images.logo}
              alt="Logo"
              width={16}
              height={16}
            />
          );
        }}
      </CommunityDetail>
    );
  }
  return <Image src={Images.logo} alt="Logo" width={16} height={16} />;
}

function MobileMenuItems() {
  return (
    <m.aside
      className={classes.vertical_navbar_container}
      initial={{ left: '-100%', opacity: 0.8 }}
      animate={{ left: 0, opacity: 1 }}
      exit={{ left: '-100%', opacity: 0.8 }}
      id="VerticalNavbar"
    >
      <BrowseLinks />
      <UserMenu />
    </m.aside>
  );
}

function ExtraButton() {
  const { t } = useTranslation(['globals']);
  const navbarCta = useAppSelector((state) => state.site.navbar_cta);
  const { data: authState } = useUserProfile();
  const router = useRouter();
  const slug = router.query.slug;

  const Component = useMemo(() => {
    return match([authState?.isLoggedIn, navbarCta])
      .with([P.any, 'None'], () => null)
      .with([false, 'Login'], () => (
        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          size={BUTTON_SIZE.XSMALL}
          onClick={() => openModal(MODAL.LOGIN, undefined, { login: true })}
        >
          {t('globals:signin')}
        </Button>
      ))
      .with([true, 'JoinCommunity'], () => {
        if (Array.isArray(slug) || typeof slug !== 'string') {
          return null;
        }

        return (
          <CommunityDetail communityKey={slug}>
            {({ community }) => {
              if (typeof community === 'undefined') return <></>;

              return (
                <JoinCommunity
                  onSuccess={() =>
                    toast.success(
                      t('community:joinedCommunity.title', {
                        community: community.name,
                      }),
                    )
                  }
                >
                  {({ onJoin }) => (
                    <Button
                      variant={BUTTON_VARIANT.BLUE}
                      size={BUTTON_SIZE.XSMALL}
                      onClick={() => {
                        onJoin(community.communityId);
                      }}
                    >
                      {t('community:joinCommunity')}
                    </Button>
                  )}
                </JoinCommunity>
              );
            }}
          </CommunityDetail>
        );
      })
      .otherwise(() => null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarCta, slug, authState?.state]);

  return <>{Component}</>;
}
