import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Tag } from '@/modules/shared/components/Atom/Tag';
import { WhitelistForm } from '@/modules/shared/components/Template/Popups/content/Whitelist/WhitelistForm';
import { PopupClose } from '@/modules/shared/components/Template/Popups/PopupClose';
import { GLOBAL_ROUTES } from '@/routes';

export const WhitelistPopup = ({
  description,
  title,
}: {
  title?: string;
  description?: string;
}) => {
  const { t } = useTranslation(['auth', 'globals']);

  return (
    <div className="login-popup ">
      <div className="flex middle between">
        <Tag label={t('globals:beta')} slug="beta" active />

        <PopupClose />
      </div>

      <div className="flex column gap-normal">
        <h2 className="popup-title lexend-heading">
          {title || t('auth:login.whitelistTitle')}
        </h2>
        <p className="popup-desc lexend-body-s">
          {description || t('auth:login.whitelistDescription')}
        </p>

        <div className="flex column gap-normal">
          <WhitelistForm />
        </div>
        <div className="footnote lexend-body-xs1">
          {t('auth:login.disclaimer')}{' '}
          <Link href={GLOBAL_ROUTES.TERMS_AND_CONDITIONS}>
            {t('auth:login.disclaimer1')}
          </Link>{' '}
          {t('auth:login.disclaimer2')}{' '}
          <Link href={GLOBAL_ROUTES.PRIVACY_POLICY}>
            {t('auth:login.disclaimer3')}
          </Link>
        </div>
      </div>
    </div>
  );
};
