import { useRouter } from 'next/router';

import { Icon } from '@/modules/shared/components/Atom/Icon';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { IconButton } from '../../Atom/Buttons/IconButton/IconButton';

export const PopupClose = () => {
  const router = useRouter();

  return (
    <IconButton
      variant="outlined"
      className="close-popup-square flex middle center"
      onClick={() => closeModal(router)}
    >
      <Icon.cross size={16} color="white" />
    </IconButton>
  );
};
