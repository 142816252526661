import { GoogleOAuthProvider } from '@react-oauth/google';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ComponentType, Suspense } from 'react';
import { Provider } from 'react-redux';

import { integralCF, lexend } from '@/assets/fonts/fonts';
import { LazyMotion, loadFeatures } from '@/lib/motion';
import { ToastContainer } from '@/lib/toastify';
import { useQuerySetup } from '@/modules/auth/client-api';
import ErrorBoundary from '@/modules/shared/components/Template/Errors/ErrorBoundary';
import { Popups } from '@/modules/shared/components/Template/Popups/components/Popups';
import { RouteErrorHandler } from '@/modules/shared/components/Template/RouteErrorHandler';
import nextI18NextConfig from '@/next-i18next.config';
import store from '@/store';
import '@/styles/main.scss';

const CookiesConsent = dynamic(
  () =>
    import(
      '@/modules/shared/components/Organism/CookiesConsent/CookiesConsent'
    ).then((m) => m.CookiesConsent),
  {
    ssr: false,
  },
);

function App({ Component, pageProps }: AppProps) {
  const clientId = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENTID || '';
  const { queryClient } = useQuerySetup();

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${lexend.style.fontFamily};
        }
      `}</style>
      <main className={` ${lexend.variable} ${integralCF.variable}`}>
        <GoogleOAuthProvider clientId={clientId}>
          <RouteErrorHandler />
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps?.dehydratedState}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <link rel="icon" href="/favicon.ico" />
                </Head>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable={false}
                  pauseOnHover
                  theme="dark"
                />
                <ErrorBoundary>
                  <LazyMotion features={loadFeatures} strict>
                    <Component {...pageProps} />
                    <Suspense fallback={<>Loading..</>}>
                      <Popups />
                      <CookiesConsent />
                    </Suspense>
                  </LazyMotion>
                </ErrorBoundary>
              </Hydrate>
            </QueryClientProvider>
          </Provider>
        </GoogleOAuthProvider>
      </main>
    </>
  );
}

const AppWithTranslation: ComponentType<any> = appWithTranslation(
  App,
  nextI18NextConfig,
);

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID || '',
})(AppWithTranslation);
