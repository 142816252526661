import { USER_SOCIAL_TYPE, getUserSocialByType } from '@xborglabs/ui-shared';
import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import first from 'lodash/first';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';

import { toast } from '@/lib/toastify';

export function useIntentToast() {
  const router = useRouter();
  const intent = useMemo(
    () =>
      Array.isArray(router.query.intent)
        ? first(router.query.intent)
        : router.query.intent,
    [router.query.intent],
  );

  const { t } = useTranslation(['landing']);
  const { data: authState } = useUserProfile();

  const { twitter, discord, twitch, steam, youtube, riot } = useMemo(
    () => ({
      twitter: getUserSocialByType(
        authState?.socials,
        USER_SOCIAL_TYPE.TWITTER,
      ),
      discord: getUserSocialByType(
        authState?.socials,
        USER_SOCIAL_TYPE.DISCORD,
      ),
      twitch: getUserSocialByType(authState?.socials, USER_SOCIAL_TYPE.TWITCH),
      steam: getUserSocialByType(authState?.socials, USER_SOCIAL_TYPE.STEAM),

      youtube: getUserSocialByType(
        authState?.socials,
        USER_SOCIAL_TYPE.YOUTUBE,
      ),

      riot: getUserSocialByType(authState?.socials, USER_SOCIAL_TYPE.RIOT),
    }),
    [authState?.socials],
  );

  const removeIntent = useCallback(() => {
    const { intent: _, ...routerQuery } = router.query;
    router.replace(
      {
        query: { ...routerQuery },
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  useEffect(() => {
    match(intent?.toUpperCase())
      .with(USER_SOCIAL_TYPE.DISCORD, () => {
        if (discord) {
          toast.success(t('landing:platformConnected', { name: 'Discord' }));
          removeIntent();
        }
      })
      .with(USER_SOCIAL_TYPE.TWITTER, () => {
        if (twitter) {
          toast.success(t('landing:platformConnected', { name: 'Twitter' }));
          removeIntent();
        }
      })
      .with(USER_SOCIAL_TYPE.TWITCH, () => {
        if (twitch) {
          toast.success(t('landing:platformConnected', { name: 'Twitch' }));
          removeIntent();
        }
      })
      .with(USER_SOCIAL_TYPE.STEAM, () => {
        if (steam) {
          toast.success(t('landing:platformConnected', { name: 'Steam' }));
          removeIntent();
        }
      })
      .with(USER_SOCIAL_TYPE.YOUTUBE, () => {
        if (youtube) {
          toast.success(t('landing:platformConnected', { name: 'Youtube' }));
          removeIntent();
        }
      })
      .with(USER_SOCIAL_TYPE.RIOT, () => {
        if (riot) {
          toast.success(t('landing:platformConnected', { name: 'Riot' }));
          removeIntent();
        }
      })
      .with(P.select(), () => null)
      .exhaustive();
  }, [discord, intent, removeIntent, riot, steam, t, twitch, twitter, youtube]);

  return { twitter, discord, twitch, steam, youtube, riot };
}
