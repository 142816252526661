import { QuestDetailsType } from '@xborglabs/ui-shared';
import { CommunityDetail } from '@xborglabs/ui-shared/dist/client';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { QuestCompleteReward } from '@/modules/shared/components/Template/Popups/content/QuestComplete';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import './_QuestComplete.scss';

export const QuestComplete = ({
  reward,
  containerId,
  emission,
  onComplete,
}: {
  reward: QuestDetailsType['reward'];
  containerId: string;
  onComplete?: () => null;
  emission?: number;
}) => {
  const router = useRouter();
  const { t } = useTranslation(['globals', 'quests']);

  const amount = emission || Number(reward.args.amount);

  function onClose() {
    if (onComplete) {
      onComplete();
    } else {
      closeModal(router);
    }
  }

  return (
    <div className="quest-complete-popup flex column middle center text-center">
      <h3 className="lexend-heading-h1 font-bold title">
        {t('quests:questCompleted')}
      </h3>

      <CommunityDetail communityKey={containerId}>
        {({ community }) =>
          community ? (
            <QuestCompleteReward
              containerId={containerId}
              reward={reward}
              amount={amount}
              communitySlug={community?.slug}
              onClose={onClose}
            />
          ) : (
            <></>
          )
        }
      </CommunityDetail>
    </div>
  );
};
