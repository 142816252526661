import {
  QuestRequiredAction,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { useTranslation } from 'react-i18next';

export type QuizValues = {
  [key: string]: {
    percentage: number;
    value: number;
  };
};

export type QuizResults = {
  values: QuizValues;
  winner: string;
};

export const QuizOptions = ({
  onChange,
  options,
  value,
  results,
}: {
  value: string;
  options: string[];
  onChange: (v: string) => void;
  results?: QuizResults;
}) => {
  const actionContext = useQuestActionContext();
  const { t } = useTranslation(['quests']);
  return (
    <div className="flex column gap-small quiz-options">
      {options.map((option) => (
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          className={`quiz-options__result w-full ${
            value === option ? 'selected' : ''
          }`}
          onClick={() => onChange(option)}
          key={option}
          disabled={actionContext.action === QuestRequiredAction.STOP}
          size={BUTTON_SIZE.LARGE}
        >
          <div
            className={[
              'btn-fill',
              results?.winner === option ? 'winner' : 'loser',
            ].join(' ')}
            style={{
              width: `${results?.values?.[option]?.percentage ?? 0}%`,
            }}
          />
          <div className="option-label">
            <div style={{ zIndex: 1 }}>
              {option}
              {results && (
                <span className={'dimmed'}>
                  {' '}
                  {t('quests:quiz_votes', {
                    percentage: Math.round(
                      results.values[option]?.percentage ?? 0,
                    ),
                    count: results.values[option]?.value ?? 0,
                  })}
                </span>
              )}
            </div>
            {value === option && (
              <Icon.checkLight size={16} className="check-icon" />
            )}
          </div>
        </Button>
      ))}
    </div>
  );
};
