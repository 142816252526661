import { useInventoryItem } from '@xborglabs/ui-shared/dist/client';
import { QuestRewardType } from '@xborglabs/ui-shared/lib/features/quests/types';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';

import { Tag } from '@/modules/shared/components/Atom/Tag';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';

import { GLOBAL_ROUTES } from '@/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PopupContinueButton } from '../../../PopupContinueButton';

export const ItemReward = ({
  reward,
  containerId,
  onClose,
  communitySlug,
}: {
  reward: QuestRewardType;
  containerId: string;
  onClose: () => void;
  communitySlug?: string;
}) => {
  const { t } = useTranslation(['globals', 'quests']);
  const router = useRouter();

  const { data: asset, isLoading } = useInventoryItem({
    communityKey: containerId,
    itemId: reward.args.itemId,
  });

  return (
    <>
      <div className="lexend-heading-l wearable-unlocked font-bold">
        {t('quests:wearableUnlocked')}
      </div>
      <div className="reward-container wearable-container flex column middle center">
        <div className="reward-image flex column middle center">
          {match([asset, isLoading])
            .with([P._, true], () => <LoadingSpinner size="big" />)
            .with([P.not(P.nullish), P._], ([a]) => (
              <>
                <Image src={a.mediaUrl} alt={a.name} width={280} height={280} />
                <div className="reward lexend-heading-l font-bold">
                  {a.name}
                </div>

                <div className="tags-list flex">
                  <div className={a.rarity}>
                    <Tag label={a.rarity} slug={a.rarity} active />
                  </div>
                </div>
              </>
            ))
            .otherwise(() => null)}
        </div>
      </div>

      <Link
        onClick={() => {
          router
            .push(
              GLOBAL_ROUTES.COMMUNITY_INVENTORY_SLOTS(communitySlug as string),
            )
            .then(onClose);
        }}
        href={GLOBAL_ROUTES.COMMUNITY_INVENTORY_SLOTS(communitySlug as string)}
        className="btn btn-transparent red flex middle center"
      >
        {t('quests:viewInventory')}
      </Link>

      <PopupContinueButton
        href={GLOBAL_ROUTES.COMMUNITY_QUESTS(communitySlug as string)}
        onClick={onClose}
      />
    </>
  );
};
