import { LeaveCommunity } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_LeaveCommunity.scss';

export type LeaveCommunityPopupProps = {
  id: string;
};

export const LeaveCommunityPopup = ({ id }: LeaveCommunityPopupProps) => {
  const { t } = useTranslation(['community', 'globals']);

  function handleLeaveCommunity(onLeave: (id: string) => void) {
    return function () {
      onLeave(id);
      closeModal();
    };
  }

  function handleCancelButton() {
    closeModal();
  }

  return (
    <LeaveCommunity
      onSuccess={(data) => {
        toast.success(
          t('community:leftCommunity.title', { community: data.name }),
        );
      }}
      onError={() => {
        toast.error(t('community:leftCommunity.error'));
      }}
    >
      {({ onLeave, isLoading }) => (
        <div className="leave-community-popup">
          <div className="modal-title">{t('community:leaveCommunity')}</div>

          <div className="flex middle actions">
            <Button
              variant={BUTTON_VARIANT.RED}
              onClick={handleCancelButton}
              className="cancel expand"
            >
              {t('globals:cancel')}
            </Button>
            <Button
              variant={BUTTON_VARIANT.TRANSPARENT}
              onClick={handleLeaveCommunity(onLeave)}
              loading={isLoading}
              className="leave expand"
            >
              {t('community:leave')}
            </Button>
          </div>
        </div>
      )}
    </LeaveCommunity>
  );
};
