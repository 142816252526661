import { ButtonHTMLAttributes } from 'react';

import classes from './IconButton.module.scss';

export type IconButtonProps = {
  variant?: 'solid' | 'outlined' | 'primary' | 'transparent';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function IconButton({
  className,
  variant = 'solid',
  ...rest
}: IconButtonProps) {
  return (
    <button
      className={`${classes.icon_button} ${classes[variant]} ${className}`}
      {...rest}
    />
  );
}
