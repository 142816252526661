import { Endpoint } from '@xborglabs/ui-shared';
import axios from 'axios';
import { JWTPayload, decodeJwt } from 'jose';

import { defaultConfig } from '@/lib/axios/axiosConfig';
import { RequestQueue } from '@/modules/utils/request-queue';

import { createClientApi } from './axios';
import { AuthCookies } from './cookies';

export const tryRefreshToken = (reqQueue: RequestQueue) => (rejected?: any) => {
  try {
    const authCookies = new AuthCookies();
    const sessionToken = decodeJwt(authCookies.sessionToken);
    const isSessionValid = isTokenValid(sessionToken, new Date());
    const status = rejected?.response?.status;
    const sessionExpired =
      rejected?.response?.data?.name === 'session_token_expired';

    // 401: Unauthorized
    if (status === 401 && sessionExpired) {
      // We might already updated the token in another request. so check if the token is renewed.
      if (isSessionValid) {
        const errorResponse = rejected?.response;
        errorResponse.config.headers.Authorization = `Bearer ${authCookies.sessionToken}`;
        return axios(errorResponse.config);
      }

      // Refresh access token.
      return refreshAccessToken(reqQueue)(rejected);
    }

    // It's not an authorization issue, just pass on the rejection.
    return Promise.reject(rejected);
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

export const refreshAccessToken =
  (reqQueue: RequestQueue) => async (rejected?: any) => {
    const now = new Date();
    const authCookies = new AuthCookies();

    try {
      const { response: errorResponse } = rejected;

      if (!authCookies.refreshToken) {
        return Promise.reject(rejected);
      }

      const refreshToken = decodeJwt(authCookies.refreshToken);
      if (!isTokenValid(refreshToken, now)) {
        return Promise.reject(rejected);
      }

      // Build a promise that will be handed to the caller instead of the error.
      const deferredRefresh = new Promise((resolve) => {
        reqQueue.enqueue((token: string) => {
          if (errorResponse) {
            errorResponse.config.headers.Authorization = `Bearer ${token}`;
            resolve(axios(errorResponse.config));
          }
        });
      });

      // Check if we're already trying to fetch a replacement access token.
      if (!reqQueue.isFetchingToken) {
        reqQueue.isFetchingToken = true;
        const newApi = axios.create(defaultConfig);
        const response = await newApi
          .post<{
            sessionToken: string;
          }>(Endpoint.REFRESH_TOKEN, {
            refreshToken: authCookies.refreshToken,
          })
          .then((res) => res.data);

        const newToken = response.sessionToken;

        if (!newToken) {
          // The refresh request failed, reset the queue, sign the
          // user out and return the error.
          reqQueue.clearQueue();

          // Clear the session cookies
          authCookies.logout();
          // Forward the rejection
          return Promise.reject(rejected);
        }

        // The refresh request succeeded, save the token details for future
        // requests and make the queued requests again.

        authCookies.refresh(newToken);
        createClientApi();

        reqQueue.dequeueAll(newToken);
      }

      // Return the promise, which will fulfill when we have a new token,
      // instead of the error.
      return deferredRefresh;
    } catch (error) {
      // Something went generically wrong, return this error.
      authCookies.logout();
      return Promise.reject(error);
    }
  };

export const isTokenValid = (token: JWTPayload, now: Date) => {
  return token?.exp && token.exp * 1000 > now.getTime();
};
