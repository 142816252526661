import { DeleteAccount } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import './_DeleteAccount.scss';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { TextInput } from '@/modules/shared/components/Molecule/Forms/TextInput';
import { PopupClose } from '@/modules/shared/components/Template/Popups';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';

export const DeleteAccountPopup = () => {
  const { t } = useTranslation(['profile', 'globals']);

  return (
    <DeleteAccount>
      {({ code, setCode, errors, onDelete, isLoading }) => (
        <div className="deleteaccount-popup">
          <div className="flex between">
            <h4 className="popup-title lexend-heading-l">
              {t('profile:delete.title')}
            </h4>

            <PopupClose />
          </div>

          <div className="description flex column lexend-body-s">
            <div className="row">{t('profile:delete.desc1')}</div>
            <div className="row">{t('profile:delete.desc2')}</div>
          </div>
          <form>
            <TextInput
              onChange={setCode}
              value={code}
              error={errors.code}
              placeholder={t('profile:delete.placeholder')!}
              id={'delete'}
            />

            <div className="flex middle">
              <Button
                variant={BUTTON_VARIANT.RED}
                onClick={() => closeModal()}
                className="cancel expand"
                size={BUTTON_SIZE.LARGE}
              >
                {t('globals:cancel')}
              </Button>
              <Button
                variant={BUTTON_VARIANT.TRANSPARENT}
                onClick={onDelete}
                className="delete expand"
                loading={isLoading}
                size={BUTTON_SIZE.LARGE}
              >
                {t('globals:delete')}
              </Button>
            </div>
          </form>
        </div>
      )}
    </DeleteAccount>
  );
};
