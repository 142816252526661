import { useFlags } from 'launchdarkly-react-client-sdk';

import { HOOK_FLAGS } from '@/lib/launchdarkly/FeatureFlags';

type KillSwitchFlag = keyof typeof HOOK_FLAGS.KILL_SWITCH;

export function useKillSwitch(flag: KillSwitchFlag): boolean;
export function useKillSwitch(flag: KillSwitchFlag[]): boolean[];
export function useKillSwitch(
  flag: KillSwitchFlag | KillSwitchFlag[],
): boolean[] | boolean {
  const flags = useFlags();

  if (Array.isArray(flag)) {
    return flag.map((f) => Boolean(flags[HOOK_FLAGS.KILL_SWITCH[f]]));
  }

  return Boolean(flags[HOOK_FLAGS.KILL_SWITCH[flag]]);
}
