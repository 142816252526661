import { USER_AUTH_STATE } from '@xborglabs/ui-shared';
import { NextRouter } from 'next/router';

import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import { GLOBAL_ROUTES, PRIVATE_ROUTES, VISITOR_ROUTES } from '@/routes';

export function handleRouting(router: NextRouter, state: USER_AUTH_STATE) {
  const pathname = router.pathname as any;
  const isPrivateRoute = Object.values(PRIVATE_ROUTES).includes(pathname);
  const isVisitorRoute = Object.values(VISITOR_ROUTES).includes(pathname);
  function updateRoute(path: string) {
    router.push(path).then(() => onRouterDoneCallback(path));
  }

  function onRouterDoneCallback(path: string) {
    handleQueryLinks(path, state);
  }
  const intention = getRoutingIntent(router.asPath);
  const intent = new URLSearchParams(
    intention as Record<string, string> | undefined,
  );
  const redirectPath = sessionStorage.getItem('redirectTo');

  switch (state) {
    case USER_AUTH_STATE.POST_CLAIM:
      if (isVisitorRoute) return updateRoute(GLOBAL_ROUTES.LANDING);
      if (redirectPath) {
        sessionStorage.removeItem('redirectTo');
        return updateRoute(redirectPath);
      }
      return onRouterDoneCallback(router.asPath);

    case USER_AUTH_STATE.ONBOARDING:
      if (intention?.intent !== 'claim_username') {
        intent.delete('intent');
        intention?.intent && intent.set('prev_intent', intention?.intent);
        return updateRoute(
          `${GLOBAL_ROUTES.ONBOARDING}${
            intent.toString().length ? `&${intent.toString()}` : ''
          }`,
        );
      } else if (intention?.intent === 'claim_username') {
        return updateRoute(router.asPath);
      }

    case USER_AUTH_STATE.NOT_REGISTERED:
      if (isPrivateRoute) return updateRoute(GLOBAL_ROUTES.LANDING);
      onRouterDoneCallback(router.asPath);
      return;

    default:
      return;
  }
}

export function handleQueryLinks(path: string, state: USER_AUTH_STATE) {
  const intention = getRoutingIntent(path);

  if (!intention) return;
  switch (intention.intent) {
    case 'claim_username':
      if (state !== USER_AUTH_STATE.ONBOARDING) return;
      openModal(MODAL.CLAIM_USERNAME);
      return;
    case 'open_quest':
      openModal(MODAL.QUEST_DETAILS, {
        qid: intention.qid,
      });
      return;
    case 'community_referral':
      if (!intention.community || !intention.r) return;
      openModal(MODAL.COMMUNITY_REFERRAL, {
        slug: intention.community,
        referrer: intention.r,
      });
      return;
    case 'social-connection':
      if (!intention.qid) return;
      openModal(MODAL.QUEST_DETAILS, {
        qid: intention.qid,
      });
      return;
    default:
      return;
  }
}

export function getRoutingIntent(path: string) {
  const searchParams = new URLSearchParams(path.split('?')?.[1]);
  switch (true) {
    case searchParams.get('intent') === 'community_referral':
      return {
        intent: 'community_referral',
        community: searchParams.get('community') as string,
        r: searchParams.get('r') as string,
      };
    case searchParams.has('qid'):
      return {
        intent: 'open_quest',
        qid: searchParams.get('qid') as string,
      };
    case searchParams.get('intent') === 'claim_username':
      return {
        intent: 'claim_username',
      };
    case searchParams.get('intent') === 'steam':
    case searchParams.get('intent') === 'riot':
    case searchParams.get('intent') === 'discord':
    case searchParams.get('intent') === 'twitch':
    case searchParams.get('intent') === 'twitter':
    case searchParams.get('intent') === 'youtube':
    case searchParams.get('intent') === 'battlenet':
      return {
        intent: 'social-connection',
        qid: searchParams.get('qid') as string,
      };
    default:
      return;
  }
}
